import React, { useState, useRef } from "react";
import autoTable from "jspdf-autotable";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, Button } from "@mui/material";
import { useContext } from "react";
import { url } from "../index";
import Select from "react-select";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import Preloader from "./Preloader";
import "jspdf-autotable";
import jsPDF from "jspdf";
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
  getClient,
  userDetails,
  activeStatus,
  permission,
  formatDate1,
  clearForm,
  formateDateTime,
} from "../services/api.service";
import { Link } from "react-router-dom";
import DocumentTitle from "react-document-title";
import { format } from "date-fns";
import { generateCsv, mkConfig } from "export-to-csv";
import Footer from "./Footer";

const Receipts = () => {

  const [saveButton, setSaveButton] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  //        -------- POPUP CLOSE VARIABLE ---------

  const currentDate = new Date().toISOString().split("T")[0];
  const closeButton = document.querySelector(".btn_popup");
  const closeView = document.querySelector(".ab");
  const closeEdit = document.querySelector(".e_popup");
  const closeDelete = document.querySelector(".mm");
  const [storePaidAmount, setStorePaidAmount] = useState("");
  const [fCaseId, setFCaseId] = useState("");
  const [fFromDate, setFFromDate] = useState("");
  const filterform = new FormData();
  const [fToDate, setFToDate] = useState("");
  const [fClientID, setFClientID] = useState("");
  const [fClientOption, setFClientOption] = useState([]);
  const baseUrl = useContext(url);
  const navigate = useNavigate();
  const [CaseID, setCaseID] = useState("");
  const [Dates, setDates] = useState(currentDate);
  const [TransactionType, setTransactionType] = useState("");
  const [Details, setDetails] = useState("");
  const [TDSAmount, setTDSAmount] = useState("0");
  const [PaidAmount, setPaidAmount] = useState("0");
  const [totalAmount, setTotalAmount] = useState("0");
  const [InvoiceID, setInvoiceID] = useState("");
  const [filterActive, setFilterActive] = useState(false);
  const [loading, setLoading] = useState(true);

  //        --------  EDIT  VARIABLE ---------

  const [EditCaseID, setEditCaseID] = useState("");
  const [EditDate, setEditDate] = useState("");
  const [EditTransactionType, setEditTransactionType] = useState("");
  const [EditDetails, setEditDetails] = useState("");
  const [eCaseID, setECaseID] = useState("");
  const [EditTDSAmount, setEditTDSAmount] = useState("");
  const [EditPaidAmount, setEditPaidAmount] = useState("");
  const [EditInvoiceId, setEditInvoiceId] = useState("");
  const [editTotalAmount, setEditTotalAmount] = useState("");

  //        --------  View  VARIABLE ---------

  const [dCaseID, setDCaseID] = useState("");
  const [dDate, setDDate] = useState("");
  const [dTDSAmount, setDTDSAmount] = useState("");
  const [dPaidAmount, setDPaidAmount] = useState("");
  const [dDetails, setDDetails] = useState("");
  const [dTransactionType, setDTransactionType] = useState("");
  const [dCaseYear, setDCaseYear] = useState("");
  const [dAddedBy, setDAddedBy] = useState("");
  const [dInvoiceNo, setDInvoiceNo] = useState("");
  const [dReceiptNo, setDReceiptNo] = useState("");
  const [dTotalAmount, setDTotalAmount] = useState("");
  const [Invoice1, setInvoice1] = useState([]);
  const [EditID, setEditID] = useState("");
  const [isAdd, setIsAdd] = useState("0");
  const [isEdit, setIsEdit] = useState("0");
  const [isView, setIsView] = useState("0");
  const [isDelete, setIsDelete] = useState("0");
  const [isStatus, setIsStatus] = useState("0");
  const [data, setData] = useState([]);
  const [delId, setDelId] = useState("");
  const formData = new FormData();
  const formEditData = new FormData();
  const form = new FormData();
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState("0");
  const [totalPaidAmount, setTotalPaidAmount] = useState("0");
  const [totalTDSAmount, setTotalTDSAmount] = useState("0");

  const [open, setOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const handleExportButtonClick = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const handleToggleColumn = (columnName) => {
    const currentIndex = selectedColumns.indexOf(columnName);
    const newSelectedColumns = [...selectedColumns];

    if (currentIndex === -1) {
      newSelectedColumns.push(columnName);
    } else {
      newSelectedColumns.splice(currentIndex, 1);
    }

    setSelectedColumns(newSelectedColumns);
  };

  //        -------- VIEW RECEIPT DATA ---------

  const withUidApi = async ( path ) => {
    form.append("AdminID", localStorage.getItem("id"));
    form.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(
        `${baseUrl}${path}`,
        {
          method: "POST",
          body : form,
          headers: {
            "Authorization": `${localStorage.getItem("access_token")}`,
            'Accept': '*/*'
          }
        }
      );
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error('Token expired please login again');
          return "token";  
      }
      
      if (data && data.result.Receipt[0].message === "Success") {
        return data.result.Receipt
      } else{
        return null
      }
  
    }
    catch (error) {
      console.error(error);
    }
  };

  const getCaseView = async () => {
    const [view, data, cdata] = await Promise.all([
      withUidApi("Receipt/ViewReceipt"),
      permission(15),
      getClient("Client/ViewClient"),
      getFileNo(),
      setLoading(true),
    ]);

    if (view === null) {
      setLoading(false);
      setData([]);
      setTotalInvoiceAmount("0");
      setTotalPaidAmount("0");
      setTotalTDSAmount("0");
    } else {
      if (view === "token") {
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        setLoading(false);
        setData(view);

        let sum = view && view.reduce(function (prev, current) {
          return prev + +current.TotalAmount;
        }, 0);
        setTotalInvoiceAmount(sum);

        let PaidAmount = view && view.reduce(function (prev, current) {
          return prev + +current.PaidAmount;
        }, 0);
        setTotalPaidAmount(PaidAmount);

        let TDSAmount = view && view.reduce(function (prev, current) {
          return prev + +current.TDSAmount;
        }, 0);
        setTotalTDSAmount(TDSAmount);
      }
    }
    
    const clientData = cdata && cdata.filter((item) => item.Status === "1");

    const Mapped =
      clientData &&
      clientData.map((item) => ({
        value: item.Id,
        label: `${item.FirstName} ${item.LastName}`,
      }));
    setFClientOption(Mapped);

    if (
      data &&
      data.result[0].IsAdd === "0" &&
      data &&
      data.result[0].IsView === "0"
    ) {
      navigate("/unauthorized");
    }
    
    setIsAdd(data && data.result[0].IsAdd);
    setIsEdit(data && data.result[0].IsEdit);
    setIsView(data && data.result[0].IsView);
    setIsStatus(data && data.result[0].IsStatus);
    setIsDelete(data && data.result[0].IsDelete);
  };

  /** Get File Name  */

  const Case = new FormData();
  const [optionsEvent, setOptionsEvent] = useState([]);
  const getFileNo = async () => {
    Case.append("AdminID", localStorage.getItem("id"));
    Case.append("UserID", localStorage.getItem("uId"));
    
    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: Case,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      
      if (data && data.result[0].message === "Success") {
        const Mapped = data.result.map((item) => ({
          value: item.CaseID,
          label: `${item.FileNo} / ${item.CaseNo} / ${item.CaseName} / ${item.CaseYear}`,
        }));
        setOptionsEvent(Mapped);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      setCaseID("");
      getInvoice("");
      setInvoice1([]);

    } else {
      setCaseID(selectedOption.value);
      getInvoice(selectedOption.value);

    }
  };

  //        -------- DELETE TASK API --------

  const deleteUser = async ( id, path ) => { 
    form.append("AdminID", localStorage.getItem("id"));
    form.append("DeletedBy", localStorage.getItem("UserId"));
    form.append("ID", id );
    form.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(
        `${baseUrl}${path}`,
        {
          method: "POST",
          body: form,
          headers: {
            "Authorization": `${localStorage.getItem("access_token")}`,
            'Accept': '*/*'
          }
        }
      );
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error('Token expired please login again');
          return "token";  
      }

      if (data.result.code === 200) {
        toast.success(data.result.message)
        return true;
      } else{
        toast.error(data.result.message)

      }
    }
    catch (error) {
      console.error(error);
    }
  };

  const deleteCaseLead = async () => {
    const data = await deleteUser(delId, "Receipt/DeleteReceipt");
    if (data === "token") {
      setTimeout(() => {
        closeDelete.click();
        navigate("/");
      }, 1000);
    }
    if (data === true) {
      closeDelete.click();
      filterActive ? filterApi() : getCaseView();
    }
  };

  //        -------- ACTIVE DEACTIVE ---------

  const deActive = async (row) => {
    const data = await activeStatus(row, "Receipt/ReceiptStatus");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 1000);
    }
    if (data === true) {
      filterActive ? filterApi() : getCaseView();
    }
  };

  //   Invoice

  const formInvoice = new FormData();

  const getInvoice = async (id) => {
    formInvoice.append("AdminID", localStorage.getItem("id"));
    formInvoice.append("CaseID", id);
    try {
      const response = await fetch(`${baseUrl}Receipt/ViewnvoiceById`, {
        method: "POST",
        body: formInvoice,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].message === "Success") {
        setInvoice1(data.result);
      } else {

        setInvoice1([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //        -------- TASK DETAILS DATA ---------

  const details = async (id) => {
    const data = await userDetails(id, "Receipt/ViewReceiptById");
    if (data === "token") {
      setTimeout(() => {
        closeView.click();
        navigate("/");
      }, 1000);
    } else {
      setDCaseID(data && data.result[0].CaseName);
      setDTDSAmount(data && data.result[0].TDSAmount);
      setDDate(data && data.result[0].Date);
      setDPaidAmount(data && data.result[0].PaidAmount);
      setDTransactionType(data && data.result[0].TransactionType);
      setDDetails(data && data.result[0].Details);
      setDAddedBy(data && data.result[0].AddedBy);
      setDCaseYear(data && data.result[0].CaseYear);
      setDInvoiceNo(data && data.result[0].InvoiceNo);
      setDReceiptNo(data && data.result[0].ReceiptNo);
      setDTotalAmount(data && data.result[0].TotalAmount);
    }
  };

  //        -------- Viwe by ID DATA ---------

  const getById = async (id) => {
    const data = await userDetails(id, "Receipt/ViewReceiptById");
    if (data === "token") {
      setTimeout(() => {
        closeEdit.click();
        navigate("/");
      }, 1000);
    } else {
      setEditID(data && data.result[0].Id);
      setECaseID(data && data.result[0].CaseID);
      setEditDate(data && data.result[0].Date);
      setEditTDSAmount(Math.floor(data && data.result[0].TDSAmount));
      setEditPaidAmount(Math.floor(data && data.result[0].PaidAmount));
      setEditTransactionType(data && data.result[0].TransactionType);
      setStorePaidAmount(Math.floor(data && data.result[0].PaidAmount));
      setEditDetails(data && data.result[0].Details);
      setEditInvoiceId(data && data.result[0].InvoiceID);
      setEditTotalAmount(Math.floor(data && data.result[0].TotalAmount));

      getInvoice(data && data.result[0].CaseID);
    }
  };

  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  // useEffect(() => {
  //   if (CaseID === "") {
  //     setInvoice1([]);
  //   } else {
  //     getInvoice(CaseID);
  //   }
  // }, [CaseID]);

  useEffect(() => {
    if (EditCaseID === "") {
      setInvoice1([]);
      setECaseID("");
    } else {
      setECaseID("");
      setEditInvoiceId("");
      getInvoice(EditCaseID);
    }
  }, [EditCaseID]);

  //        -------- SUBMIT DATA ---------

  const submitHandler = async (event) => {
    event.preventDefault();

    const amount = Invoice1 && Invoice1.filter((val) => val.Id === InvoiceID)
    const invoiceAmount = parseInt(amount && amount[0]?.InvoiceAmount) - parseInt(amount && amount[0]?.PaidAmount);

    if (totalAmount <= invoiceAmount) {
      setSaveButton(false);
      formData.append("AdminID", localStorage.getItem("id"));
      formData.append("CaseID", CaseID);
      formData.append("Date", Dates);
      formData.append("TransactionType", TransactionType);
      formData.append("TDSAmount", TDSAmount);
      formData.append("PaidAmount", PaidAmount);
      formData.append("InvoiceID", InvoiceID);
      formData.append("TotalAmount", totalAmount);
      formData.append("Details", Details.toUpperCase());
      formData.append("CreatedBy", localStorage.getItem("UserId"));

      try {
        const response = await fetch(`${baseUrl}Receipt/AddReceipt`, {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        });
  
        const data = await response.json();
  
        if (data && data.result.message == "Session Time Expire.") {
          toast.error("Token expired please login again");
          setTimeout(() => {
            closeButton.click();
            navigate("/");
          }, 1000);
        }
  
        if (data.result.length && data.result[0].message === "Success") {
          toast.success("Receipt added successfully");
          handleClose();
          filterActive ? filterApi() : getCaseView();
          clearForm();
          setCaseID("");
          setDates(currentDate);
          setTransactionType("");
          setDetails("");
          setTDSAmount("0");
          setTotalAmount("0");
          setPaidAmount("0");
          setInvoiceID("");
          setInvoice1([]);
          closeButton.click();
          setSaveButton(true);
        } else {
          toast.error(data.result.message);
          setSaveButton(true);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.error(`Total Invoice Amount Is ${invoiceAmount}`);
    }
  };

  //        -------- EDIT TASK API ---------

  const submitEditHandler = async (event) => {
    event.preventDefault();

    const amount = Invoice1 && Invoice1.filter((val) => val.Id === EditInvoiceId)
    const invoiceAmount = parseInt(amount && amount[0]?.InvoiceAmount) - parseInt(amount && amount[0]?.PaidAmount);

    if (editTotalAmount <= (invoiceAmount + storePaidAmount)) {
      formEditData.append("ID", EditID);
      formEditData.append("AdminID", localStorage.getItem("id"));
      formEditData.append("UpdatedBy", localStorage.getItem("UserId"));
      formEditData.append("CaseID", EditCaseID === "" ? eCaseID : EditCaseID);
      formEditData.append("Date", EditDate);
      formEditData.append("TransactionType", EditTransactionType);
      formEditData.append("TDSAmount", EditTDSAmount);
      formEditData.append("PaidAmount", EditPaidAmount);
      formEditData.append("Details", EditDetails.toUpperCase());
      formEditData.append("InvoiceID", EditInvoiceId);
      formEditData.append("TotalAmount", editTotalAmount);
      formEditData.append("oldPaidAmount", storePaidAmount);
  
      try {
        const response = await fetch(`${baseUrl}Receipt/EditReceipt`, {
          method: "POST",
          body: formEditData,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        });
        const data = await response.json();
        if (data && data.result.message == "Session Time Expire.") {
          toast.error("Token expired please login again");
          setTimeout(() => {
            closeEdit.click();
            navigate("/");
          }, 2000);
        }
  
        if (data.result.length && data.result[0].code === "200") {
          toast.success(" Updated successfully");
          closeEdit.click();
          filterActive ? filterApi() : getCaseView();
          clearForm();
        } else {
          toast.error(data.result[0].message);
        }
      } catch (error) {
        console.error(error);
      }
    } else{
      toast.error(`Total Invoice Amount Is ${invoiceAmount + storePaidAmount}`);
    }
  };

  // ---------- FILTER API ---------

  const filterApi = async () => {
    setLoading(true);
    filterform.append("AdminID", localStorage.getItem("id"));
    filterform.append("UserID", localStorage.getItem("uId"));
    filterform.append("CaseID", fCaseId);
    filterform.append("ClientID", fClientID);
    filterform.append("From", fFromDate);
    filterform.append("To", fToDate);
    try {
      const response = await fetch(`${baseUrl}Receipt/ViewReceiptfilter`, {
        method: "POST",
        body: filterform,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 2000);
      }

      if (
        data &&
        data.result.length &&
        data &&
        data.result[0].message === "Success"
      ) {
        setFilterActive(true);
        setLoading(false);
        setData(data.result);

        let sum = data && data.result.reduce(function (prev, current) {
          return prev + +current.TotalAmount;
        }, 0);
        setTotalInvoiceAmount(sum);

        let PaidAmount = data && data.result.reduce(function (prev, current) {
          return prev + +current.PaidAmount;
        }, 0);
        setTotalPaidAmount(PaidAmount);

        let TDSAmount = data && data.result.reduce(function (prev, current) {
          return prev + +current.TDSAmount;
        }, 0);
        setTotalTDSAmount(TDSAmount);

      } else {
        setLoading(false);
        setData([]);
        setTotalInvoiceAmount("0");
        setTotalPaidAmount("0");
        setTotalTDSAmount("0");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filterCancel = () => {
    clearForm();
    setCaseID("");
    setFCaseId("");
    setFClientID("");
    setFFromDate("");
    setFToDate("");
    getCaseView();
    setFilterActive(false);
  };

  const handleFilterCase = (selectedOption) => {
    if (selectedOption === null) {
      setFCaseId("");
    } else {
      setFCaseId(selectedOption.value);
    }
  };

  const handleFilterClient = (selectedOption) => {
    if (selectedOption === null) {
      setFClientID("");
    } else {
      setFClientID(selectedOption.value);
    }
  };


// ---------- calculate the percentage ------------

  const calculatePercentage = (amount, percentage) => {
    return (amount * percentage) / 100;
  };


  //        -------- TABLE ---------



  const columnHelper = createMRTColumnHelper();



  const columns1 = [
    columnHelper.accessor("name", {
      // minSize: "10px",
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      header: "Action",
      Cell: (row) => {
        return (
          <div style={{ display: "flex", gap: "15px" }}>
            <span>
              {isEdit !== "0" && (
                <a
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModalLongEdit"
                  onClick={() => getById(row.row.original.Id)}
                >
                  <img src="assets/img/icons/edit.svg" title="Edit" />
                </a>
              )}
            </span>
            <span>
              <a
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongDetail"
                onClick={() => details(row.row.original.Id)}
              >
                <img src="assets/img/icons/eye.svg" title="Details" />
              </a>
            </span>
            <span>
              <Link
                target="_blank"
                to={`/View_Receipt_PDF/${row.row.original.Id}`}
                title="PDF"
              >
                <img src="assets/img/icons/pdf.svg" title="PDF" />
              </Link>
            </span>
            <span>
              <Link
                target="_blank"
                to={`/View_Receipt_WP/${row.row.original.Id}`}
                title="PDF"
              >
                <img src="assets/img/icons/send.svg" title="Whatsapp PDF" />
              </Link>
            </span>
            <span>
              <a
                style={{
                  cursor: "pointer",
                  display: isStatus === "0" ? "none" : "block",
                }}
                onClick={() => deActive(row.row.original)}
              >
                {row.row.original.Status === "1" ? (
                  <img
                    className="thumb_icon"
                    src="assets/img/icons/Up.svg"
                    title="Status"
                  />
                ) : (
                  <img
                    className="thumb_icon"
                    src="assets/img/icons/Down.svg"
                    title="Status"
                  />
                )}
              </a>
            </span>
            {isDelete !== "0" && (
              <span
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                style={{ cursor: "pointer" }}
                onClick={() => setDelId(row.row.original.Id)}
              >
                <img src="assets/img/icons/delete.svg" title="Delete" />
              </span>
            )}
          </div>
        );
      },
    }),

    columnHelper.accessor("Date", {
      header: "Date",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.Date === null ||
            row.row.original.Date === "0000-00-00"
              ? "-"
              : formatDate1(row.row.original.Date)}
          </div>
        );
      },
    }),

    columnHelper.accessor("ReceiptNo", {
      header: "Receipt No",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.ReceiptNo === null ||
            row.row.original.ReceiptNo === ""
              ? "-"
              : row.row.original.ReceiptNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("InvoiceNo", {
      header: "Invoice No",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.InvoiceNo === null ||
            row.row.original.InvoiceNo === ""
              ? "-"
              : row.row.original.InvoiceNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("TransactionType", {
      header: "Transaction Type",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.TransactionType === null ||
            row.row.original.TransactionType === "0000"
              ? "-"
              : row.row.original.TransactionType}
          </div>
        );
      },
    }),

    columnHelper.accessor("TotalAmount", {
      footer : `${totalInvoiceAmount} Rs.`,
      header: "Total Amount",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.TotalAmount === null ||
            row.row.original.TotalAmount === ""
              ? "-"
              : row.row.original.TotalAmount}
          </div>
        );
      },
    }),

    columnHelper.accessor("PaidAmount", {
      footer : `${totalPaidAmount} Rs.`,
      header: "Paid Amount",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.PaidAmount === null ||
            row.row.original.PaidAmount === ""
              ? "-"
              : row.row.original.PaidAmount}
          </div>
        );
      },
    }),

    columnHelper.accessor("TDSAmount", {
      footer : `${totalTDSAmount} Rs.`,
      header: "TDS Amount",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.TDSAmount === null ||
            row.row.original.TDSAmount === ""
              ? "-"
              : row.row.original.TDSAmount}
          </div>
        );
      },
    }),

    columnHelper.accessor("FileNo", {
      header: "File No",
      minSize: "10px",
    }),

    columnHelper.accessor("CaseNo", {
      header: "Case No",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CaseNo === null || row.row.original.CaseNo === ""
              ? "-"
              : row.row.original.CaseNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseName", {
      header: "Case Name",
      Cell: (row) => {
        return (
          <div style={{ minWidth: "350px" }} className="upperCase">
            <a title="Case Details"
              href={`#/view-casedetails/${row.row.original.CaseID}`}
              className="visuald_case"
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              {row.row.original.CaseName}
            </a>
          </div>
        );
      },
    }),

    columnHelper.accessor("Counsel", {
      header: "Counsel",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Counsel === null ||
            row.row.original.Counsel === ""
              ? "-"
              : row.row.original.Counsel}
          </div>
        );
      },
    }),

   columnHelper.accessor("Counsel Per", {
      header: "Counsel Amount",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.CounselPer === "0" || row.row.original.Counsel === null || row.row.original.Counsel === "" ||
            row.row.original.CounselPer === ""
              ? "-"
              :  `${calculatePercentage(row.row.original.PaidAmount, row.row.original.CounselPer)} - ${row.row.original.CounselPer}%`}
          </div>
        );
      },
    }),

    columnHelper.accessor("Cocounsel", {
      header: "Co-counsel",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Cocounsel === null ||
            row.row.original.Cocounsel === ""
              ? "-"
              : row.row.original.Cocounsel}
          </div>
        );
      },
    }),

  columnHelper.accessor("Cocounsel Per", {
      header: "Co-counsel Amount",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.CounselPer === "0" || row.row.original.Cocounsel === null || row.row.original.Cocounsel === "" ||
            row.row.original.CounselPer === ""
              ? "-"
              :  `${calculatePercentage(row.row.original.PaidAmount, row.row.original.CounselPer)} - ${row.row.original.CounselPer}%`}
          </div>
        );
      },
    }),

    columnHelper.accessor("FirstName", {
      header: "Client",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.ClientName}
           <br/>
            {row.row.original.MobileNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("Status", {
      header: "Status",
      Cell: (row) => {
        return <div>{row.row.original.Status === "1" ? "ACTIVE" : "DEACTIVE"}</div>;
      },
    }),

    columnHelper.accessor("UpdatedBy", {
      header: "Last-Modified",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.UpdatedBy === null ||
            row.row.original.UpdatedBy === ""
              ? "-"
              : row.row.original.UpdatedBy}<br/>
                   {formateDateTime(row.row.original.UpdatedDate)}
          </div>
        );
      },
    }),

    columnHelper.accessor("CreatedBy", {
      header: "Added By",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.CreatedBy === null ||
            row.row.original.CreatedBy === ""
              ? "-"
              : row.row.original.CreatedBy}
              <br/>
              {formateDateTime(row.row.original.CreatedDate)}
          </div>
        );
      },
    }),
  ];

  const columns2 = [
    columnHelper.accessor("name", {
      // minSize: "10px",
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      header: "Action",
      Cell: (row) => {
        return (
          <div style={{ display: "flex", gap: "15px" }}>
            <span>
              {isEdit !== "0" && (
                <a
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModalLongEdit"
                  onClick={() => getById(row.row.original.Id)}
                >
                  <img src="assets/img/icons/edit.svg" title="Edit" />
                </a>
              )}
            </span>
            <span>
              <a
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongDetail"
                onClick={() => details(row.row.original.Id)}
              >
                <img src="assets/img/icons/eye.svg" title="Details" />
              </a>
            </span>
            <span>
              <Link
                target="_blank"
                to={`/View_Receipt_PDF/${row.row.original.Id}`}
                title="PDF"
              >
                <img src="assets/img/icons/pdf.svg" title="PDF" />
              </Link>
            </span>
            <span>
              <Link
                target="_blank"
                to={`/View_Receipt_WP/${row.row.original.Id}`}
                title="PDF"
              >
                <img src="assets/img/icons/send.svg" title="Whatsapp PDF" />
              </Link>
            </span>
            <span>
              <a
                style={{
                  cursor: "pointer",
                  display: isStatus === "0" ? "none" : "block",
                }}
                onClick={() => deActive(row.row.original)}
              >
                {row.row.original.Status === "1" ? (
                  <img
                    className="thumb_icon"
                    src="assets/img/icons/Up.svg"
                    title="Status"
                  />
                ) : (
                  <img
                    className="thumb_icon"
                    src="assets/img/icons/Down.svg"
                    title="Status"
                  />
                )}
              </a>
            </span>
            {isDelete !== "0" && (
              <span
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                style={{ cursor: "pointer" }}
                onClick={() => setDelId(row.row.original.Id)}
              >
                <img src="assets/img/icons/delete.svg" title="Delete" />
              </span>
            )}
          </div>
        );
      },
    }),

    columnHelper.accessor("Date", {
      header: "Date",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.Date === null ||
            row.row.original.Date === "0000-00-00"
              ? "-"
              : formatDate1(row.row.original.Date)}
          </div>
        );
      },
    }),

    columnHelper.accessor("ReceiptNo", {
      header: "Receipt No",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.ReceiptNo === null ||
            row.row.original.ReceiptNo === ""
              ? "-"
              : row.row.original.ReceiptNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("InvoiceNo", {
      header: "Invoice No",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.InvoiceNo === null ||
            row.row.original.InvoiceNo === ""
              ? "-"
              : row.row.original.InvoiceNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("TransactionType", {
      header: "Transaction Type",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.TransactionType === null ||
            row.row.original.TransactionType === "0000"
              ? "-"
              : row.row.original.TransactionType}
          </div>
        );
      },
    }),

    columnHelper.accessor("TotalAmount", {
      footer : `${totalInvoiceAmount} Rs.`,
      header: "Total Amount",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.TotalAmount === null ||
            row.row.original.TotalAmount === ""
              ? "-"
              : row.row.original.TotalAmount}
          </div>
        );
      },
    }),

    columnHelper.accessor("PaidAmount", {
      footer : `${totalPaidAmount} Rs.`,
      header: "Paid Amount",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.PaidAmount === null ||
            row.row.original.PaidAmount === ""
              ? "-"
              : row.row.original.PaidAmount}
          </div>
        );
      },
    }),

    columnHelper.accessor("TDSAmount", {
      footer : `${totalTDSAmount} Rs.`,
      header: "TDS Amount",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.TDSAmount === null ||
            row.row.original.TDSAmount === ""
              ? "-"
              : row.row.original.TDSAmount}
          </div>
        );
      },
    }),

    columnHelper.accessor("FileNo", {
      header: "File No",
      minSize: "10px",
    }),

    columnHelper.accessor("CaseNo", {
      header: "Case No",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CaseNo === null || row.row.original.CaseNo === ""
              ? "-"
              : row.row.original.CaseNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseName", {
      header: "Case Name",
      Cell: (row) => {
        return (
          <div style={{ minWidth: "350px" }} className="upperCase">
            <a title="Case Details"
              href={`#/view-casedetails/${row.row.original.CaseID}`}
              className="visuald_case"
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              {row.row.original.CaseName}
            </a>
          </div>
        );
      },
    }),

    columnHelper.accessor("Counsel", {
      header: "Counsel",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Counsel === null ||
            row.row.original.Counsel === ""
              ? "-"
              : row.row.original.Counsel}
          </div>
        );
      },
    }),

    columnHelper.accessor("Cocounsel", {
      header: "Co-counsel",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Cocounsel === null ||
            row.row.original.Cocounsel === ""
              ? "-"
              : row.row.original.Cocounsel}
          </div>
        );
      },
    }),

    columnHelper.accessor("FirstName", {
      header: "Client",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.ClientName}
           <br/>
            {row.row.original.MobileNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("Status", {
      header: "Status",
      Cell: (row) => {
        return <div>{row.row.original.Status === "1" ? "ACTIVE" : "DEACTIVE"}</div>;
      },
    }),

    columnHelper.accessor("UpdatedBy", {
      header: "Last-Modified",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.UpdatedBy === null ||
            row.row.original.UpdatedBy === ""
              ? "-"
              : row.row.original.UpdatedBy}<br/>
                   {formateDateTime(row.row.original.UpdatedDate)}
          </div>
        );
      },
    }),

    columnHelper.accessor("CreatedBy", {
      header: "Added By",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.CreatedBy === null ||
            row.row.original.CreatedBy === ""
              ? "-"
              : row.row.original.CreatedBy}
              <br/>
              {formateDateTime(row.row.original.CreatedDate)}
          </div>
        );
      },
    }),
  ];




 
  let sum = 0;
  data &&
    data.map((val, index) => {
      sum += parseFloat(val.PaidAmount);
    });

  let totalCashPayment = 0;

  const totalCash =
    data && data.filter((val) => val.TransactionType === "CASH");

  totalCash &&
    totalCash.map((val, index) => {
      totalCashPayment += parseFloat(val.PaidAmount);
    });

  const handleTdsAmount = (e) => {
    if (e.target.value === "") {
      setTDSAmount(0);
      setTotalAmount(parseInt(PaidAmount));
    } else {
      const inputAmount = parseInt(e.target.value);
      const newTotalAmount = parseInt(PaidAmount) + inputAmount;
      setTotalAmount(newTotalAmount);
      setTDSAmount(inputAmount);
    }
  };

  const handleAmount = (e) => {
    if (e.target.value === "") {
      setPaidAmount(0);
      setTotalAmount(parseInt(TDSAmount));
    } else {
      const inputAmount = parseInt(e.target.value);
      const newTotalAmount = parseInt(TDSAmount) + inputAmount;
      setTotalAmount(newTotalAmount);
      setPaidAmount(inputAmount);
    }
  };

  const editHandleTdsAmount = (e) => {
    if (e.target.value === "") {
      setEditTDSAmount(0);
      setEditTotalAmount(parseInt(EditPaidAmount));
    } else {
      const inputAmount = parseInt(e.target.value);
      const newTotalAmount = parseInt(EditPaidAmount) + inputAmount;
      setEditTotalAmount(newTotalAmount);
      setEditTDSAmount(inputAmount);
    }
  };

  const editHandleAmount = (e) => {
    if (e.target.value === "") {
      setEditPaidAmount(0);
      setEditTotalAmount(parseInt(EditTDSAmount));
    } else {
      const inputAmount = parseInt(e.target.value);
      const newTotalAmount = parseInt(EditTDSAmount) + inputAmount;
      setEditTotalAmount(newTotalAmount);
      setEditPaidAmount(inputAmount);
    }
  };

  //        -------- SEARCH ---------

  const excelData =
    data &&
    data.map((obj) => {
      const { Id, code, message, Status, AdminID, ...rest } = obj;
      return rest;
    });

  const handleExportData = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });

    const formattedDate = new Date().toISOString().slice(0, 10); // Get today's date
    const filename = `Receipt_${formattedDate}.csv`;

    const csv = generateCsv(csvConfig)(excelData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const columnsToExport = [
      { id: "serialNo", header: "S No." },
      ...columns2.filter((column) => selectedColumns.includes(column.id)),
    ];

    const tableHeaders = columnsToExport.map((c) => c.header);

    const formattedDate = new Date().toLocaleDateString("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });

    const filename = `Receipt_${formattedDate}.pdf`;
    
    const addWatermarkToFirstPage = () => {
        const watermarkHeight = 20;
        doc.setFontSize(10);
        doc.text(
            10,
            watermarkHeight + -10,
            `Generated by : ${localStorage.getItem("name")} `
        );
        doc.text(172, watermarkHeight + -10, `Date: ${formattedDate}`);
        doc.setFontSize(16);
        doc.text("Receipt Table", 79, 20);
    };

    const watermarkHeight = 20;
    addWatermarkToFirstPage();
    const tableData = rows.map((row, index) => {
        const formattedDateInPdf = row.original["Date"]
            ? format(new Date(row.original["Date"]), "dd/MM/yyyy")
            : "";

        return [
            { content: (index + 1).toString(), styles: { fontStyle: "bold" } },
            ...columnsToExport.slice(1).map((column) => {
           
                if (column.id === "Date") {
                    return formattedDateInPdf;
                }

                const value =
                    column.id in row.original ? row.original[column.id] : "";
                return value !== null && value !== undefined ? value : "";
            }),
        ];
    });

    tableData.push([]);
    const extraRow = ["", "", "Total", `${totalPaidAmount}/-` ];
    tableData.push(extraRow);

    autoTable(doc, {
        head: [tableHeaders],
        body: tableData,
        startY: watermarkHeight + 5 ,
    });

    const pdfBlob = doc.output("blob");
    const blobUrl = URL.createObjectURL(pdfBlob);
    window.open(blobUrl, "_blank");

    URL.revokeObjectURL(blobUrl);
};

  const table = useMaterialReactTable({
    columns:   localStorage.getItem("uId") ? columns1 : columns2,
    data,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    initialState: {
      columnVisibility: {
        Status: false,
        CreatedBy: false,
        UpdatedBy: false,
        UpdatedDate: false,
        CreatedDate: false,
      },
    },
    enableStickyHeader: true,
    enableRowNumbers: true,
    state: {
      isLoading: loading,
    },
    muiCircularProgressProps: {
      color: "primary",
      thickness: 5,
      size: 55,
      style: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000,
      },
    },
    muiSkeletonProps: {
      animation: "pulse",
      height: 20,
    },
    muiPaginationProps: {
      rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
      showFirstButton: true,
      showLastButton: true,
    },
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export Excel
        </Button>

        <Button
        disabled={!table || table.getPrePaginationRowModel().rows.length === 0}
        onClick={handleExportButtonClick}
        startIcon={<FileDownloadIcon />}
      >
        Export PDF
      </Button>
      <Dialog open={open} onClose={handleClose1}>
        <DialogTitle>Select Columns To Export</DialogTitle>
        <DialogContent>
            {columns2.map((column) =>
              column.header !== "Action" ? (
                <div key={column.id}>
                  <Checkbox
                    checked={selectedColumns.includes(column.id)}
                    onChange={() => handleToggleColumn(column.id)}
                  />
                  {column.header}
                </div>
              ) : (
                ""
              )
            )}
          </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1}>Cancel</Button>
          <Button onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}>Export</Button>
        </DialogActions>
      </Dialog>
      </Box>
    ),
  });

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getCaseView();
    }
  }, []);

  return (
    <>
      <DocumentTitle title=" RECEIPT | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  {/* <div className="accountContainer userListContainer"> */}
                  <div className="pageContent">
                    <div className="PageTitle">
                      <h3>Receipt List</h3>
                      <a
                        style={{
                          display: isAdd === "0" ? "none" : "block",
                        }}
                        type="button"
                        className="btn btn-primary btn_client headbtn"
                        data-toggle="modal"
                        data-target="#exampleModalLong"
                      >
                        Add Receipt
                        <img src="assets/img/icons/add.svg" />
                      </a>
                    </div>
                    <div
                      className="filter_type add_wrap"
                      style={{
                        display: isView === "0" ? "none" : "block",
                      }}
                    >
                      <div className="form_flex1 flex_wrap  caseMobile">
                        <div className="form-group1 zIndex1">
                          <label>File No.</label>

                          <Select
         
                            className="basic-single"
                            placeholder="Please Select "
                            classNamePrefix="select"
                            onChange={handleFilterCase}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={optionsEvent}
                          />
                        </div>
                        <div className="form-group1 zIndex2">
                          <label>Client Name</label>

                          <Select
      
                            className="basic-single"
                            placeholder="Please Select "
                            classNamePrefix="select"
                            onChange={handleFilterClient}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={fClientOption}
                          />
                        </div>
                        <div className="form-group1">
                          <label>From Date</label>
                          <input
                            max={currentDate}
                            autoComplete="off"
                            className="name"
                            type="date"
                            value={fFromDate}
                            onChange={(e) => setFFromDate(e.target.value)}
                          />
                        </div>
                        <div className="form-group1">
                          <label>To Date</label>
                          <input
                            autoComplete="off"
                            className="name"
                            type="date"
                            value={fToDate}
                            onChange={(e) => setFToDate(e.target.value)}
                          />
                        </div>
                        <div className="form-group1 caseMobile btnGroup">
                          <div className="btn_main casebtn">
                            <button
                              type="submit"
                              className="btn btn_client"
                              onClick={filterApi}
                            >
                              Search
                            </button>
                            <button
                              type="button"
                              className="btn btn_client"
                              onClick={filterCancel}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: isView === "0" ? "none" : "block",
                      }}
                      className="searDesign"
                    >
                      <MaterialReactTable table={table} />
                    </div>
               

                 
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <Footer/>
            </div>

            {/*-------- DELETE FORM -------*/}

            <div
              className="modal fade sc_modal"
              id="exampleModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close mm"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <h4>Delete Row</h4>
                    <p>Are you sure you want to delete this Receipt ?</p>
                    <ul>
                      <li>
                        <a
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </a>
                      </li>
                      <li>
                        <a
                          className="btn btn-danger"
                          onClick={() => deleteCaseLead(delId)}
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- ADD FORM ---------*/}

            <div
              className="modal fade"
              id="exampleModalLong"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
              show={show}
            >
              <div
                className="modal-dialog modal-xl"
                role="document"
                // style={{ maxWidth: "70%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Add Receipt </h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="name">
                              File No. <text className="m_star">*</text>
                            </label>
                            <Select
                              required
                              className="basic-single"
                              placeholder="Please Select "
                              classNamePrefix="select"
                              onChange={handleSelectChange}
                              isClearable={true}
                              isSearchable={true} 
                              name="color"
                              options={optionsEvent}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="invoice">
                              Invoice <text className="m_star">*</text>
                            </label>

                            <select
                              className="name"
                              id="cars"
                              onChange={(e) => setInvoiceID(e.target.value)}
                              required
                            >
                              <option value="">--Please Select--</option>
                              {Invoice1 && Invoice1.length ? (
                                Invoice1.map((val, index) => {
                                  return (
                                    <option key={index} value={val.Id}>
                                      {`InvoiceNo - ${
                                        val.InvoiceNo
                                      } / Amount - ${
                                        val.InvoiceAmount - val.PaidAmount
                                      } / Date - ${formatDate1(val.Date)}`}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">
                              Date <text className="m_star">*</text>
                            </label>
                            <input
                              required
                              value={Dates}
                              type="Date"
                              max={currentDate}
                              className="name"
                              onChange={(e) => setDates(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Transaction Type <text className="m_star">*</text>
                            </label>
                            <select
                              required
                              className="name"
                              onChange={(e) =>
                                setTransactionType(e.target.value)
                              }
                            >
                              <option value="">--Please Select--</option>
                              <option value="CASH">CASH</option>
                              <option value="CHEQUE">CHEQUE</option>
                              <option value="NEFT/RTGS/IMPS">
                                NEFT/RTGS/IMPS
                              </option>
                              <option value="CARD PAYMENT">CARD PAYMENT</option>
                              <option value="PAYMENT GATEWAY">
                                PAYMENT GATEWAY
                              </option>
                              <option value="OTHERS">OTHERS</option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label for="uname">Payment Detail</label>
                            <input
                              autoComplete="off"
                              className="name"
                              type="text"
                              placeholder="Enter Payment Detail"
                              onChange={(e) => setDetails(e.target.value)}
                            />
                          </div>
                        </div>

                        <hr />
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="amount">Amount</label>
                            <input
                            value={PaidAmount}
                              type="text"
                              className="name"
                              placeholder="Enter Amount"
                              onChange={handleAmount}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="tds">TDS Amount</label>
                            <input
                            value={TDSAmount}
                              type="text"
                              className="name"
                              placeholder="Enter TDS"
                              onChange={handleTdsAmount}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="amount">Total Amount</label>
                            <input
                              type="text"
                              readOnly
                              className="name"
                              placeholder="Enter Amount"
                              value={totalAmount}
                            />
                          </div>
                        </div>
                     {
                      saveButton ? (
                        <div className="btn_main caseMobile">
                        <button type="submit" className="btn_save">
                          Save
                        </button>
                        <button
                          type="button"
                          data-dismiss="modal"
                          className="btn_save btn_cancle"
                        >
                          Cancel
                        </button>
                      </div>
                      ) : ""
                     }
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- EDIT FORM ---------*/}

            <div
              className="modal fade"
              id="exampleModalLongEdit"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-xl"
                role="document"
                // style={{ maxWidth: "70%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Edit Receipt</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close e_popup btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitEditHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="name">
                              File No.<text className="m_star">*</text>
                            </label>

                            <select
                              className="name"
                              id="cars"
                              onChange={(e) => setEditCaseID(e.target.value)}
                              required
                            >
                              <option value="">--Please Select--</option>
                              {optionsEvent && optionsEvent.length ? (
                                optionsEvent.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.value}
                                      selected={val.value === eCaseID}
                                    >
                                      {val.label}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="invoice">
                              Invoice <text className="m_star">*</text>
                            </label>
                            <select
                              className="name"
                              id="cars"
                              onChange={(e) => setEditInvoiceId(e.target.value)}
                              required
                            >
                              <option value="">--Please Select--</option>
                              {Invoice1 && Invoice1.length ? (
                                Invoice1.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.Id}
                                      selected={val.Id === EditInvoiceId}
                                    >
                                      {`InvoiceNo - ${
                                        val.InvoiceNo
                                      } / Amount - ${
                                        val.InvoiceAmount - val.PaidAmount
                                      } / Date - ${formatDate1(val.Date)}`}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">
                              Date <text className="m_star">*</text>
                            </label>
                            <input
                              required
                              value={EditDate}
                              max={currentDate}
                              type="Date"
                              className="name"
                              onChange={(e) => setEditDate(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Transaction Type<text className="m_star">*</text>
                            </label>
                            <select
                              required
                              value={EditTransactionType}
                              className="name"
                              onChange={(e) =>
                                setEditTransactionType(e.target.value)
                              }
                            >
                              <option value="">--Please Select--</option>
                              <option value="CASH">CASH</option>
                              <option value="CHEQUE">CHEQUE</option>
                              <option value="NEFT/RTGS/IMPS">
                                NEFT/RTGS/IMPS
                              </option>
                              <option value="CARDPAYMENT">CARD PAYMENT</option>
                              <option value="PAYMENTGATEWAY">
                                PAYMENT GATEWAY
                              </option>
                              <option value="OTHERS">OTHERS</option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label for="uname">Payment Detail</label>
                            <input
                              autoComplete="off"
                              className="name"
                              type="text"
                              placeholder="Enter Payment Detail"
                              value={EditDetails}
                              onChange={(e) => setEditDetails(e.target.value)}
                            />
                          </div>
                        </div>
                        <hr />
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="amount">Amount</label>
                            <input
                              type="text"
                              className="name"
                              placeholder="Enter Amount"
                              value={EditPaidAmount}
                              onChange={editHandleAmount}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="tds">TDS Amount</label>
                            <input
                              type="text"
                              className="name"
                              placeholder="Enter TDS"
                              value={EditTDSAmount}
                              onChange={editHandleTdsAmount}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="amount">Total Amount</label>
                            <input
                              type="text"
                              readOnly
                              className="name"
                              placeholder="Enter Amount"
                              value={editTotalAmount}
                            />
                          </div>
                        </div>
                        <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn_save btn_cancle"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- DETAILS ---------*/}

            <div
              className="modal fade"
              id="exampleModalLongDetail"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Receipt Details</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close ab btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>Case Name &nbsp;: &nbsp; {dCaseID} </h4>
                        </div>
                        <div className="form-group">
                          <h4>Case Year &nbsp;: &nbsp; {dCaseYear} </h4>
                        </div>
                      </div>
                     
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>Total Amount &nbsp;: &nbsp; {dTotalAmount} </h4>
                        </div>
                        <div className="form-group">
                          <h4>Date &nbsp;: &nbsp; {formatDate1(dDate)} </h4>
                        </div>
                      </div>
                     
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>TDS &nbsp;: &nbsp;₹ {dTDSAmount} </h4>
                        </div>
                        <div className="form-group">
                          <h4>Paid Amount &nbsp;: &nbsp;₹ {dPaidAmount} </h4>
                        </div>
                      </div>
                     
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Receipt No. &nbsp;: &nbsp;{" "}
                            {dReceiptNo === null ? "-" : dReceiptNo}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Invoice No. &nbsp;: &nbsp;{" "}
                            {dInvoiceNo === null ? "-" : dInvoiceNo}{" "}
                          </h4>
                        </div>
                      </div>
                     
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Transaction Type &nbsp;: &nbsp; {dTransactionType}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>Added By &nbsp;: &nbsp; {dAddedBy} </h4>
                        </div>
                      </div>
                     
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Detail &nbsp;: &nbsp;{" "}
                            {dDetails === "" ? "-" : dDetails}{" "}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- TOASTER ---------*/}

            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        )}
      </DocumentTitle>
    </>
  );
};

export default Receipts;
