import React, { useState, useRef } from "react";
import Swal from "sweetalert";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { Link, useParams } from "react-router-dom";
import Preloader from "./Preloader";
import DocumentTitle from "react-document-title";
import {
  formatDate1,
  getcourtView,
  getClient,
  deleteUser,
  userDetails,
  activeStatus,
  permission,
  formateDateTime,
  getBranchCity,
} from "../services/api.service";
// import Swal from "sweetalert2";
import Footer from "./Footer";
const CaseDetails = () => {
  const [saveButton, setSaveButton] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const currentDate = new Date().toISOString().split("T")[0];

  // ----------------------------- Manish Prajapat ------------------

  // Edit Client

  const [editBranchCity, setEditBranchCity] = useState("");
  const [branchCityData, setBranchCityData] = useState([]);
  const Rcp_add = document.querySelector(".Rcp_add");
  const closeCasEedit = document.querySelector(".closeCasEedit");
  const editCaseLead = new FormData();
  const duplicateCaseForm = new FormData();
  const configForm = new FormData();
  const editCaseDelete = new FormData();
  const [showClosingDate, setShowClosingDate] = useState(false);
  const [taskRelated, setTaskRelated] = useState("Customer");
  const [cocounselRow, setCocounselRow] = useState([
    { coCounselName: "", coCounselShare: "" },
  ]);
  const [title, setTitle] = useState("");
  const [compantPrecentage, setCompantPrecentage] = useState("");
  const [totalCoCounsel, setTotalCoCounsel] = useState("0");
  const [viewUserData, setViewUserData] = useState([]);
  const [optionsUser, setOptionsUser] = useState([]);
  const ViewUserData = new FormData();
  const [isValid1, setIsValid1] = useState(true);
  const [showInputField, setShowInputField] = useState(false);
  const [optionsYear, setOptionYear] = useState([]);
  const [caseType, setCaseType] = useState([]);
  const [courtData, setCourtData] = useState([]);
  const [year, setYear] = useState([]);
  const [viewUserCoCounselData, setViewUserCoCounselData] = useState([]);
  const [inputWidth, setInputWidth] = useState(6);
  const [title1, setTitle1] = useState("");
  const [title2, setTitle2] = useState("");
  const [type, setType] = useState("");
  const [court, setCourt] = useState("");
  const [cEditId, setCEditId] = useState("");
  const [caseClientData, setCaseClientData] = useState([]);
  const [editfilingDate, setEditFilingdate] = useState("");
  const [closingDate, setClosingDate] = useState("");
  const [isOpen, setIsOpen] = useState("1");
  const [editnewOld, setEditNewOld] = useState("");
  const [editdiaryNo, setEditDiaryNo] = useState("");
  const [editdiaryYear, setEditDiaryYear] = useState("");
  const [editcaseNo, setEditCaseNo] = useState("");
  const [editcaseYear, setEditCaseYear] = useState("");
  const [ceditclient, setCEditClient] = useState("");
  const [edittitle, setEditTitle] = useState("");
  const [ceditsummary, setCEditSummary] = useState("");
  const [editfixedFees, setEditFixedFees] = useState("");
  const [editexpenses, setEditExpenses] = useState("");
  const [editappearanceFee, setEditAppearanceFee] = useState("");
  const [editnonAppearanceFee, setEditNonAppearanceFee] = useState("");
  const [editcounsel, setEditCounsel] = useState("");
  const [customerDropDownShow, setCustomerDropDownShow] = useState(true);
  const [editcounselShare, setEditCounselShare] = useState("");
  const [editcoCounselShare, setEditCoCounselShare] = useState("");
  const [editaor, setEditAor] = useState("");
  const [editretaineed, setEditRetaineed] = useState("");
  const [editpatitioners, setEditPatitioners] = useState("");
  const [editrespondants, setEditRespondants] = useState("");
  const [editintervenors, setEditIntervenors] = useState("");
  const [contactPersonName, setContactPersonName] = useState("");
  const [contactPersonContact, setContactPersonContact] = useState("");
  const [conPersonName, setConPersonName] = useState("");
  const [conPersonContact, setConPersonContact] = useState("");
  const [dContactPersonName, setDContactPersonName] = useState("");
  const [dContactPersonContact, setDContactPersonContact] = useState("");

  // ----------- VIEW Year--------

  const ViewYearData = new FormData();
  ViewYearData.append("AdminID", localStorage.getItem("id"));
  const getYear = async () => {
    try {
      const response = await fetch(`${baseUrl}Master/ViewYear`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: ViewYearData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }

      const y_data = data && data.result.filter((item) => item.Status === "1");
      const Mapped =
        y_data &&
        y_data.map((item) => ({
          value: item.Year,
          label: item.Year,
        }));
      setOptionYear(Mapped);
      if (data.response_code === 400) {
        toast.error("Token expired please login again");
      }
    } catch (error) {
      console.error(error);
    }
  };

  //------------ EDIT CASE ----------

  const editDataSave = async () => {
    const filter =
      cocounselRow &&
      cocounselRow.filter(
        (item) => item.coCounselName !== "" && item.coCounselName !== "0"
      );

    const coCounselNameData =
      filter &&
      filter.map((coCounsel) =>
        coCounsel.coCounselName === "" ? "0" : coCounsel.coCounselName
      );
    const coCounselshareData =
      filter &&
      filter.map((coCounsel) =>
        coCounsel.coCounselShare === "" ? "0" : coCounsel.coCounselShare
      );

    const CounselName = [editcounsel, ...coCounselNameData];

    const CounselPer = [editcounselShare, ...coCounselshareData];

    const CounselType =
      CounselPer &&
      CounselPer.map((obj, index) => {
        return index === 0 ? 1 : 2;
      });

    editCaseLead.append("ID", cEditId);
    editCaseLead.append("AdminID", localStorage.getItem("id"));
    editCaseLead.append("UpdatedBy", localStorage.getItem("UserId"));
    editCaseLead.append("CourtID", court);
    editCaseLead.append("StateID", StateID);
    editCaseLead.append("CaseTypeID", type === "0" ? "" : type);
    editCaseLead.append("DistrictID", CityID);
    editCaseLead.append("CityID", cityData);
    editCaseLead.append("DiaryNo", editdiaryNo);
    editCaseLead.append("DiaryYear", editdiaryYear);
    editCaseLead.append("CaseNo", editcaseNo);
    editCaseLead.append("CaseYear", editcaseYear);
    editCaseLead.append("CounselID", CounselName);
    editCaseLead.append("CounselPer", CounselPer);
    editCaseLead.append("CounselType", CounselType);
    editCaseLead.append("ClientID", ceditclient);
    editCaseLead.append("CaseName", edittitle.toUpperCase());
    editCaseLead.append("CaseSummary", ceditsummary.toUpperCase());
    editCaseLead.append("Petitioners", editpatitioners.toUpperCase());
    editCaseLead.append("Intervenors", editintervenors.toUpperCase());
    editCaseLead.append("FixedFees", editfixedFees);
    editCaseLead.append("AppearanceFee", editappearanceFee);
    editCaseLead.append("AOR", editaor.toUpperCase());
    editCaseLead.append("Respondants", editrespondants.toUpperCase());
    editCaseLead.append("RetainedFor", editretaineed);
    editCaseLead.append("Expenses", editexpenses);
    editCaseLead.append("NonAppearanceFee", editnonAppearanceFee);
    editCaseLead.append("FilingDate", editfilingDate);
    // editCaseLead.append("VillageID", villageID);
    editCaseLead.append("IsOpen", isOpen);
    editCaseLead.append("ClosingDate", closingDate);
    editCaseLead.append("BranchCity", editBranchCity);

    try {
      const response = await fetch(`${baseUrl}Cases/EditCaseRecords`, {
        method: "POST",
        body: editCaseLead,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message === "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeCasEedit.click();
          navigate("/");
        }, 1000);
      }

      if (data.result.length && data.result[0].message === "Success") {
        toast.success(" Updated successfully");
        getCaseHistory();
        closeCasEedit.click();
        setTitle("");
        setTitle1("");
        setTitle2("");
        setCocounselRow([{ coCounselName: "", coCounselShare: "" }]);
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const editsweetAlert = () => {
    Swal({
      title: "Are you sure ?",
      text: "We have identified a duplicate record based on the combination of Case year, Court name ,Case type , Client name  and Case No. Are you certain you wish to proceed with adding this duplicate record ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        editDataSave();
      } else {
        Swal("Case not added !");
      }
    });
  };

  const editDuplicateCase = async () => {
    duplicateCaseForm.append("CaseYear", editcaseYear);
    duplicateCaseForm.append("CaseNo", editcaseNo);
    duplicateCaseForm.append("CourtID", court);
    duplicateCaseForm.append("CaseTypeID", type);
    duplicateCaseForm.append("ClientID", ceditclient);
    try {
      const response = await fetch(`${baseUrl}Cases/IsDuplicateCase`, {
        method: "POST",
        body: duplicateCaseForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data = await response.json();

      if (data && data.result[0].IsDuplicateCase === "1") {
        editsweetAlert();
      } else {
        editDataSave();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const submitCaseEditHandler = async (event) => {
    event.preventDefault();
    editDuplicateCase();
  };

  const editRemoveRow = async (indexToRemove, Id) => {
    editCaseDelete.append("AdminID", localStorage.getItem("id"));
    editCaseDelete.append("ID", Id);
    editCaseDelete.append("CaseID", cEditId);
    editCaseDelete.append("DeletedBy", localStorage.getItem("UserId"));

    try {
      const response3 = await fetch(`${baseUrl}Cases/DeleteCaseDeatil`, {
        method: "POST",
        body: editCaseDelete,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
    } catch (error) {
      console.error(error);
    }

    if (cocounselRow.length === 1) {
      return;
    }

    let updatedFormData = cocounselRow.filter(
      (_, index) => index !== indexToRemove
    );

    setTotalCoCounsel(
      totalCoCounsel - cocounselRow[indexToRemove].coCounselShare
    );
    setCocounselRow(updatedFormData);
  };

  const getCountriesUser = async () => {
    ViewUserData.append("AdminID", localStorage.getItem("id"));
    ViewUserData.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}Admin/ViewUser`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: ViewUserData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }

      const clientData =
        data && data.result.advocateUser.filter((item) => item.Status === "1");

      const sortData =
        clientData &&
        clientData.sort((a, b) => {
          const nameA = a.Name.toUpperCase();
          const nameB = b.Name.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

      setViewUserData(sortData);

      const Mapped =
        clientData &&
        clientData.map((item) => ({
          value: item.Id,
          label: item.Name,
        }));
      setOptionsUser(Mapped);
      if (data.response_code === 400) {
        toast.error("Token expired please login again");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const closeclientEdit = document.querySelector(".closeclientEdit");
  const [EditFirstName, setEditFirstName] = useState("");
  const [EditLastName, setEditLastName] = useState("");
  const [EditCompanyName, setEditCompanyName] = useState("");
  const [EditDesignation, setEditDesignation] = useState("");
  const [EditHouseNo, setEditHouseNo] = useState("");
  const [EditMobileNo, setEditMobileNo] = useState("");
  const [EditPhone, setEditPhone] = useState("");
  const [EditEmail, setEditEmail] = useState("");
  const [EditIsLead, setEditIsLead] = useState("");
  const [EditAddress, setEditAddress] = useState("");
  const [EditPinCode, setEditPinCode] = useState("");
  const [EditSource, setEditSource] = useState("");
  const [EditReferredByClientID, setEditReferredByClientID] = useState("");
  const [EditReferredByCounselID, setEditReferredByCounselID] = useState("");
  const [Edittext, setEditText] = useState("");
  const [EditCountryID, setEditCountryID] = useState("");
  const [EditselectedOption, setEditSelectedOption] = useState("");
  const [ReferredByClientName, setReferredByClientName] = useState("");
  const [ReferredByCounselName, setReferredByCounselName] = useState("");
  const [city_title, setcity_title] = useState("");
  const [district_title, setdistrict_title] = useState("");
  const [state_title, setstate_title] = useState("");
  const [country_title, setcountry_title] = useState("");
  const [editClientId, setEditClientId] = useState("");
  const [cityData, setCityData] = useState("");
  const [StateID, setStateID] = useState("");
  const [villageID, setVillageID] = useState("");
  const [CityID, setCityID] = useState("");
  const [referredByCounselData, setReferredByCounselData] = useState([]);
  const [referredByClientData, setReferredByClientData] = useState([]);
  const [isValid, setIsValid] = useState(true);
  const [village, setVillage] = useState([]);
  const [City, setCity] = useState([]);
  const [District, setDistrict] = useState([]);
  const [State, setState] = useState([]);
  const [CountryID, setCountryID] = useState("1");
  const [UserList, setUserList] = useState([]);
  const ViewData = new FormData();
  const c = new FormData();
  const detailsForm = new FormData();
  const district = new FormData();
  const villegeForm = new FormData();

  const userById = new FormData();
  const updateUser = async (id) => {
    userById.append("AdminID", `${localStorage.getItem("id")}`);
    userById.append("ID", id);

    try {
      const response = await fetch(`${baseUrl}Client/ViewClientById`, {
        method: "POST",
        body: userById,

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data5 = await response.json();
      if (data5 && data5.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      setEditClientId(data5.result[0].Id);
      setEditFirstName(data5.result[0].FirstName);
      setEditLastName(data5.result[0].LastName);
      setEditCompanyName(data5.result[0].CompanyName);
      setEditDesignation(data5.result[0].Designation);
      setEditHouseNo(data5.result[0].HouseNo);
      setEditMobileNo(data5.result[0].MobileNo);
      setEditPhone(data5.result[0].Phone);
      setEditEmail(data5.result[0].Email);
      setEditIsLead(data5.result[0].IsLead);
      setEditSelectedOption(data5.result[0].IsLead);
      setCityData(
        data5.result[0].CityID === "0" ? "" : data5 && data5.result[0].CityID
      );
      setEditAddress(data5.result[0].Address);
      setEditPinCode(data5.result[0].PinCode);
      setVillageID(data5.result[0].VillageID);
      setEditSource(data5.result[0].Source);
      setEditReferredByClientID(
        data5.result[0].ReferredByClientID === "0"
          ? ""
          : data5 && data5.result[0].ReferredByClientID
      );
      setEditReferredByCounselID(
        data5.result[0].ReferredByCounselID === "0"
          ? ""
          : data5 && data5.result[0].ReferredByCounselID
      );
      setEditText(data5.result[0].Comments);
      setEditCountryID(
        data5.result[0].CountryID === "0"
          ? ""
          : data5 && data5.result[0].CountryID
      );
      setStateID(
        data5 && data5.result[0].StateID === "0"
          ? ""
          : data5 && data5.result[0].StateID
      );
      setCityID(
        data5.result[0].DistrictID === "0"
          ? ""
          : data5 && data5.result[0].DistrictID
      );
      setIsStatus(data5.result[0].Status);
      setReferredByClientName(
        data5.result[0].ReferredByClientName === "0"
          ? ""
          : data5 && data5.result[0].ReferredByClientName
      );
      setReferredByCounselName(
        data5.result[0].ReferredByCounselName === "0"
          ? ""
          : data5 && data5.result[0].ReferredByCounselName
      );
      setcity_title(data5.result[0].city_title);
      setdistrict_title(data5.result[0].district_title);
      setstate_title(data5.result[0].state_title);
      setcountry_title(data5.result[0].state_title);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange1 = (e) => {
    const value = e.target.value;
    const isValidInput = /^\d{10}$/.test(value);

    setEditMobileNo(value);
    setIsValid(isValidInput);
  };

  const getCity = async () => {
    c.append("district_id", CityID);

    try {
      const response = await fetch(`${baseUrl}Master/ViewCityByDistrictid`, {
        method: "POST",
        body: c,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data1 = await response.json();
      if (data1 && data1.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      setCity(data1.result);
    } catch (error) {
      console.error(error);
    }
  };

  const getDistrict = async () => {
    district.append("state_id", StateID);
    try {
      const response = await fetch(`${baseUrl}Master/ViewDistricByStateID`, {
        method: "POST",
        body: district,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data3 = await response.json();
      if (data3 && data3.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      setDistrict(data3.result);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange3 = (e) => {
    const value = e.target.value;
    const isValidInput = /^\d{10}$/.test(value);

    setEditPhone(value);
    setIsValid(isValidInput);
  };

  const handleKeyPressalpha = (e) => {
    const charCode = e.which || e.keyCode;

    if (
      (charCode < 65 || charCode > 90) &&
      (charCode < 97 || charCode > 122) &&
      charCode !== 8 &&
      charCode !== 32 &&
      charCode !== 44
    ) {
      e.preventDefault();
    }
  };

  const handleKeyPress1 = (e) => {
    const charCode = e.which || e.keyCode;
    if (
      charCode < 48 ||
      charCode > 57 ||
      (charCode !== 8 && charCode !== 47 && e.target.value.length >= 6)
    ) {
      e.preventDefault();
    }
  };

  const handleStateChange = (e) => {
    setStateID(e.target.value);
    setCityData("");
    setCityID("");
    getDistrict();
  };

  useEffect(() => {
    getCity();
    setVillage("");
  }, [CityID]);

  useEffect(() => {
    getVillege();
  }, [cityData]);

  useEffect(() => {
    getDistrict();
    setCity("");
  }, [StateID]);

  const getVillege = async () => {
    villegeForm.append("city_id", cityData);
    try {
      const response = await fetch(`${baseUrl}Master/ViewVillageByCityId`, {
        method: "POST",
        body: villegeForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      setVillage(data.result);
    } catch (error) {
      console.error(error);
    }
  };

  const getUserClientApi = async () => {
    form.append("AdminID", localStorage.getItem("id"));
    try {
      const response = await fetch(`${baseUrl}Task/ViewUserList`, {
        method: "POST",
        body: form,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result[0].message == "Success") {
        setUserList(data && data.result);
        setReferredByCounselData(data && data.result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDistrictChange = (e) => {
    setCityID(e.target.value);
    getCity(e.target.value);
    setCityData("");
  };

  const handleTextChange1 = (event) => {
    setEditText(event.target.value);
  };

  const handleEditReferredByClientID = (value) => {
    if (value === "") {
      setEditReferredByClientID("");
      setReferredByCounselData(UserList);
    } else {
      setEditReferredByClientID(value);
      setEditReferredByCounselID("");
      setReferredByCounselData([]);
    }
  };

  const handleCityChange = (e) => {
    setCityData(e.target.value);
  };

  const handleOptionChange3 = (event) => {
    setEditSource(event.target.value);
  };

  const handleOptionChange2 = (event) => {
    setEditSelectedOption(event.target.value);
  };

  const viewClientApi = async () => {
    ViewData.append("AdminID", localStorage.getItem("id"));
    try {
      const response = await fetch(`${baseUrl}Client/ViewClient`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: ViewData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
      }

      if (data && data.result[0].message === "Success") {
        const clientData =
          data && data.result.filter((item) => item.Status === "1");
        setReferredByClientData(clientData);
        const Mapped =
          clientData &&
          clientData.map((item) => ({
            value: item.Id,
            label: `${item.FirstName} ${item.LastName}`,
          }));
          console.log(clientData)
        setCaseClientData(Mapped);
      }

      if (data.response_code === 400) {
        toast.error("Token expired please login again");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditReferredByCounselID = (value) => {
    if (value === "") {
      setEditReferredByCounselID("");
      // setReferredByClientData(countries);
    } else {
      setEditReferredByCounselID(value);
      setEditReferredByClientID("");
      setReferredByClientData([]);
    }
  };

  const HandleEditClientSubmit = async (e) => {
    e.preventDefault();
    EditformData.append("ID", editClientId);
    EditformData.append("AdminID", localStorage.getItem("id"));
    EditformData.append("FirstName", EditFirstName.toUpperCase());
    EditformData.append("LastName", EditLastName.toUpperCase());
    EditformData.append("CompanyName", EditCompanyName.toUpperCase());
    EditformData.append("Designation", EditDesignation.toUpperCase());
    EditformData.append("HouseNo", EditHouseNo.toUpperCase());
    EditformData.append("MobileNo", EditMobileNo);
    EditformData.append("Phone", EditPhone);
    EditformData.append("Email", EditEmail.toUpperCase());
    EditformData.append("IsLead", EditselectedOption);
    EditformData.append("CityID", cityData);
    EditformData.append("Address", EditAddress.toUpperCase());
    EditformData.append("PinCode", EditPinCode);
    EditformData.append("Source", EditSource);
    EditformData.append("ReferredByClientID", EditReferredByClientID);
    EditformData.append("ReferredByCounselID", EditReferredByCounselID);

    if (EditCountryID && EditCountryID.length > 0) {
      EditformData.append("CountryID", EditCountryID);
    } else {
      EditformData.append("CountryID", "");
    }

    EditformData.append("StateID", StateID);
    if (villageID && villageID.length > 0) {
      EditformData.append("VillageID", villageID);
    } else {
      EditformData.append("VillageID", " ");
    }
    EditformData.append("DistrictID", CityID);
    EditformData.append("Comments", Edittext.toUpperCase());
    EditformData.append("UpdatedBy", localStorage.getItem("UserId"));

    try {
      const response = await fetch(`${baseUrl}Client/EditClient`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: EditformData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
      }

      if (data && data.result[0].message === "Success") {
        toast.success("Client Updated !");
        getCaseHistory();
        closeclientEdit.click();
      } else {
        toast.error(data.result[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // letter

  const [invoiceIDSettle, setInvoiceIDSettle] = useState("");
  const [clientIdDetail, setClientIdDetail] = useState("");
  const [isView, setIsView] = useState("0");
  const [optionsCD, setOptionsCD] = useState([]);
  const [caseData, setCaseData] = useState([]);
  const ViewCDData = new FormData();
  const [CID, setCID] = useState("");

  //------------ CASE VARIABLE ----------

  const baseUrl = useContext(url);

  const navigate = useNavigate();

  //------------ ADD CASE VARIABLE ----------

  const { id } = useParams();
  const [caseLeadData, setCaseLeadData] = useState([]);

  //------------ VIEW CASE ---------

  const getCaseHistory = async () => {
    viewClientApi();
    getInvoice(id);
    getInvoiceSA(id);

    ViewCDData.append("ID", id);
    ViewCDData.append("AdminID", localStorage.getItem("id"));

    try {
      const response = await fetch(
        `${baseUrl}CaseHistory/ViewCaseHistoryById`,
        {
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
          body: ViewCDData,
        }
      );

      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }

   
      setConPersonName(`${data.result[0].case[0].ClientName} ${data.result[0].case[0].ClientLastName}`);
      setConPersonContact(data.result[0].case[0].MobileNo);

      setOptionsCD(data && data.result[0]);
      setCaseData(data && data.result[0].case[0]);
      setCID(data && data.result[0].case[0].Id);
      setClientIdDetail(data && data.result[0].case[0].ClientID);
    } catch (error) {
      console.error(error);
    }
  };

  const configApi = async () => {
    configForm.append("AdminID", localStorage.getItem("id"));
    try {
      const response = await fetch(`${baseUrl}Config/ViewConfigById`, {
        method: "POST",
        body: configForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      setCompantPrecentage(parseInt(data && data.result[0].CompanyPer));
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCaseView = async () => {
    const [view, coData, data, branchCityData] = await Promise.all([
      getCaseHistory(),
      getcourtView("Master/ViewCourt", court),
      permission(25),
      getBranchCity(),
      getInvoice(id),
      getCountriesUser(),
      configApi(),
      getYear(),
    ]);

    setBranchCityData(branchCityData);
    if (view === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } else {
      const dataIt = await getClient("Master/ViewInvoiceTemplate");
      setCaseLeadData(view);
      setInvoiceTem(dataIt);
    }

    const c_data = coData && coData.filter((item) => item.Status === "1");
    setCourtData(c_data);
    setIsView(data && data.result[0].IsView);
  };

  useEffect(() => {
    getCaseType();
  }, [court]);

  //------------ SEARCH DETAILS ----------

  const formRef = useRef(null);

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      getCaseView();
    }
  }, []);

  // ----------------------Invoice---------------------------------

  const closeDelete = document.querySelector(".dInvoice");
  const closeButton = document.querySelector(".Inv");
  const closeEdit = document.querySelector(".Inv_popup");

  // Add Invoice

  const [dynamicRows, setDynamicRows] = useState([
    { Description: "", Amount: "" },
  ]);

  const [CaseIDInv, setCaseIDInv] = useState("");
  const [invoiceTem, setInvoiceTem] = useState([]);
  const [invoiceTemId, setInvoiceTemId] = useState([]);
  const [Dates, setDates] = useState(currentDate);
  const [remark, setRemark] = useState("");
  const [invoiceTotal, setInvoiceTotal] = useState("0");
  const [Discount, setDiscount] = useState("0");
  const [InvoiceAmount, setInvoiceAmount] = useState("0");
  const form = new FormData();
  const [optionsEvent, setOptionsEvent] = useState([]);
  const [dAddedBy, setDAddedBy] = useState("");
  const [dCaseName, setDCaseName] = useState("");
  const [dCaseYear, setDCaseYear] = useState("");
  const [dClientName, setDClientName] = useState("");
  const [dRemark, setDRemark] = useState("");
  const [dDate, setDDate] = useState(currentDate);
  const [dDiscount, setDDiscount] = useState("");
  const [dInvoiceAmount, setDInvoiceAmount] = useState("");
  const [dInvoiceNo, setDInvoiceNo] = useState("");
  const [dTotalAmount, setDTotalAmount] = useState("");
  const [invoiceDetailData, setInvoiceDetailData] = useState([]);
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState("");
  const [loading, setLoading] = useState(true);

  const [delId, setDelId] = useState("");
  const [isAdd, setIsAdd] = useState("1");
  const [isEdit, setIsEdit] = useState("1");
  const [isDelete, setIsDelete] = useState("1");
  const [isStatus, setIsStatus] = useState("1");
  const [data, setData] = useState([]);
  const [active, setActive] = useState(false);
  const [filterUser, setFilterUser] = useState("");
  const [BankID, setBankID] = useState("");
  const [EditBankID, setEditBankID] = useState("");

  const [formData1, setFormData1] = useState([{ Description: "", Amount: "" }]);
  let sum = 0;
  data &&
    data.map((val, index) => {
      sum += parseFloat(val.InvoiceAmount);
    });

  // Edit Invoice

  const [EditID, setEditID] = useState("");
  const [EditCaseIDINv, setEditCaseIDInv] = useState("");
  const [editInvoiceTemId, setEditInvoiceTemId] = useState("");
  const [EditDate, setEditDate] = useState("");
  const [editRemark, setEditRemark] = useState("");
  const [editInvoiceTotal, setEditInvoiceTotal] = useState("");
  const [EditDiscount, setEditDiscount] = useState("");
  const [EditInvoiceAmount, setEditInvoiceAmount] = useState("");

  const EditformData = new FormData();
  const editInvoiceDelete = new FormData();
  const formData3 = new FormData();

  // for Only Number

  const handleKeyPress = (e) => {
    const charCode = e.which || e.keyCode;
    // Allow only numeric characters and backspace
    if (
      charCode < 48 ||
      charCode > 57 ||
      (charCode !== 8 && e.target.value.length >= 10)
    ) {
      e.preventDefault();
    }
  };
  const HandleEditSubmit = async (event) => {
    event.preventDefault();
    EditformData.append("ID", EditID);
    EditformData.append("AdminID", localStorage.getItem("id"));
    EditformData.append("BankID", EditBankID);
    EditformData.append("CaseID", EditCaseIDINv);
    EditformData.append("InvoiceTemplateID", editInvoiceTemId);
    EditformData.append("Remark", editRemark.toUpperCase());
    EditformData.append("Discount", EditDiscount);
    EditformData.append("InvoiceAmount", EditInvoiceAmount);
    EditformData.append("TotalInvoice", editInvoiceTotal);
    EditformData.append("Date", EditDate);
    EditformData.append("UpdatedBy", localStorage.getItem("UserId"));

    try {
      const response = await fetch(`${baseUrl}Invoice/EditInvoice`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: EditformData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data.result.length && data.result[0].code === "200") {
        const InvoiceId = data && data.result[0].Id;

        editInvoiceDelete.append("AdminID", localStorage.getItem("id"));
        editInvoiceDelete.append("InvoiceID", InvoiceId);
        editInvoiceDelete.append("DeletedBy", localStorage.getItem("UserId"));

        try {
          const response3 = await fetch(
            `${baseUrl}Invoice/DeleteInvoiceDeatil`,
            {
              method: "POST",
              body: editInvoiceDelete,
              headers: {
                Authorization: `${localStorage.getItem("access_token")}`,
                Accept: "*/*",
              },
            }
          );

          const data3 = await response3.json();
          if (data3 && data3.result.message == "Session Time Expire.") {
            toast.error("Token expired please login again");
            setTimeout(() => {
              navigate("/");
            }, 3000);
          }
          if (data3 && data3.result.code === 200) {
            const rowsData = [];

            dynamicRows.forEach((item) => {
              rowsData.push({
                Description: item.Description,
                Amount: item.Amount,
              });
            });

            try {
              for (const rows of rowsData) {
                formData3.append("AdminID", localStorage.getItem("id"));
                formData3.append("InvoiceID", InvoiceId);
                formData3.append("Description", rows.Description.toUpperCase());
                formData3.append("Amount", rows.Amount);
                formData3.append("CreatedBy", localStorage.getItem("UserId"));

                const response2 = await fetch(
                  `${baseUrl}Invoice/AddInvoiceDetail`,
                  {
                    method: "POST",
                    body: formData3,
                    headers: {
                      Authorization: `${localStorage.getItem("access_token")}`,
                      Accept: "*/*",
                    },
                  }
                );

                const data2 = await response2.json();
                if (data2 && data2.result.message == "Session Time Expire.") {
                  toast.error("Token expired please login again");
                  setTimeout(() => {
                    navigate("/");
                  }, 3000);
                }
                if (data2.result.length && data2.result[0].code === "200") {
                  toast.success(" Updated successfully");
                  closeEdit.click();
                  getCaseHistory();
                } else {
                  toast.error(data2 && data2.result.message);
                }
              }
            } catch (error) {
              console.error(error);
            }
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /** Get Bank  */

  const CaseB = new FormData();
  const [Bank, setBank] = useState([]);

  const getBank = async () => {
    CaseB.append("AdminID", localStorage.getItem("id"));
    try {
      const response = await fetch(`${baseUrl}Master/ViewBank`, {
        method: "POST",
        body: CaseB,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].message === "Success") {
        const Mapped = data.result.map((item) => ({
          value: item.Id,
          label: `${item.Beneficiary} / ${item.Bank} / ${item.Branch}`,
        }));
        setBank(Mapped);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getBank();
  }, []);

  //  Delete Invoice

  const deleteUserWithUid = async (id, path) => {
    form.append("AdminID", localStorage.getItem("id"));
    form.append("DeletedBy", localStorage.getItem("UserId"));
    form.append("ID", id);
    form.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}${path}`, {
        method: "POST",
        body: form,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        return "token";
      }

      if (data.result.code === 200) {
        toast.success(data.result.message);
        return true;
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteInvoice = async () => {
    const data = await deleteUserWithUid(delId, "Invoice/DeleteInvoice");
    if (data === true) {
      closeDelete.click();
      getCaseHistory();
    }
  };

  /** Get File Name  */
  // By Disha 16-09-23
  const Case = new FormData();
  const [optionsCase, setOptionsCase] = useState([]); // Initialize options state

  const getFileNo = async () => {
    Case.append("AdminID", localStorage.getItem("id"));
    Case.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: Case,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].message === "Success") {
        const Mapped = data.result.map((item) => ({
          value: item.CaseID,
          label: `${item.FileNo} / ${item.CaseNo} / ${item.CaseName} / ${item.CaseYear}`,
        }));
        setOptionsCase(Mapped);
        setOptionsEvent(Mapped);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getState = async () => {
    try {
      const response = await fetch(`${baseUrl}Master/ViewState`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data2 = await response.json();
      if (data2 && data2.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      setState(data2.result);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      setCaseIDSA("");
    } else {
      setCaseIDSA(selectedOption.value);
    }
  };

  const deActive = async (row, path) => {
    const data = await activeStatus(row, path);
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }

    if (data === true) {
      getCaseHistory();
    }
  };

  const getById = async (id) => {
    const data = await userDetails(id, "Invoice/ViewInvoiceById");
    const Invoicedata = await userDetails(id, "Invoice/ViewInvoiceDetailById");

    if (data === "token") {
      setTimeout(() => {
        closeEdit.click();
        navigate("/");
      }, 1000);
    } else {
      setDynamicRows(Invoicedata && Invoicedata.result);
      setEditID(data && data.result[0].Id);
      setEditBankID(data && data.result[0].BankID);
      setEditCaseIDInv(data && data.result[0].CaseID);
      setEditRemark(data && data.result[0].Remark);
      setEditDate(data && data.result[0].Date);
      setEditDiscount(data && data.result[0].Discount);
      setEditInvoiceAmount(data && data.result[0].InvoiceAmount);
      setEditInvoiceTemId(
        data && data.result[0].InvoiceTemplateID === "0"
          ? ""
          : data.result[0].InvoiceTemplateID
      );
      setEditInvoiceTotal(data && data.result[0].TotalAmount);
    }
  };

  const details = async (id) => {
    const data = await userDetails(id, "Invoice/ViewInvoiceById");
    const Invoicedata = await userDetails(id, "Invoice/ViewInvoiceDetailById");

    if (data === "token") {
      setTimeout(() => {
        closeEdit.click();
        navigate("/");
      }, 1000);
    } else {
      setInvoiceDetailData(Invoicedata && Invoicedata.result);
      setDInvoiceNo(data && data.result[0].InvoiceNo);
      setDDate(data && data.result[0].Date);
      setDInvoiceAmount(data && data.result[0].InvoiceAmount);
      setDClientName(data && data.result[0].ClientName);
      setDCaseName(data && data.result[0].CaseName);
      setDCaseYear(data && data.result[0].CaseYear);
      setDRemark(data && data.result[0].Remark);
      setDDiscount(data && data.result[0].Discount);
      setDTotalAmount(data && data.result[0].TotalAmount);
      setDAddedBy(data && data.result[0].AddedBy);
      setDContactPersonName(data && data.result[0].ClientName1);
      setDContactPersonContact(data && data.result[0].ClientNumber1);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      getState();
      getUserClientApi();
      getCaseHistory();
    }
  }, []);

  const handleDescriptionChange = (index, value) => {
    const updatedFormData = [...formData1];
    updatedFormData[index].Description = value;
    setFormData1(updatedFormData);
  };

  const handleAmountChange = (index, value) => {
    const updatedFormData = [...formData1];
    updatedFormData[index].Amount = value;

    let sum = updatedFormData.reduce(function (prev, current) {
      return prev + +current.Amount;
    }, 0);

    setInvoiceTotal(sum);
    setInvoiceAmount(sum - Discount);
    setFormData1(updatedFormData);
  };

  const addRow = () => {
    setFormData1([...formData1, { Description: "", Amount: "" }]);
  };

  const removeRow = (indexToRemove) => {
    if (formData1.length === 1) {
      return;
    }
    let updatedFormData = formData1.filter(
      (_, index) => index !== indexToRemove
    );

    setInvoiceTotal(invoiceTotal - formData1[indexToRemove].Amount);
    setInvoiceAmount(InvoiceAmount - formData1[indexToRemove].Amount);
    setFormData1(updatedFormData);
  };

  const editRremoveRow = (indexToRemove) => {
    if (dynamicRows.length === 1) {
      return;
    }
    let updatedFormData = dynamicRows.filter(
      (_, index) => index !== indexToRemove
    );

    setEditInvoiceTotal(editInvoiceTotal - dynamicRows[indexToRemove].Amount);
    setEditInvoiceAmount(EditInvoiceAmount - dynamicRows[indexToRemove].Amount);
    setDynamicRows(updatedFormData);
  };

  const handelDiscount = () => {
    let sum = formData1.reduce(function (prev, current) {
      return prev + +current.Amount;
    }, 0);

    let dis = sum - Discount;
    setInvoiceAmount(dis);
  };

  useEffect(() => {
    handelDiscount();
  }, [Discount]);

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setSaveButton(false);
    const formData = new FormData();
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("CaseID", id);
    formData.append("BankID", BankID);
    formData.append("InvoiceTemplateID", invoiceTemId);
    formData.append("TotalInvoice", invoiceTotal);
    formData.append("Remark", remark.toUpperCase());
    formData.append("Discount", Discount);
    formData.append("InvoiceAmount", InvoiceAmount);
    formData.append("Date", Dates);
    formData.append(
      "ClientName",
      taskRelated === "Customer" ? "" : contactPersonName
    );
    formData.append(
      "ClientNumber",
      taskRelated === "Customer" ? "" : contactPersonContact
    );
    formData.append("CreatedBy", localStorage.getItem("UserId"));

    try {
      const response = await fetch(`${baseUrl}Invoice/AddInvoice`, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("access_token"),
          Accept: "*/*",
        },
        body: formData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data.result.length && data.result[0].code === "200") {
        const InvoiceID = data.result[0].InvoiceID;
        const rowsData = [];
        formData1.forEach((item) => {
          rowsData.push({
            Description: item.Description,
            Amount: item.Amount,
          });
        });

        try {
          for (const row of rowsData) {
            const formData2 = new FormData();
            formData2.append("AdminID", localStorage.getItem("id"));
            formData2.append("InvoiceID", InvoiceID);
            formData2.append("Description", row.Description.toUpperCase());
            formData2.append("Amount", row.Amount);
            formData2.append("CreatedBy", localStorage.getItem("UserId"));
            const response2 = await fetch(
              `${baseUrl}Invoice/AddInvoiceDetail`,
              {
                method: "POST",
                headers: {
                  Authorization: localStorage.getItem("access_token"),
                  Accept: "*/*",
                },
                body: formData2,
              }
            );

            const data2 = await response2.json();
            if (data2 && data2.result.message == "Session Time Expire.") {
              toast.error("Token expired please login again");
              setTimeout(() => {
                navigate("/");
              }, 3000);
            }
            if (data2.result.length && data2.result[0].message === "Success") {
              closeButton.click();
              getCaseHistory();
              getInvoice();
              setCaseID("");
              setInvoiceAmount("");
              setInvoiceTotal("");
              setInvoiceTemId("");
              setRemark("");
              setDates(currentDate);
              setDiscount("");
              setBankID("");
              toast.success("Data Added !");
              setFormData1([{ Description: "", Amount: "" }]);
              setSaveButton(true);
              setTaskRelated("Customer");
              setConPersonName("");
              setConPersonContact("");
              setContactPersonName("");
              setContactPersonContact("");
            } else {
              toast.error(data2.result.message);
              setSaveButton(true);
            }
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const editHandleDescriptionChange = (index, value) => {
    const updatedFormData = [...dynamicRows];
    updatedFormData[index].Description = value;
    setDynamicRows(updatedFormData);
  };

  const editAddRow = () => {
    setDynamicRows([...dynamicRows, { Description: "", Amount: "" }]);
  };

  const editHandleAmountChange = (index, value) => {
    const updatedFormData = [...dynamicRows];
    updatedFormData[index].Amount = value;

    let esti =
      updatedFormData &&
      updatedFormData.reduce(function (prev, current) {
        return prev + +current.Amount;
      }, 0);

    setEditInvoiceTotal(esti);
    setEditInvoiceAmount(esti - EditDiscount);
    setDynamicRows(updatedFormData);
  };

  const editHandelDiscount = () => {
    let sum =
      dynamicRows &&
      dynamicRows.reduce(function (prev, current) {
        return prev + +current.Amount;
      }, 0);

    setEditInvoiceAmount(sum - EditDiscount);
  };

  useEffect(() => {
    editHandelDiscount();
  }, [EditDiscount]);

  useEffect(() => {
    handelDiscount();
  }, [Discount]);

  // ------------------Receipt--------------------------------

  const closeDeleteR = document.querySelector(".dRcp");
  const closeButtonR = document.querySelector(".Rcp");
  const closeEditR = document.querySelector(".RcpE");
  const [CaseID, setCaseID] = useState("");
  // const [Dates, setDates] = useState(currentDate);
  const [TransactionType, setTransactionType] = useState("");
  const [Details, setDetails] = useState("");
  const [TDSAmount, setTDSAmount] = useState("0");
  const [PaidAmount, setPaidAmount] = useState("0");
  const [totalAmount, setTotalAmount] = useState("0");
  const [InvoiceID, setInvoiceID] = useState("");

  //        --------  EDIT  VARIABLE ---------

  const [EditTransactionType, setEditTransactionType] = useState("");
  const [EditDetails, setEditDetails] = useState("");
  const [eCaseID, setECaseID] = useState("");
  const [EditTDSAmount, setEditTDSAmount] = useState("");
  const [EditPaidAmount, setEditPaidAmount] = useState("");
  const [storePaidAmount, setStorePaidAmount] = useState("");
  const [EditInvoiceId, setEditInvoiceId] = useState("");
  const [editTotalAmount, setEditTotalAmount] = useState("");

  //        --------  View  VARIABLE ---------

  const [dCaseID, setDCaseID] = useState("");
  // const [dDate, setDDate] = useState("");
  const [dTDSAmount, setDTDSAmount] = useState("");
  const [dPaidAmount, setDPaidAmount] = useState("");
  const [dDetails, setDDetails] = useState("");
  const [dTransactionType, setDTransactionType] = useState("");
  const [dReceiptNo, setDReceiptNo] = useState("");

  const [Invoice1, setInvoice1] = useState([]);
  const [InvoiceSA, setInvoiceSA] = useState([]);
  const formData = new FormData();
  const formEditData = new FormData();

  //        -------- DELETE TASK API ---------

  const deleteCaseLead = async () => {
    const data = await deleteUserWithUid(delId, "Receipt/DeleteReceipt");
    if (data === "token") {
      setTimeout(() => {
        closeDeleteR.click();
        navigate("/");
      }, 1000);
    }
    if (data === true) {
      closeDeleteR.click();
      getCaseHistory();
    }
  };

  //   Invoice

  const formInvoice = new FormData();

  const getInvoice = async () => {
    formInvoice.append("AdminID", localStorage.getItem("id"));
    formInvoice.append("CaseID", id);
    try {
      const response = await fetch(`${baseUrl}Receipt/ViewnvoiceById`, {
        method: "POST",
        body: formInvoice,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].message === "Success") {
        setInvoice1(data.result);
      } else {
        setInvoice1([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() =>{
  //   getInvoiceSA(id);
  //   getInvoice(id);
  // }, [id])
  useEffect(() => {
    getInvoice(id);
    counselApi(id);
  }, []);
  //        -------- TASK DETAILS DATA ---------

  const detailsRcp = async (id) => {
    const data = await userDetails(id, "Receipt/ViewReceiptById");
    if (data === "token") {
      setTimeout(() => {
        // closeView.click();
        navigate("/");
      }, 1000);
    } else {
      setDCaseID(data && data.result[0].CaseName);
      setDTDSAmount(data && data.result[0].TDSAmount);
      setDDate(data && data.result[0].Date);
      setDPaidAmount(data && data.result[0].PaidAmount);
      setDTransactionType(data && data.result[0].TransactionType);
      setDDetails(data && data.result[0].Details);
      setDAddedBy(data && data.result[0].AddedBy);
      setDCaseYear(data && data.result[0].CaseYear);
      setDInvoiceNo(data && data.result[0].InvoiceNo);
      setDReceiptNo(data && data.result[0].ReceiptNo);
      setDTotalAmount(data && data.result[0].TotalAmount);
    }
  };

  //        -------- Viwe by ID DATA ---------

  const getByIdRcp = async (id) => {
    const data = await userDetails(id, "Receipt/ViewReceiptById");
    if (data === "token") {
      setTimeout(() => {
        closeEdit.click();
        navigate("/");
      }, 1000);
    } else {
      setEditID(data && data.result[0].Id);
      setECaseID(data && data.result[0].CaseID);
      setEditDate(data && data.result[0].Date);
      setEditTDSAmount(Math.floor(data && data.result[0].TDSAmount));
      setEditPaidAmount(Math.floor(data && data.result[0].PaidAmount));
      setStorePaidAmount(Math.floor(data && data.result[0].PaidAmount));
      setEditTransactionType(data && data.result[0].TransactionType);
      setEditDetails(data && data.result[0].Details);
      setEditInvoiceId(data && data.result[0].InvoiceID);
      setEditTotalAmount(Math.floor(data && data.result[0].TotalAmount));
      getInvoice(data && data.result[0].CaseID);
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  useEffect(() => {
    if (EditCaseIDINv === "") {
      setInvoice1([]);
      setECaseID("");
    } else {
      setECaseID("");
      setEditInvoiceId("");
      getInvoice(EditCaseIDINv);
    }
  }, [EditCaseIDINv]);

  const detailsForm1 = new FormData();

  const counselApi = async (id) => {
    detailsForm1.append("AdminID", `${localStorage.getItem("id")}`);
    detailsForm1.append("ID", id);
    try {
      const response = await fetch(`${baseUrl}Cases/ViewCaseByIdOLD`, {
        method: "POST",
        body: detailsForm1,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message === "Session Time Expire.") {
        toast.error("Token expired please login again");
        return "token";
      }

      if (data && data.result.Case[0].message === "Success") {
        setCounselData(data && data.result.CaseDetail);
      } else {
        toast.error(data && data.result.Case[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //        -------- SUBMIT DATA ---------

  const submitHandler = async (event) => {
    event.preventDefault();

    const amount = Invoice1 && Invoice1.filter((val) => val.Id === InvoiceID);

    const invoiceAmount =
      parseInt(amount && amount[0]?.InvoiceAmount) -
      parseInt(amount && amount[0]?.PaidAmount);

    if (totalAmount <= invoiceAmount) {
      setSaveButton(false);
      formData.append("AdminID", localStorage.getItem("id"));
      formData.append("CaseID", id);
      formData.append("Date", Dates);
      formData.append("TransactionType", TransactionType);
      formData.append("TDSAmount", TDSAmount);
      formData.append("PaidAmount", PaidAmount);
      formData.append("InvoiceID", InvoiceID);
      formData.append("TotalAmount", totalAmount);
      formData.append("Details", Details.toUpperCase());
      formData.append("CreatedBy", localStorage.getItem("UserId"));

      try {
        const response = await fetch(`${baseUrl}Receipt/AddReceipt`, {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        });

        const data = await response.json();

        if (data && data.result.message == "Session Time Expire.") {
          toast.error("Token expired please login again");
          setTimeout(() => {
            closeButtonR.click();
            navigate("/");
          }, 1000);
        }

        if (data.result.length && data.result[0].message === "Success") {
          toast.success("Receipt added successfully");
          Rcp_add.click();
          getInvoiceSA();
          handleClose();
          getCaseHistory();
          getInvoice();
          setCaseID("");
          setDates(currentDate);
          setTransactionType("");
          setDetails("");
          setTDSAmount("0");
          setTotalAmount("0");
          setPaidAmount("0");
          setInvoiceID("");
          setInvoice1([]);
          setSaveButton(true);
        } else {
          toast.error(data.result.message);
          setSaveButton(true);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.error(`Total Invoice Amount Is ${invoiceAmount}`);
    }
  };

  //        -------- EDIT TASK API ---------

  const submitEditHandler = async (event) => {
    event.preventDefault();

    const amount =
      Invoice1 && Invoice1.filter((val) => val.Id === EditInvoiceId);
    const invoiceAmount =
      parseInt(amount && amount[0]?.InvoiceAmount) -
      parseInt(amount && amount[0]?.PaidAmount);

    if (editTotalAmount <= (invoiceAmount + storePaidAmount)) {
      formEditData.append("ID", EditID);
      formEditData.append("AdminID", localStorage.getItem("id"));
      formEditData.append("UpdatedBy", localStorage.getItem("UserId"));
      formEditData.append("CaseID", eCaseID);
      formEditData.append("Date", EditDate);
      formEditData.append("TransactionType", EditTransactionType);
      formEditData.append("TDSAmount", EditTDSAmount);
      formEditData.append("PaidAmount", EditPaidAmount);
      formEditData.append("Details", EditDetails.toUpperCase());
      formEditData.append("InvoiceID", EditInvoiceId);
      formEditData.append("TotalAmount", editTotalAmount);
      formEditData.append("oldPaidAmount", storePaidAmount);

      try {
        const response = await fetch(`${baseUrl}Receipt/EditReceipt`, {
          method: "POST",
          body: formEditData,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        });
        const data = await response.json();
        if (data && data.result.message == "Session Time Expire.") {
          toast.error("Token expired please login again");
          setTimeout(() => {
            closeEditR.click();
            navigate("/");
          }, 2000);
        }

        if (data.result.length && data.result[0].code === "200") {
          toast.success(" Updated successfully");
          closeEditR.click();
          getCaseHistory();
        } else {
          toast.error(data.result[0].message);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.error(`Total Invoice Amount Is ${invoiceAmount + storePaidAmount}`);
    }
  };

  let totalCashPayment = 0;

  const totalCash =
    data && data.filter((val) => val.TransactionType === "CASH");

  totalCash &&
    totalCash.map((val, index) => {
      totalCashPayment += parseFloat(val.PaidAmount);
    });

  const handleTdsAmount = (e) => {
    if (e.target.value === "") {
      setTDSAmount(0);
      setTotalAmount(parseInt(PaidAmount));
    } else {
      const inputAmount = parseInt(e.target.value);
      const newTotalAmount = parseInt(PaidAmount) + inputAmount;
      setTotalAmount(newTotalAmount);
      setTDSAmount(inputAmount);
    }
  };

  const handleAmount = (e) => {
    if (e.target.value === "") {
      setPaidAmount(0);
      setTotalAmount(parseInt(TDSAmount));
    } else {
      const inputAmount = parseInt(e.target.value);
      const newTotalAmount = parseInt(TDSAmount) + inputAmount;
      setTotalAmount(newTotalAmount);
      setPaidAmount(inputAmount);
    }
  };

  const editHandleTdsAmount = (e) => {
    if (e.target.value === "") {
      setEditTDSAmount(0);
      setEditTotalAmount(parseInt(EditPaidAmount));
    } else {
      const inputAmount = parseInt(e.target.value);
      const newTotalAmount = parseInt(EditPaidAmount) + inputAmount;
      setEditTotalAmount(newTotalAmount);
      setEditTDSAmount(inputAmount);
    }
  };

  const editHandleAmount = (e) => {
    if (e.target.value === "") {
      setEditPaidAmount(0);
      setEditTotalAmount(parseInt(EditTDSAmount));
    } else {
      const inputAmount = parseInt(e.target.value);
      const newTotalAmount = parseInt(EditTDSAmount) + inputAmount;
      setEditTotalAmount(newTotalAmount);
      setEditPaidAmount(inputAmount);
    }
  };
  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      toast.error("Token expired !! Kindly Login Again");
      navigate("/");
    } else {
      getCaseView();
      getFileNo();
    }
  }, []);

  //  -----------------Settle Amount---------------------------
  //        -------- POPUP CLOSE VARIABLE ---------
  const closeDeleteSA = document.querySelector(".dSA");
  const closeButtonSA = document.querySelector(".SA");
  const [FileNo, setFileNo] = useState([]);
  const [estimateAmount, setEstimateAmount] = useState("");

  //        -------- VARIABLE ---------

  const [userData, setUserData] = useState([]);
  const [InvoiceIDSA, setInvoiceIDSA] = useState("");
  const [formData2, setFormData2] = useState([]);

  const [CaseIDSA, setCaseIDSA] = useState("");
  const [DatesSA, setDatesSA] = useState(currentDate);
  const [PaymentMode, setPaymentMode] = useState("");
  const [CounselID, setCounselID] = useState("");
  const [CocounselID, setCocounselID] = useState("");
  const [CounselShare, setCounselShare] = useState("");
  const [CoCounselShare, setCoCounselShare] = useState("");
  const [CounselName, setCounselName] = useState("");
  const [CoCounselName, setCoCounselName] = useState("");
  // const [TotalAmount, setTotalAmount] = useState("0");
  const [remarkSA, setRemarkSA] = useState("");
  const [ReceivedAmount, setReceivedAmount] = useState("0");
  const [receiptID, setReceiptID] = useState("");
  const [coCounselAmount, setCoCounselAmount] = useState("");
  const [counselAmount, setCounselAmount] = useState("");
  const [receiptData, setReceiptData] = useState([]);
  const [counselData, setCounselData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [companyPer, setCompanyPer] = useState("");
  const [remaining, setRemaining] = useState("");

  //        --------  View  VARIABLE ---------

  const [dCaseIDSA, setDCaseIDSA] = useState("");
  const [dDateSA, setDDateSA] = useState("");
  const [dRemarkSA, setDRemarkSA] = useState("");
  const [dAddedBySA, setDAddedBySA] = useState("");
  const [dTotalAmountSA, setDTotalAmountSA] = useState("");
  const [dCaseYearSA, setDCaseYearSA] = useState("");
  const [dCounselName, setDCounselName] = useState([]);
  const [dPaymentMode, setDPaymentMode] = useState("");
  const [dInvoiceNoSA, setDInvoiceNoSA] = useState("");
  const [dReceiptNoSA, setDReceiptNoSA] = useState("");
  const [settleAmountData, setsettleAmountData] = useState([]);
  const [delIdSA, setDelIdSA] = useState("");
  const formDataSA = new FormData();
  const userDetailsForm = new FormData();
  // Excel
  const [excel, setExcel] = useState([]);
  const removekey = () => {
    if (settleAmountData && settleAmountData.length > 0) {
      const excelData =
        settleAmountData &&
        settleAmountData.map((obj) => {
          const { Id, code, message, Status, AdminID, ...rest } = obj;
          return rest;
        });
      setExcel(excelData);
    } else {
      toast.error("Data Not Found");
    }
  };

  // ----------- VIEW Client---------

  const [options, setOptions] = useState([]); // Initialize options state

  const getFileNoSA = async () => {
    ViewData.append("AdminID", localStorage.getItem("id"));
    ViewData.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: ViewData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].message === "Success") {
        const filterData = data.result.filter(
          (item) => item.CoCounselID !== "0" && item.CounselID !== "0"
        );

        const Mapped = filterData.map((item) => ({
          value: item.CaseID,
          label: `${item.FileNo} / ${item.CaseNo} / ${item.CaseName} / ${item.CaseYear}`,
        }));
        setOptions(Mapped);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //        -------- DELETE TASK API ---------

  const deleteSA = async () => {
    const data = await deleteUser(delIdSA, "SettleAmount/DeleteSettleAmount");
    if (data === "token") {
      setTimeout(() => {
        closeDeleteSA.click();
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      closeDeleteSA.click();
      getCaseHistory();
    }
  };

  useEffect(() => {
    if (invoiceIDSettle === "") {
      setInvoiceIDSettle("");
      setReceiptID("");
      setReceiptData([]);
    } else {
      getReceipt(invoiceIDSettle);
    }
  }, [invoiceIDSettle]);

  //        --------  DETAILS DATA ---------

  const detailsSA = async (id) => {
    userDetailsForm.append("AdminID", `${localStorage.getItem("id")}`);
    userDetailsForm.append("ID", id);
    try {
      const response = await fetch(
        `${baseUrl}SettleAmount/ViewSettleAmountById`,
        {
          method: "POST",
          body: userDetailsForm,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        }
      );
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }

      if (data && data.result[0].message == "Success") {
        setDCaseIDSA(data && data.result[0].CaseName);
        setDDateSA(data && data.result[0].Date);
        setDInvoiceNoSA(data && data.result[0].InvoiceNo);
        setDRemarkSA(data && data.result[0].Remark);
        setDReceiptNoSA(data && data.result[0].ReceiptNo);
        setDPaymentMode(data && data.result[0].PaymentMode);
        setDCounselName(data && data.result);
        setDAddedBySA(data && data.result[0].AddedBy);
        setDTotalAmountSA(data && data.result[0].TotalAmount);
        setDCaseYearSA(data && data.result[0].CaseYear);
      } else {
        toast.error(data.result[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //   Invoice

  const formInvoiceSA = new FormData();
  const formReceipt = new FormData();

  const getInvoiceSA = async (ID) => {
    counselApi(ID);
    formInvoiceSA.append("AdminID", localStorage.getItem("id"));
    formInvoiceSA.append("CaseID", id);
    try {
      const response = await fetch(`${baseUrl}SettleAmount/ViewInvoiceByCase`, {
        method: "POST",
        body: formInvoiceSA,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }

      if (data && data.result[0].message === "Success") {
        setInvoiceSA(data.result);
        setCounselID(data && data.result[0].CounselID);
        setCocounselID(data && data.result[0].CoCounselID);
        setCounselShare(Math.floor(data && data.result[0].CounselShare));
        setCoCounselShare(data && data.result[0].CoCounselShare);
      } else {
        setInvoiceSA([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getReceipt = async (ID) => {
    formReceipt.append("AdminID", localStorage.getItem("id"));
    formReceipt.append("CaseID", id);
    formReceipt.append("InvoiceID", ID);

    try {
      const response = await fetch(
        `${baseUrl}SettleAmount/ViewReceipyByInvoice`,
        {
          method: "POST",
          body: formReceipt,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        }
      );
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].message === "Success") {
        setReceiptData(data.result);
      } else {
        setReceiptData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const receiptApi = async (id) => {
    const data = await userDetails(id, "Receipt/ViewReceiptById");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } else {
      setCounselName(
        data && data.result[0].CounselName === null
          ? "-"
          : data.result[0].CounselName
      );
      setCoCounselName(
        data && data.result[0].CoCounselName === null
          ? "-"
          : data.result[0].CoCounselName
      );

      setReceivedAmount(Math.floor(data && data.result[0].PaidAmount));
      const paid = Math.floor(data && data.result[0].PaidAmount);

      const percentage = 100 - companyPer;
      setRemaining((percentage / 100) * paid);
    }
  };

  useEffect(() => {
    if (invoiceIDSettle === null || invoiceIDSettle === "") {
      setReceiptData([]);
      setReceivedAmount("0");
      setCounselName("");
      setCoCounselName("");
      setCounselAmount("");
      setCoCounselAmount("");
    } else {
      getReceipt(invoiceIDSettle);
    }
  }, [InvoiceID]);

  useEffect(() => {
    getInvoiceSA(id);
    getReceipt(invoiceIDSettle);
    // receiptApi();
  }, []);

  //        -------- SUBMIT DATA ---------

  const dataSave = async () => {
    const amountData =
      updateData &&
      updateData.map((coCounsel) =>
        coCounsel.amount === "" || coCounsel.amount === "0"
          ? "0"
          : coCounsel.amount
      );

    const counselId =
      updateData && updateData.map((coCounsel) => coCounsel.CounselID);

    formDataSA.append("AdminID", localStorage.getItem("id"));
    formDataSA.append("CaseID", id);
    formDataSA.append("CounselID", counselId);
    formDataSA.append("CounselAmount", amountData);
    formDataSA.append("Date", DatesSA);
    formDataSA.append("InvoiceID", invoiceIDSettle);
    formDataSA.append("TotalAmount", ReceivedAmount);
    formDataSA.append("ReceiptID", receiptID);
    formDataSA.append("Remark", remarkSA.toUpperCase());
    formDataSA.append("PaymentMode", PaymentMode);
    formDataSA.append("CreatedBy", localStorage.getItem("UserId"));

    try {
      const response = await fetch(`${baseUrl}SettleAmount/AddSettleAmount`, {
        method: "POST",
        body: formDataSA,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButtonSA.click();
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].code === "200") {
        toast.success("SettleAmount added successfully");
        handleClose();
        getFileNo();
        getCaseHistory();
        setInvoiceIDSettle("");
        setCaseIDSA("");
        setDatesSA(currentDate);
        setPaymentMode("");
        setCounselAmount("");
        setCoCounselAmount("");
        setCounselName("");
        setCoCounselName("");
        setInvoice1([]);
        setReceiptData([]);
        setCounselID("");
        setCocounselID("");
        setRemarkSA("");
        setReceivedAmount("0");
        setReceiptID("");
        setInvoiceID("");
        setCounselShare("");
        setCoCounselAmount("");
        closeButtonSA.click();
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const submitHandlerSA = (event) => {
    event.preventDefault();

    Swal({
      title: "Warning Message !",
      text: "Once you add data, you cannot edit it.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dataSave();
      } else {
        Swal("Settle Amount Is Not Add !");
      }
    });
  };

  const handleCounselAmount = (index, value) => {
    const updatedFormData = [...updateData];

    updatedFormData[index].amount = value;

    let sum = updatedFormData.reduce(function (prev, current) {
      return prev + +current.amount;
    }, 0);

    if (sum <= remaining) {
      setUpdateData(updatedFormData);
    } else {
      alert(
        `Company Percentage Is ${companyPer} And Remaining Amount Is ${remaining}`
      );
    }
  };

  const handleCoCounselAmount = (e) => {
    const value = e.target.value;
    if (value === "") {
      setCoCounselAmount("");
    } else {
      if (ReceivedAmount !== "0") {
        if (ReceivedAmount >= parseInt(value)) {
          setCounselAmount(parseInt(ReceivedAmount) - parseInt(value));
          setCoCounselAmount(value);
        } else {
          alert("Received Amount Is Less");
        }
      }
    }
  };

  useEffect(() => {
    if (receiptID === null || receiptID === "") {
      setReceivedAmount("0");
      setCounselName("");
      setCoCounselName("");
      setCounselAmount("");
      setCoCounselAmount("");
    } else {
      receiptApi(receiptID);
    }
  }, [receiptID]);

  useEffect(() => {
    const data =
      updateData &&
      updateData.map((val, index) => {
        return { ...val, amount: (val.CounselPer / 100) * ReceivedAmount };
      });
    setUpdateData(data);
  }, [ReceivedAmount]);

  useEffect(() => {
    const data =
      counselData &&
      counselData.map((val, index) => {
        return { ...val, amount: (val.CounselPer / 100) * ReceivedAmount };
      });

    setUpdateData(data);
  }, [counselData]);

  const getcaseEditById = async (id) => {
    detailsForm.append("AdminID", `${localStorage.getItem("id")}`);
    detailsForm.append("ID", id);
    try {
      const response = await fetch(`${baseUrl}Cases/ViewCaseByIdOLD`, {
        method: "POST",
        body: detailsForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message === "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }

      if (data && data.result.Case[0].message === "Success") {
        const updatedItems =
          data &&
          data.result.CaseDetail.filter((item) => item.CounselType === "2");

        const rowsData = [];

        if (updatedItems && updatedItems.length) {
          updatedItems &&
            updatedItems.forEach((item) => {
              rowsData.push({
                coCounselName: item.CounselID,
                coCounselShare: item.CounselPer,
                coCounselId: item.Id,
              });
            });

          setCocounselRow(rowsData);
        } else {
          setCocounselRow([{ coCounselName: "", coCounselShare: "" }]);
        }

        setCEditId(data && data.result.Case[0].Id);
        setVillageID(data && data.result.Case[0].VillageID);
        setCourt(
          data && data.result.Case[0].CourtID === "0"
            ? ""
            : data.result.Case[0].CourtID
        );
        setStateID(
          data && data.result.Case[0].StateID === "0"
            ? ""
            : data.result.Case[0].StateID
        );
        setCityID(
          data.result.Case[0].DistrictID === "0"
            ? ""
            : data.result.Case[0].DistrictID
        );
        setCityData(
          data && data.result.Case[0].CityID === "0"
            ? ""
            : data.result.Case[0].CityID
        );
        setType(
          data && data.result.Case[0].CaseTypeID === "0"
            ? ""
            : data.result.Case[0].CaseTypeID
        );

        setEditNewOld(data && data.result.Case[0].CaseStage);
        setEditDiaryNo(data && data.result.Case[0].DiaryNo);
        setEditDiaryYear(data && data.result.Case[0].DiaryYear);
        setEditCaseNo(data && data.result.Case[0].CaseNo);
        setEditCaseYear(data && data.result.Case[0].CaseYear);
        setCEditClient(data && data.result.Case[0].ClientID);
        const ab = data && data.result.Case[0].CaseName;
        const a = ab.split("VS");
        setTitle1(a[0]);
        setTitle2(a[1]);
        setCEditSummary(data && data.result.Case[0].CaseSummary);
        setEditBranchCity(data && data.result.Case[0].BranchCity);
        setEditPatitioners(data && data.result.Case[0].Petitioners);
        setEditIntervenors(data && data.result.Case[0].Intervenors);
        setEditFixedFees(data && data.result.Case[0].FixedFees);
        setEditAppearanceFee(data && data.result.Case[0].AppearanceFee);
        setEditAor(data && data.result.Case[0].AOR);
        setEditRespondants(data && data.result.Case[0].Respondants);
        setEditRetaineed(data && data.result.Case[0].RetainedFor);
        setEditExpenses(data && data.result.Case[0].Expenses);
        setEditNonAppearanceFee(data && data.result.Case[0].NonAppearanceFee);
        setEditFilingdate(data && data.result.Case[0].FilingDate);
        setEditTitle(data && data.result.Case[0].CaseName);
        setIsOpen(data && data.result.Case[0].IsOpen);
        setClosingDate(
          (data && data.result.Case[0].ClosingDate === null) ||
            data.result.Case[0].ClosingDate === "0000-00-00"
            ? ""
            : data.result.Case[0].ClosingDate
        );
        setEditCounsel(data && data.result.CaseDetail[0].CounselID);
        setEditCounselShare(data && data.result.CaseDetail[0].CounselPer);
      } else {
        toast.error(data && data.result.Case[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };




  const handleCustomerRelated = (event) => {
    setTaskRelated(event.target.value);
    if (event.target.value === "Other") {
      setCustomerDropDownShow(false);
      setContactPersonName("");
      setContactPersonContact("");
    } else {
      setContactPersonName(conPersonName);
      setContactPersonContact(conPersonContact);
      setCustomerDropDownShow(true);
    }
  };



  
  const handleCourtChange = (selectedValue, name) => {
    setCourt(selectedValue);
    setType("");
    if (name === "Supreme Court") {
      setShowInputField(true);
    } else {
      setEditDiaryYear("");
      setEditDiaryNo("");
      setShowInputField(false);
    }
  };

  const handleCounselShareChange1 = (e) => {
    const value = parseInt(e.target.value);
    if (isNaN(value) || value < 0 || value > 50) {
      setIsValid1(false);
      return;
    }
    setEditCounselShare(value);
    // setIsValid1(true)
    setEditCoCounselShare(50 - parseInt(value));
  };

  const handleEditTitle1 = (e) => {
    setTitle1(e.target.value);
    setEditTitle(e.target.value + " " + "VS" + " " + title2);
  };

  const handleEditTitle2 = (e) => {
    setTitle2(e.target.value);
    setEditTitle(title1 + " " + "VS" + " " + e.target.value);
  };

  const editCaseAddRow = () => {
    const total = 100 - compantPrecentage;
    if (cocounselRow.length < total) {
      setCocounselRow([
        ...cocounselRow,
        { coCounselName: "", coCounselShare: "" },
      ]);
    } else {
      alert(`Total Percentage is ${total} %`);
    }
  };

  const editHandleCoCounselShare = (index, value) => {
    const updatedFormData = [...cocounselRow];
    if (updatedFormData[index].coCounselName === "") {
      toast.error("Please Select CoCounsel");
    } else {
      const total = 100 - compantPrecentage;
      const updatedFormData = [...cocounselRow];
      const oldValue = +updatedFormData[index].coCounselShare;
      const total1 = total - editcounselShare;

      if (value <= total && value <= total1) {
        updatedFormData[index].coCounselShare = value;

        let sum = updatedFormData.reduce(function (prev, current) {
          return prev + +current.coCounselShare;
        }, 0);

        if (sum <= total && sum <= total1) {
          setCocounselRow(updatedFormData);
        } else {
          updatedFormData[index].coCounselShare = oldValue;
          alert(`Total Percentage is ${total} %`);
        }
      } else {
        alert(`Total Percentage is ${total} %`);
      }
    }
  };

  const editHandlecoCounsel = (index, value) => {
    const updatedFormData = [...cocounselRow];
    updatedFormData[index].coCounselName = value;
    setCocounselRow(updatedFormData);
  };

  useEffect(() => {
    if (editcounsel === "") {
      setEditCounsel("");
      setViewUserCoCounselData([]);
    } else {
      const data =
        viewUserData && viewUserData.filter((item) => item.Id !== editcounsel);
      setViewUserCoCounselData(data);
    }
  }, [editcounsel]);

  useEffect(() => {
    setIsOpen(isOpen);
    if (isOpen === "0") {
      setShowClosingDate(true);
      setInputWidth(12);
    } else {
      setShowClosingDate(false);
      setInputWidth(6);
      setClosingDate("");
    }
  }, [isOpen]);

  const getCaseType = async () => {
    const data = await getcourtView("Master/ViewCaseTypeByCourtId", court);
    setCaseType(data);
  };

  // =-----------------------Events------------------------------------

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <DocumentTitle title="DETAILS | LEGAL CRM">
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  <div className="pageContent">
                    <div className="PageTitle">
                      <h3>Case Details</h3>
                      <div>
                        <a
                          type="button"
                          className="btn btn-primary btn_client"
                          data-toggle="modal"
                          data-target="#exampleModalLongEditCase"
                          onClick={() => getcaseEditById(id)}
                        >
                          Case Info.
                        </a>
                        &nbsp;
                        <a
                          type="button"
                          className="btn btn-primary btn_client"
                          data-toggle="modal"
                          data-target="#exampleModalLongClientEdit"
                          onClick={() => updateUser(clientIdDetail)}
                        >
                          Client Info.
                        </a>
                      </div>
                    </div>
                    <br />
                    {/* <div className="caseMobile"> */}
                    <div className="caseDetail">
                      <div className="form-group Details high_light">
                        <h4>Case Name : </h4>
                        <p>
                          {caseData.CaseName === "" ? "-" : caseData.CaseName}
                        </p>
                      </div>
                      <div className="form-group Details high_light">
                        <h4>Case No. : </h4>
                        <p >
                          {caseData.CaseNo === "" || caseData.CaseNo === null
                            ? "-"
                            : caseData.CaseNo}
                        </p>
                      </div>

                      <div className="form-group Details high_light">
                        <h4>Case Year : </h4>
                        <p>
                          {caseData.CaseYear === "" ||
                          caseData.CaseYear === null
                            ? "-"
                            : caseData.CaseYear}
                        </p>
                      </div>

                      <div className="form-group Details high_light">
                        <h4>Court Name : </h4>
                        <p>
                          {caseData.CourtName === "" ||
                          caseData.CourtName === null
                            ? "-"
                            : caseData.CourtName}
                        </p>
                      </div>
                      <div className="form-group Details high_light">
                        <h4>Case Type :</h4>
                        <p>
                          {caseData.CaseType === "" ||
                          caseData.CaseType === null
                            ? "-"
                            : caseData.CaseType}
                        </p>
                      </div>
                      <div className="form-group Details">
                        <h4>File No. : </h4>
                        <p>
                          {caseData.FileNo === "" || caseData.FileNo === null
                            ? "-"
                            : caseData.FileNo}
                        </p>
                      </div>

                      <div className="form-group Details">
                        <h4>Diary No. : </h4>
                        <p>
                          {caseData.DiaryNo === "" || caseData.DiaryNo === null
                            ? "-"
                            : caseData.DiaryNo}
                        </p>
                      </div>
                      <div className="form-group Details">
                        <h4>Diary Year: </h4>
                        <p>
                          {caseData.DiaryYear === "" ||
                          caseData.DiaryYear === null
                            ? "-"
                            : caseData.DiaryYear}
                        </p>
                      </div>

                      <div className="form-group Details">
                        <h4>Client Name : </h4>
                        <p>
                          {caseData.ClientName === "" ||
                          caseData.ClientName === null
                            ? "-"
                            : caseData.ClientName}
                        </p>
                      </div>
                      <div className="form-group Details">
                        <h4>Client Last Name : </h4>
                        <p>
                          {caseData.ClientLastName === "" ||
                          caseData.ClientLastName === null
                            ? "-"
                            : caseData.ClientLastName}
                        </p>
                      </div>
                      <div className="form-group Details">
                        <h4>Company Name : </h4>
                        <p>
                          {caseData.CompanyName === "" ||
                          caseData.CompanyName === null
                            ? "-"
                            : caseData.CompanyName}
                        </p>
                      </div>

                      <div className="form-group Details high_light">
                        <h4>Client Mobile No. : </h4>

                        <p>
                          {caseData.MobileNo === "" ||
                          caseData.MobileNo === null
                            ? "-"
                            : caseData.MobileNo}
                        </p>
                      </div>
                      <div className="form-group Details">
                        <h4>Phone : </h4>
                        <p>
                          {caseData.Phone === "" || caseData.Phone === null
                            ? "-"
                            : caseData.Phone}
                        </p>
                      </div>
                      <div className="form-group Details high_light">
                        <h4>Email :</h4>
                        <p>
                          {caseData.Email === "" || caseData.Email === null
                            ? "-"
                            : caseData.Email}
                        </p>
                      </div>

                      <div className="form-group Details">
                        <h4>State : </h4>
                        <p>
                          {caseData.state_title === null
                            ? "-"
                            : caseData.state_title}
                        </p>
                      </div>
                      <div className="form-group Details">
                        <h4>District : </h4>
                        <p>
                          {caseData.district_title === null
                            ? "-"
                            : caseData.district_title}
                        </p>
                      </div>
                      <div className="form-group Details">
                        <h4>Tehsil : </h4>
                        <p>
                          {caseData.city_title === null
                            ? "-"
                            : caseData.city_title}
                        </p>
                      </div>
                      <div className="form-group Details">
                        <h4>Branch City : </h4>
                        <p>
                          {caseData.BranchCity === "" ||
                          caseData.BranchCity === null
                            ? "-"
                            : caseData.BranchCity}
                        </p>
                      </div>

                      <div className="form-group Details high_light">
                        <h4>Counsel : </h4>
                        <p className="upperCase">
                          {caseData.Counsel === "" || caseData.Counsel === null
                            ? "-"
                            : caseData.Counsel}
                        </p>
                      </div>
                      <div className="form-group Details high_light">
                        <h4>Co-Counsel :</h4>
                        <p className="upperCase">
                          {caseData.Cocounsel === "" ||
                          caseData.Cocounsel === null
                            ? "-" : caseData.Cocounsel}

                  
                        </p>
                      </div>
                      <div className="form-group Details">
                        <h4>Added By : </h4>
                        <p className="upperCase">
                          {caseData.AddedBy === "" || caseData.AddedBy === null
                            ? "-"
                            : caseData.AddedBy}
                          <br />
                          {formateDateTime(caseData.CreatedDate)}
                        </p>
                      </div>
                      <div className="form-group Details">
                        <h4>Filing Date : </h4>
                        <p>
                          {caseData.FilingDate === "" ||
                          caseData.FilingDate === "0000-00-00" ||
                          caseData.FilingDate === null
                            ? "-"
                            : new Date(caseData.FilingDate).toLocaleDateString(
                                "en-GB"
                              )}
                        </p>
                      </div>
                      <div className="form-group Details">
                        <h4>Address :</h4>
                        <p>
                          {caseData.Address === "" || caseData.Address === null
                            ? "-"
                            : caseData.Address}
                        </p>
                      </div>

                      <div className="form-group Details">
                        <h4>AOR : </h4>
                        <p>
                          {caseData.AOR === "" || caseData.AOR === null
                            ? "-"
                            : caseData.AOR}
                        </p>
                      </div>

                      <div className="form-group Details">
                        <h4>Retained For :</h4>
                        <p className="upperCase">
                          {caseData.RetainedFor === "" ||
                          caseData.RetainedFor === null
                            ? "-"
                            : caseData.RetainedFor}
                        </p>
                      </div>
                      <div className="form-group Details">
                        <h4>Petitioners :</h4>
                        <p>
                          {caseData.Petitioners === "" ||
                          caseData.Petitioners === null
                            ? "-"
                            : caseData.Petitioners}
                        </p>
                      </div>

                      <div className="form-group Details">
                        <h4>Respondants : </h4>
                        <p>
                          {caseData.Respondants === ""
                            ? "-"
                            : caseData.Respondants}
                        </p>
                      </div>
                      <div className="form-group Details">
                        <h4>Fixed Fees : </h4>
                        <p>
                          {caseData.FixedFees === "" ? "-" : caseData.FixedFees}
                        </p>
                      </div>
                      <div className="form-group Details">
                        <h4>Appearance Fee : </h4>
                        <p>
                          {caseData.AppearanceFee === "" ||
                          caseData.AppearanceFee === null
                            ? "-"
                            : caseData.AppearanceFee}
                        </p>
                      </div>

                      <div className="form-group Details">
                        <h4>Expenses : </h4>
                        <p>
                          {caseData.Expenses === "" ||
                          caseData.Expenses === null
                            ? "-"
                            : caseData.Expenses === "0"
                            ? "EXCLUSIVE"
                            : "INCLUSIVE"}
                        </p>
                      </div>
                      <div className="form-group Details">
                        <h4>Non Appearance Fee : </h4>
                        <p>
                          {caseData.NonAppearanceFee === ""
                            ? "-"
                            : caseData.NonAppearanceFee}
                        </p>
                      </div>
                      <div className="form-group Details">
                        <h4>Intervenors :</h4>
                        <p>
                          {caseData.Intervenors === "" ||
                          caseData.Intervenors === null
                            ? "-"
                            : caseData.Intervenors}
                        </p>
                      </div>

                      <div className="form-group Details">
                        <h4>Is Open : </h4>
                        <p> {caseData.IsOpen}</p>
                      </div>

                      <div className="form-group Details">
                        <h4>Closing Date : </h4>
                        <p>
                          {caseData.ClosingDate === "0000-00-00" ||
                          caseData.UpdatedDate === "" ||
                          caseData.ClosingDate === null
                            ? "-"
                            : new Date(caseData.ClosingDate).toLocaleDateString(
                                "en-GB"
                              )}
                        </p>
                      </div>
                      <div className="form-group Details">
                        <h4>Last Updated Date</h4>
                        <p>
                          {caseData.UpdatedDate === "" ||
                          caseData.UpdatedDate === "0000-00-00" ||
                          caseData.UpdatedDate === null
                            ? "-"
                            : new Date(caseData.UpdatedDate).toLocaleDateString(
                                "en-GB"
                              )}
                        </p>
                      </div>
                      <div className="form-group detailComments">
                        <h4>Comments : </h4>
                        <p>
                          {caseData.Comments === "" ||
                          caseData.Comments === null
                            ? "-"
                            : caseData.Comments}
                        </p>
                      </div>
                      <div className="form-group detailComments">
                        <h4>Case Summary :</h4>
                        <p>
                          {caseData.CaseSummary === ""
                            ? "-"
                            : caseData.CaseSummary}
                        </p>
                      </div>
                      <br />
                    </div>

                    {/* </div> */}
                    <div
                    // style={{
                    //   display: isView === "0" ? "none" : "block",
                    // }}
                    >
                      {/* Invoice Start */}
                      <>
                        <div className="report_crmgraph">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="myCard">
                                <div className="cardHeader">
                                  <h4>Invoice</h4>
                                  <span>
                                    <a
                                      style={{
                                        color: "white",
                                      }}
                                      type="button"
                                      // className="btn"
                                      data-toggle="modal"
                                      data-target="#exampleModalLongINVAdd"
                                    >
                                      Add Invoice
                                      <img src="assets/img/icons/add.svg" />
                                    </a>
                                  </span>
                                  <a
                                    href="/#/view-invoice"
                                    className="rytContent"
                                  >
                                    <img
                                      src="assets/img/icons/dots.png"
                                      alt=""
                                      title="All Invoice"
                                    />
                                  </a>
                                </div>
                                {optionsCD.Invoice &&
                                optionsCD.Invoice[0].message === "Success" ? (
                                  <div className="cardBody">
                                    <table className="myTable">
                                      <thead
                                        className="top_table"
                                        style={{
                                          backgroundColor: "#1C315E",
                                        }}
                                      >
                                        <th>Action</th>
                                        <th>Date</th>
                                        <th>Invoice No</th>
                                        <th>Invoice Amount </th>
                                        <th>Amount</th>
                                        <th>Balance</th>
                                      </thead>

                                      {optionsCD.Invoice &&
                                      optionsCD.Invoice.length > 0 ? (
                                        optionsCD.Invoice.map((val, index) => (
                                          <tr
                                            key={index}
                                            className={
                                              index % 2 === 0
                                                ? "row-even"
                                                : "row-odd"
                                            }
                                          >
                                            <td>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  gap: "15px",
                                                }}
                                              >
                                                <span>
                                                  <a
                                                    type="button"
                                                    data-toggle="modal"
                                                    data-target="#exampleModalLongINVEdit"
                                                    onClick={() =>
                                                      getById(val.Id)
                                                    }
                                                  >
                                                    <img
                                                      src="assets/img/icons/edit.svg"
                                                      title="Edit"
                                                    />
                                                  </a>
                                                </span>
                                                <span>
                                                  <a
                                                    type="button"
                                                    data-toggle="modal"
                                                    data-target="#exampleModalLongINVDetail"
                                                    onClick={() =>
                                                      details(val.Id)
                                                    }
                                                  >
                                                    <img
                                                      src="assets/img/icons/eye.svg"
                                                      title="Details"
                                                    />
                                                  </a>
                                                </span>
                                                <span>
                                                  <Link
                                                    target="_blank"
                                                    to={`/View_Invoice_PDF/${val.Id}`}
                                                    title="PDF"
                                                  >
                                                    <img
                                                      src="assets/img/icons/pdf.svg"
                                                      className="pdf"
                                                      title="PDF"
                                                    />
                                                  </Link>
                                                </span>
                                                <span>
                                                  <a
                                                    type="button"
                                                    onClick={() =>
                                                      deActive(
                                                        val,
                                                        "Invoice/InvoiceStatus"
                                                      )
                                                    }
                                                  >
                                                    {val.Status === "1" ? (
                                                      <img
                                                        className="thumb_icon"
                                                        src="assets/img/icons/Up.svg"
                                                        title="Status"
                                                      />
                                                    ) : (
                                                      <img
                                                        className="thumb_icon"
                                                        src="assets/img/icons/Down.svg"
                                                        title="Status"
                                                      />
                                                    )}
                                                  </a>
                                                </span>
                                                <span>
                                                  <Link
                                                    target="_blank"
                                                    to={`/View_Invoice_WP/${val.Id}`}
                                                    title="PDF"
                                                  >
                                                    <img
                                                      src="assets/img/icons/send.svg"
                                                      title="Whatsapp PDF"
                                                    />
                                                  </Link>
                                                </span>
                                                {/* {isDelete !== "0" && ( */}
                                                <span
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#exampleModalINVDelete"
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    setDelId(val.Id)
                                                  }
                                                >
                                                  <img
                                                    src="assets/img/icons/delete.svg"
                                                    alt="Delete"
                                                    title="Delete"
                                                  />
                                                </span>
                                                {/* )} */}
                                              </div>
                                            </td>
                                            <td>{formatDate1(val.Date)}</td>
                                            <td>{val.InvoiceNo}</td>
                                            <td>{val.InvoiceAmount}</td>

                                            {val.PaidAmount ===
                                            val.InvoiceAmount ? (
                                              <td className="paid">
                                                <label className="PA">
                                                  {val.PaidAmount}
                                                </label>
                                                (PAID)
                                              </td>
                                            ) : val.PaidAmount > 0 ? (
                                              <td className="overdue">
                                                <label className="PA">
                                                  {val.PaidAmount}
                                                </label>
                                                (PENDING)
                                              </td>
                                            ) : (
                                              <td className="pending">
                                                <label className="PA">
                                                  {val.PaidAmount}
                                                </label>
                                                (UNPAID)
                                              </td>
                                            )}

                                            <td>
                                              {val.InvoiceAmount -
                                                val.PaidAmount}
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <div></div>
                                      )}
                                    </table>

                                    {/* Delete */}
                                    <div
                                      className="modal fade sc_modal"
                                      id="exampleModalINVDelete"
                                      tabIndex={-1}
                                      aria-labelledby="exampleModalLabel"
                                      aria-hidden="true"
                                    >
                                      <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <button
                                              type="button"
                                              className="btn-close dInvoice"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            />
                                          </div>
                                          <div className="modal-body">
                                            <h4>Delete invoice</h4>
                                            <p>
                                              if you delete the invoice then
                                              receipt related to this invoice
                                              will be deleted
                                            </p>
                                            <ul>
                                              <li>
                                                <a
                                                  className="btn btn-secondary"
                                                  data-bs-dismiss="modal"
                                                >
                                                  Cancel
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="btn btn-danger"
                                                  onClick={() =>
                                                    deleteInvoice(delId)
                                                  }
                                                >
                                                  Delete
                                                </a>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    {/* Edit */}

                                    <div
                                      className="modal fade"
                                      id="exampleModalLongINVEdit"
                                      tabIndex="-1"
                                      role="dialog"
                                      aria-labelledby="exampleModalLongTitle"
                                      aria-hidden="true"
                                    >
                                      <div
                                        className="modal-dialog e_popup modal-xl"
                                        role="document"
                                        // style={{ maxWidth: "70%" }}
                                      >
                                        <div className="modal-content">
                                          <div className="add_wrap">
                                            <div className="main_heading">
                                              <h2>Edit Invoice</h2>
                                            </div>
                                          </div>
                                          <div className="popup_modal ">
                                            <button
                                              type="button"
                                              className="close btn_popup e_popup Inv_popup"
                                              data-dismiss="modal"
                                              aria-label="Close"
                                            >
                                              <span aria-hidden="true">
                                                &times;
                                              </span>
                                            </button>

                                            <div className="dashed_popup">
                                              <form
                                                role="form "
                                                onSubmit={HandleEditSubmit}
                                              >
                                                <div className="form_flex caseMobile">
                                                  <div className="form-group">
                                                    <label htmlFor="username">
                                                      File No.
                                                      <text className="m_star">
                                                        *
                                                      </text>
                                                    </label>
                                                    <select
                                                      disabled
                                                      id="city"
                                                      required
                                                    >
                                                      {optionsCase &&
                                                        optionsCase.map(
                                                          (val, index) => (
                                                            <option
                                                              key={index}
                                                              value={val.value}
                                                              selected={
                                                                val.value ===
                                                                EditCaseIDINv
                                                              }
                                                            >
                                                              {val.label}
                                                            </option>
                                                          )
                                                        )}
                                                    </select>
                                                  </div>
                                                  <div className="form-group">
                                                    <label for="uname">
                                                      Invoice Tempalte
                                                    </label>
                                                    <select
                                                      className="name"
                                                      id="city"
                                                      value={editInvoiceTemId}
                                                      onChange={(e) =>
                                                        setEditInvoiceTemId(
                                                          e.target.value
                                                        )
                                                      }
                                                    >
                                                      <option value="">
                                                        ------ Please Select
                                                        -------
                                                      </option>
                                                      {invoiceTem &&
                                                        invoiceTem.map(
                                                          (val, index) => {
                                                            return (
                                                              <option
                                                                key={index}
                                                                value={val.Id}
                                                                selected={
                                                                  val.Id ===
                                                                  editInvoiceTemId
                                                                }
                                                              >
                                                                {val.Name}
                                                              </option>
                                                            );
                                                          }
                                                        )}
                                                    </select>
                                                  </div>
                                                  <div className="form-group">
                                                    <label for="uname">
                                                      Bank
                                                      <text className="m_star">
                                                        *
                                                      </text>
                                                    </label>
                                                    <select
                                                      value={EditBankID}
                                                      id="city"
                                                      onChange={(e) =>
                                                        setEditBankID(
                                                          e.target.value
                                                        )
                                                      }
                                                      required
                                                    >
                                                      <option value="">
                                                        --Please Select--
                                                      </option>
                                                      {Bank &&
                                                      Bank.length > 0 ? (
                                                        Bank.map((val) => (
                                                          <option
                                                            key={val.Id}
                                                            value={val.value}
                                                            selected={
                                                              val.value ===
                                                              EditBankID
                                                            }
                                                          >
                                                            {val.label}
                                                          </option>
                                                        ))
                                                      ) : (
                                                        <option value="">
                                                          Loading...
                                                        </option>
                                                      )}
                                                    </select>
                                                  </div>
                                                  <div className="form-group">
                                                    <label for="uname">
                                                      Date
                                                      <text className="m_star">
                                                        *
                                                      </text>
                                                    </label>
                                                    <input
                                                      required
                                                      autoComplete="off"
                                                      max={currentDate}
                                                      className="name"
                                                      type="date"
                                                      placeholder="Enter Date "
                                                      onChange={(e) =>
                                                        setEditDate(
                                                          e.target.value
                                                        )
                                                      }
                                                      value={EditDate}
                                                    />
                                                  </div>
                                                </div>

                                                <div className="main_heading">
                                                  <h2>Invoice Details</h2>
                                                </div>

                                                {dynamicRows &&
                                                  dynamicRows.map(
                                                    (item, index) => (
                                                      <div key={index}>
                                                        <div className="form_flex caseMobile">
                                                          <div className="form-group">
                                                            <label
                                                              htmlFor={`description-${index}`}
                                                            >
                                                              Description
                                                            </label>
                                                            <input
                                                              type="text"
                                                              className="name"
                                                              placeholder="Enter Description"
                                                              value={
                                                                item.Description
                                                              }
                                                              onChange={(e) =>
                                                                editHandleDescriptionChange(
                                                                  index,
                                                                  e.target.value
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                          <div className="form-group">
                                                            <label
                                                              htmlFor={`amount-${index}`}
                                                            >
                                                              Amount
                                                              <text className="m_star">
                                                                *
                                                              </text>
                                                            </label>
                                                            <input
                                                              required
                                                              onKeyPress={
                                                                handleKeyPress
                                                              }
                                                              value={
                                                                item.Amount ===
                                                                "0.00"
                                                                  ? ""
                                                                  : item.Amount
                                                              }
                                                              type="text"
                                                              className="name"
                                                              placeholder="Enter Amount"
                                                              onChange={(e) =>
                                                                editHandleAmountChange(
                                                                  index,
                                                                  e.target.value
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                          {index === 0 && (
                                                            <a
                                                              onClick={
                                                                editAddRow
                                                              }
                                                              style={{
                                                                color: "white",
                                                                marginTop:
                                                                  "20px",
                                                                cursor:
                                                                  "pointer",
                                                                margin: "10px",
                                                                padding:
                                                                  "6px 10px",
                                                                background:
                                                                  "green",
                                                              }}
                                                            >
                                                              +
                                                            </a>
                                                          )}

                                                          {index !== 0 && (
                                                            <a
                                                              style={{
                                                                color: "white",
                                                                cursor:
                                                                  "pointer",
                                                                margin: "10px",
                                                                marginTop:
                                                                  "20px",
                                                                padding:
                                                                  "6px 10px",
                                                              }}
                                                              onClick={() =>
                                                                editRremoveRow(
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              ❌
                                                            </a>
                                                          )}
                                                        </div>
                                                      </div>
                                                    )
                                                  )}

                                                <div className="form_flex test">
                                                  <div className="form-group">
                                                    <label htmlFor="remark">
                                                      Remark
                                                    </label>
                                                    <textarea
                                                      type="text"
                                                      rows={10}
                                                      placeholder="Enter Remark"
                                                      name="remark"
                                                      value={editRemark}
                                                      onChange={(e) =>
                                                        setEditRemark(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="form-group">
                                                    <div>
                                                      <label htmlFor="discount">
                                                        Invoice Total
                                                      </label>
                                                      <input
                                                        readOnly
                                                        type="text"
                                                        placeholder=""
                                                        name="discount"
                                                        value={editInvoiceTotal}
                                                      />
                                                    </div>

                                                    <div>
                                                      <label htmlFor="discount">
                                                        Discount ₹
                                                      </label>
                                                      <input
                                                        type="text"
                                                        placeholder="Enter Discount"
                                                        name="discount"
                                                        onChange={(e) =>
                                                          setEditDiscount(
                                                            e.target.value
                                                          )
                                                        }
                                                        value={EditDiscount}
                                                      />
                                                    </div>

                                                    <div>
                                                      <label htmlFor="invoice_amount">
                                                        Final Amount
                                                      </label>
                                                      <input
                                                        readOnly
                                                        type="text"
                                                        placeholder="Enter Invoice Amount"
                                                        name="invoice_amount"
                                                        value={
                                                          EditInvoiceAmount
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="btn_main caseMobile">
                                                  <button
                                                    type="submit"
                                                    className="btn_save"
                                                  >
                                                    Update
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="btn_save btn_cancle"
                                                    data-dismiss="modal"
                                                  >
                                                    Cancel
                                                  </button>
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    {/* DETAILS */}

                                    <div
                                      className="modal fade"
                                      id="exampleModalLongINVDetail"
                                      tabIndex={-1}
                                      role="dialog"
                                      aria-labelledby="exampleModalLongTitle"
                                      aria-hidden="true"
                                    >
                                      <div
                                        className="modal-dialog modal-lg"
                                        role="document"
                                        // style={{ maxWidth: "50%" }}
                                      >
                                        <div className="modal-content">
                                          <div className="add_wrap">
                                            <div className="main_heading">
                                              <h2>Invoice Details</h2>
                                            </div>
                                          </div>
                                          <div className="popup_modal ">
                                            <button
                                              type="button"
                                              className="close ab btn_popup"
                                              data-dismiss="modal"
                                              aria-label="Close"
                                            >
                                              <span aria-hidden="true">×</span>
                                            </button>
                                            <div className="dashed_popup">
                                              <div className="form_flex caseMobile">
                                                <div className="form-group">
                                                  <h4>
                                                    Case Name &nbsp;: &nbsp;
                                                    {dCaseName}
                                                  </h4>
                                                </div>
                                                <div className="form-group">
                                                  <h4>
                                                    Case Year &nbsp;: &nbsp;
                                                    {dCaseYear}
                                                  </h4>
                                                </div>
                                              </div>
                                              <div className="form_flex caseMobile">
                                                <div className="form-group">
                                                  <h4>
                                                    Client Name &nbsp;: &nbsp;
                                                    {dClientName}
                                                  </h4>
                                                </div>
                                                <div className="form-group">
                                                  <h4>
                                                    Date &nbsp;: &nbsp;
                                                    {dDate === "0000-00-00" ||
                                                    dDate === ""
                                                      ? "-"
                                                      : formatDate1(dDate)}
                                                  </h4>
                                                </div>
                                              </div>
                                              <div className="form_flex caseMobile">
                                                <div className="form-group">
                                                  <h4>
                                                    Invoice No. &nbsp;: &nbsp;
                                                    {dInvoiceNo === null
                                                      ? "-"
                                                      : dInvoiceNo}
                                                  </h4>
                                                </div>
                                                <div className="form-group">
                                                  <h4>
                                                    Invoice Amount &nbsp;:
                                                    &nbsp;₹ {dInvoiceAmount}
                                                  </h4>
                                                </div>
                                              </div>
                                              <div className="form_flex caseMobile">
                                                <div className="form-group">
                                                  <h4>
                                                    Total Amount &nbsp;: &nbsp;
                                                    {dTotalAmount}
                                                  </h4>
                                                </div>
                                                <div className="form-group">
                                                  <h4>
                                                    Discount &nbsp;: &nbsp;₹
                                                    {dDiscount}
                                                  </h4>
                                                </div>
                                              </div>
                                              <div className="form_flex caseMobile">
                                                <div className="form-group">
                                                  <h4>
                                                    Added By &nbsp;: &nbsp;
                                                    {dAddedBy}
                                                  </h4>
                                                </div>
                                                <div className="form-group">
                                                  <h4>
                                                    Remark &nbsp;: &nbsp;
                                                    {dRemark === ""
                                                      ? "-"
                                                      : dRemark}
                                                  </h4>
                                                </div>
                                              </div>
                                              <div className="form_flex caseMobile">
                                              <div className="form-group">
                                                <h4>
                                                  Contact Person Name &nbsp;: &nbsp;{" "}
                                                  {dContactPersonName}{" "}
                                                </h4>
                                              </div>
                                              <div className="form-group">
                                                <h4>
                                                  Contact Person No &nbsp;: &nbsp;{" "}
                                                  {dContactPersonContact === ""
                                                    ? "-"
                                                    : dContactPersonContact}{" "}
                                                </h4>
                                              </div>
                                            </div>
                                              <div className="form_flex caseMobile">
                                                <div className="form-group">
                                                  <table className="table detail_table">
                                                    <tr>
                                                      <th>S. No</th>
                                                      <th>Amount</th>
                                                      <th>Description</th>
                                                    </tr>

                                                    {invoiceDetailData &&
                                                      invoiceDetailData.map(
                                                        (val, index) => {
                                                          return (
                                                            <tr key={index}>
                                                              <td>
                                                                {index + 1}
                                                              </td>
                                                              <td>
                                                                {val.Amount}
                                                              </td>
                                                              <td>
                                                                {val.Description ===
                                                                ""
                                                                  ? "-"
                                                                  : val.Description}
                                                              </td>
                                                            </tr>
                                                          );
                                                        }
                                                      )}
                                                  </table>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <p className="case_detail_table">
                                    Data not found
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Add */}

                        <div
                          className="modal fade"
                          id="exampleModalLongINVAdd"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="exampleModalLongTitle"
                          aria-hidden="true"
                        >
                          <div
                            className="modal-dialog popUp e_popup modal-xl"
                            role="document"
                            // style={{ maxWidth: "70%" }}
                          >
                            <div className="modal-content">
                              <div className="add_wrap">
                                <div className="main_heading">
                                  <h2>Add Invoice</h2>
                                </div>
                              </div>
                              <div className="popup_modal">
                                <button
                                  type="button"
                                  className="close btn_popup Inv"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                                <div className="dashed_popup">
                                  <form role="form " onSubmit={HandleSubmit}>
                                    <div className="form_flex caseMobile">
                                      <div className="form-group">
                                        <label htmlFor="username">
                                          File No.
                                          <text className="m_star">*</text>
                                        </label>
                                        <select disabled id="city" required>
                                          {optionsCase &&
                                            optionsCase.map((val, index) => (
                                              <option
                                                key={index}
                                                value={val.value}
                                                selected={val.value === id}
                                              >
                                                {val.label}
                                              </option>
                                            ))}
                                        </select>
                                      </div>
                                      <div className="form-group">
                                        <label for="uname">
                                          Invoice Tempalte
                                        </label>

                                        <a
                                          className="visuald"
                                          href="/#/view-invoicetemplate"
                                        >
                                          +Add Template
                                        </a>
                                        <select
                                          value={invoiceTemId}
                                          className="name"
                                          id="city"
                                          onChange={(e) =>
                                            setInvoiceTemId(e.target.value)
                                          }
                                        >
                                          <option value="">
                                            ------ Please Select -------
                                          </option>
                                          {invoiceTem &&
                                            invoiceTem.map((val, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={val.Id}
                                                >
                                                  {val.Name}
                                                </option>
                                              );
                                            })}
                                        </select>
                                      </div>
                                      <div className="form-group">
                                        <label for="uname">
                                          Bank
                                          <text className="m_star">*</text>
                                        </label>
                                        <select
                                          required
                                          value={BankID}
                                          className="name"
                                          id="city"
                                          onChange={(e) =>
                                            setBankID(e.target.value)
                                          }
                                        >
                                          <option value="">
                                            ------ Please Select -------
                                          </option>
                                          {Bank &&
                                            Bank.map((val, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={val.value}
                                                >
                                                  {val.label}
                                                </option>
                                              );
                                            })}
                                        </select>
                                      </div>
                                      <div className="form-group">
                                        <label for="uname">
                                          Date
                                          <text className="m_star">*</text>
                                        </label>
                                        <input
                                          required
                                          autoComplete="off"
                                          value={Dates}
                                          className="name"
                                          max={currentDate}
                                          type="date"
                                          onChange={(e) =>
                                            setDates(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="form_flex caseMobile">
                                    <div className="form-group">
                                      <label htmlFor="uname">
                                        Contact Person For Invoice
                                        <text className="m_star">*</text>
                                      </label>
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "40px",
                                          borderRadius: "4px",
                                          border: "1px solid #7a0000",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            marginLeft: "20px",
                                          }}
                                        >
                                          <input
                                            style={{ width: "20px" }}
                                            className="name"
                                            type="radio"
                                            id="Customer"
                                            name="Customer"
                                            value="Customer"
                                            onChange={handleCustomerRelated}
                                            checked={taskRelated === "Customer"}
                                          />
                                          &nbsp;&nbsp;{" "}
                                          <label
                                            style={{ marginTop: "10px" }}
                                            htmlFor="Customer"
                                          >
                                            Same as Client
                                          </label>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                          <input
                                            style={{ width: "20px" }}
                                            className="name"
                                            type="radio"
                                            id="other"
                                            name="other"
                                            value="Other"
                                            onChange={handleCustomerRelated}
                                            checked={taskRelated === "Other"}
                                          />
                                          &nbsp;&nbsp;{" "}
                                          <label
                                            style={{ marginTop: "10px" }}
                                            htmlFor="other"
                                          >
                                            Any Other
                                          </label>
                                        </div>
                                      </div>
                                    </div>
    
                                    {customerDropDownShow ? (
                                      <>
                                        <div className="form-group">
                                          <label htmlFor="ClientName ">
                                          Contact Person Name{" "}
                                          </label>
                                          <input
                                            disabled
                                            value={conPersonName}
                                            className="name"
                                            type="text"
                                            placeholder="Enter Client Name"
                                            name="uname"
                                            required
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label htmlFor="ClientNumber">
                                          Contact Person Number
                                          </label>
                                          <input
                                            disabled
                                            value={conPersonContact}
                                            className="name"
                                            type="text"
                                            placeholder="Enter Client Number"
                                            name="uname"
                                            minLength={10}
                                            maxLength={10}
                                            required
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="form-group">
                                          <label htmlFor="ClientName ">
                                            Client Name{" "}
                                            <text className="m_star">*</text>
                                          </label>
                                          <input
                                            onChange={(e) =>
                                              setContactPersonName(e.target.value)
                                            }
                                            value={contactPersonName}
                                            className="name"
                                            type="text"
                                            placeholder="Enter Client Name"
                                            name="uname"
                                            required
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label htmlFor="ClientNumber">
                                            Client Number
                                            <text className="m_star">*</text>
                                          </label>
                                          <input
                                            onChange={(e) =>
                                              setContactPersonContact(
                                                e.target.value
                                              )
                                            }
                                            value={contactPersonContact}
                                            className="name"
                                            type="text"
                                            placeholder="Enter Client Number"
                                            name="uname"
                                            minLength={10}
                                            maxLength={10}
                                            required
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>



                                    <div className="main_heading">
                                      <h2>Invoice Details</h2>
                                    </div>

                                    <div>
                                      {formData1 &&
                                        formData1.map((row, index) => (
                                          <div key={index}>
                                            <div className="form_flex caseMobile">
                                              <div className="form-group">
                                                <label
                                                  htmlFor={`description-${index}`}
                                                >
                                                  Description
                                                </label>
                                                <input
                                                  value={row.Description}
                                                  type="text"
                                                  className="name"
                                                  placeholder="Enter Description"
                                                  onChange={(e) =>
                                                    handleDescriptionChange(
                                                      index,
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="form-group">
                                                <label
                                                  htmlFor={`amount-${index}`}
                                                >
                                                  Amount
                                                  <text className="m_star">
                                                    *
                                                  </text>
                                                </label>
                                                <input
                                                  required
                                                  onKeyPress={handleKeyPress}
                                                  value={row.Amount}
                                                  type="text"
                                                  className="name"
                                                  placeholder="Enter Amount"
                                                  onChange={(e) =>
                                                    handleAmountChange(
                                                      index,
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                              {index === 0 && (
                                                <a
                                                  onClick={addRow}
                                                  style={{
                                                    color: "white",
                                                    marginTop: "20px",
                                                    cursor: "pointer",
                                                    margin: "10px",
                                                    padding: "6px 10px",
                                                    background: "green",
                                                  }}
                                                >
                                                  +
                                                </a>
                                              )}

                                              {index !== 0 && (
                                                <a
                                                  onClick={() =>
                                                    removeRow(index)
                                                  }
                                                  style={{
                                                    color: "white",
                                                    marginTop: "20px",
                                                    cursor: "pointer",
                                                    margin: "10px",
                                                    padding: "6px 10px",
                                                  }}
                                                >
                                                  ❌
                                                </a>
                                              )}
                                            </div>
                                          </div>
                                        ))}

                                      <div className="form_flex test">
                                        <div className="form-group">
                                          <label htmlFor="remark">Remark</label>
                                          <textarea
                                            className="name"
                                            type="text"
                                            rows={10}
                                            placeholder="Enter Remark"
                                            name="remark"
                                            value={remark}
                                            onChange={(e) =>
                                              setRemark(e.target.value)
                                            }
                                          />
                                        </div>

                                        <div className="form-group">
                                          <div>
                                            <label htmlFor="discount">
                                              Invoice Total
                                            </label>
                                            <input
                                              readOnly
                                              type="text"
                                              placeholder=""
                                              name="discount"
                                              value={invoiceTotal}
                                            />
                                          </div>

                                          <div>
                                            <label htmlFor="discount">
                                              Discount ₹
                                            </label>
                                            <input
                                              className="name"
                                              type="text"
                                              placeholder=""
                                              name="discount"
                                              value={Discount}
                                              onChange={(e) =>
                                                setDiscount(e.target.value)
                                              }
                                            />
                                          </div>

                                          <div>
                                            <label htmlFor="invoice_amount">
                                              Final Amount
                                            </label>
                                            <input
                                              className="name"
                                              type="text"
                                              name="invoice_amount"
                                              value={InvoiceAmount}
                                              readOnly
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {saveButton ? (
                                      <div className="btn_main caseMobile">
                                        <button
                                          type="submit"
                                          className="btn_save"
                                        >
                                          Save
                                        </button>
                                        <button
                                          type="button"
                                          className="btn_save btn_cancle"
                                          data-dismiss="modal"
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                      <br />
                      {/* Invoice Ends */}
                      {/* Receipt Start */}
                      <>
                        <div className="report_crmgraph">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="myCard">
                                <div className="cardHeader">
                                  <h4>Receipt</h4>
                                  <span>
                                    <a
                                      style={{
                                        color: "white",
                                      }}
                                      type="button"
                                      data-toggle="modal"
                                      data-target="#exampleModalLongRCPAdd"
                                    >
                                      Add Receipt
                                      <img src="assets/img/icons/add.svg" />
                                    </a>
                                  </span>
                                  <a
                                    href="/#/view-receipt"
                                    className="rytContent"
                                  >
                                    <img
                                      src="assets/img/icons/dots.png"
                                      alt=""
                                      title="All Receipt"
                                    />
                                  </a>
                                </div>
                                {optionsCD.Receipt &&
                                optionsCD.Receipt[0].message === "Success" ? (
                                  <div className="cardBody">
                                    <table className="myTable">
                                      <thead>
                                        <th>Action</th>
                                        <th>Date </th>
                                        <th>Invoice No.</th>
                                        <th>Receipt No.</th>
                                        <th>Paid Amount </th>
                                        <th>TDS Amount </th>
                                        <th>Total Amount </th>
                                        <th>Transaction Type </th>
                                        <th>Details </th>
                                      </thead>

                                      {optionsCD.Receipt &&
                                      optionsCD.Receipt.length > 0 ? (
                                        optionsCD.Receipt.map((val, index) => (
                                          <tr
                                            key={index}
                                            className={
                                              index % 2 === 0
                                                ? "row-even"
                                                : "row-odd"
                                            }
                                          >
                                            <td>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  gap: "15px",
                                                }}
                                              >
                                                <span>
                                                  <a
                                                    type="button"
                                                    data-toggle="modal"
                                                    data-target="#exampleModalLongRCPEdit"
                                                    onClick={() =>
                                                      getByIdRcp(val.Id)
                                                    }
                                                  >
                                                    <img
                                                      src="assets/img/icons/edit.svg"
                                                      title="Edit"
                                                    />
                                                  </a>
                                                </span>
                                                <span>
                                                  <a
                                                    type="button"
                                                    data-toggle="modal"
                                                    data-target="#exampleModalLongRCPDetail"
                                                    onClick={() =>
                                                      detailsRcp(val.Id)
                                                    }
                                                  >
                                                    <img
                                                      src="assets/img/icons/eye.svg"
                                                      title="Details"
                                                    />
                                                  </a>
                                                </span>
                                                <span>
                                                  <Link
                                                    target="_blank"
                                                    to={`/View_Receipt_PDF/${val.Id}`}
                                                    title="PDF"
                                                  >
                                                    <img
                                                      src="assets/img/icons/pdf.svg"
                                                      className="pdf"
                                                      title="PDF"
                                                    />
                                                  </Link>
                                                </span>
                                                <span>
                                                  <a
                                                    type="button"
                                                    onClick={() =>
                                                      deActive(
                                                        val,
                                                        "Receipt/ReceiptStatus"
                                                      )
                                                    }
                                                  >
                                                    {val.Status === "1" ? (
                                                      <img
                                                        className="thumb_icon"
                                                        src="assets/img/icons/Up.svg"
                                                        title="Status"
                                                      />
                                                    ) : (
                                                      <img
                                                        className="thumb_icon"
                                                        src="assets/img/icons/Down.svg"
                                                        title="Status"
                                                      />
                                                    )}
                                                  </a>
                                                </span>
                                                <span>
                                                  <Link
                                                    target="_blank"
                                                    to={`/View_Receipt_WP/${val.Id}`}
                                                    title="PDF"
                                                  >
                                                    <img
                                                      src="assets/img/icons/send.svg"
                                                      title="Whatsapp PDF"
                                                    />
                                                  </Link>
                                                </span>
                                                {/* {isDelete !== "0" && ( */}
                                                <span
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#exampleModalRCPDelete"
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    setDelId(val.Id)
                                                  }
                                                >
                                                  <img
                                                    src="assets/img/icons/delete.svg"
                                                    alt="Delete"
                                                    title="Delete"
                                                  />
                                                </span>
                                                {/* )} */}
                                              </div>
                                            </td>
                                            <td>{formatDate1(val.Date)}</td>
                                            <td>{val.InvoiceNo}</td>
                                            <td>{val.ReceiptNo}</td>
                                            <td>{val.PaidAmount} </td>
                                            <td>{val.TDSAmount} </td>
                                            <td>{val.TotalAmount} </td>
                                            <td>
                                              {val.TransactionType === ""
                                                ? "-"
                                                : val.TransactionType}
                                            </td>
                                            <td>
                                              {val.Details === ""
                                                ? "-"
                                                : val.Details}
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <div></div>
                                      )}
                                    </table>
                                    {/*-------- DELETE FORM -------*/}

                                    <div
                                      className="modal fade sc_modal"
                                      id="exampleModalRCPDelete"
                                      tabIndex={-1}
                                      aria-labelledby="exampleModalLabel"
                                      aria-hidden="true"
                                    >
                                      <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <button
                                              type="button"
                                              className="btn-close dRcp"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            />
                                          </div>
                                          <div className="modal-body">
                                            <h4>Delete Row</h4>
                                            <p>
                                              Are you sure you want to delete
                                              this Receipt ?
                                            </p>
                                            <ul>
                                              <li>
                                                <a
                                                  className="btn btn-secondary"
                                                  data-bs-dismiss="modal"
                                                >
                                                  Cancel
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="btn btn-danger"
                                                  onClick={() =>
                                                    deleteCaseLead(delId)
                                                  }
                                                >
                                                  Delete
                                                </a>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    {/*-------- EDIT FORM ---------*/}

                                    <div
                                      className="modal fade"
                                      id="exampleModalLongRCPEdit"
                                      tabIndex={-1}
                                      role="dialog"
                                      aria-labelledby="exampleModalLongTitle"
                                      aria-hidden="true"
                                    >
                                      <div
                                        className="modal-dialog modal-xl"
                                        role="document"
                                        // style={{ maxWidth: "70%" }}
                                      >
                                        <div className="modal-content">
                                          <div className="add_wrap">
                                            <div className="main_heading">
                                              <h2>Edit Receipt</h2>
                                            </div>
                                          </div>
                                          <div className="popup_modal ">
                                            <button
                                              type="button"
                                              className="close e_popup btn_popup RcpE"
                                              data-dismiss="modal"
                                              aria-label="Close"
                                            >
                                              <span aria-hidden="true">×</span>
                                            </button>
                                            <div className="dashed_popup">
                                              <form
                                                role="form"
                                                onSubmit={submitEditHandler}
                                              >
                                                <div className="form_flex caseMobile">
                                                  <div className="form-group">
                                                    <label htmlFor="username">
                                                      File No.
                                                      <text className="m_star">
                                                        *
                                                      </text>
                                                    </label>
                                                    <select
                                                      disabled
                                                      id="city"
                                                      required
                                                    >
                                                      {optionsCase &&
                                                        optionsCase.map(
                                                          (val, index) => (
                                                            <option
                                                              key={index}
                                                              value={val.value}
                                                              selected={
                                                                val.value ===
                                                                eCaseID
                                                              }
                                                            >
                                                              {val.label}
                                                            </option>
                                                          )
                                                        )}
                                                    </select>
                                                  </div>
                                                  <div className="form-group">
                                                    <label htmlFor="invoice">
                                                      Invoice
                                                      <text className="m_star">
                                                        *
                                                      </text>
                                                    </label>
                                                    <select
                                                      className="name"
                                                      id="cars"
                                                      onChange={(e) =>
                                                        setEditInvoiceId(
                                                          e.target.value
                                                        )
                                                      }
                                                      value={EditInvoiceId}
                                                      required
                                                    >
                                                      <option value="">
                                                        --Please Select--
                                                      </option>
                                                      {Invoice1 &&
                                                      Invoice1.length ? (
                                                        Invoice1.map(
                                                          (val, index) => {
                                                            return (
                                                              <option
                                                                key={index}
                                                                value={val.Id}
                                                                selected={
                                                                  val.Id ===
                                                                  EditInvoiceId
                                                                }
                                                              >
                                                                {`InvoiceNo - ${
                                                                  val.InvoiceNo
                                                                } / Amount - ${
                                                                  val.InvoiceAmount -
                                                                  val.PaidAmount
                                                                } / Date - ${formatDate1(
                                                                  val.Date
                                                                )}`}
                                                              </option>
                                                            );
                                                          }
                                                        )
                                                      ) : (
                                                        <option value="">
                                                          Loading...
                                                        </option>
                                                      )}
                                                    </select>
                                                  </div>
                                                  <div className="form-group">
                                                    <label htmlFor="uname">
                                                      Date
                                                      <text className="m_star">
                                                        *
                                                      </text>
                                                    </label>
                                                    <input
                                                      required
                                                      value={EditDate}
                                                      max={currentDate}
                                                      type="Date"
                                                      className="name"
                                                      onChange={(e) =>
                                                        setEditDate(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div className="form_flex caseMobile">
                                                  <div className="form-group">
                                                    <label htmlFor="uname">
                                                      Transaction Type
                                                      <text className="m_star">
                                                        *
                                                      </text>
                                                    </label>
                                                    <select
                                                      required
                                                      value={
                                                        EditTransactionType
                                                      }
                                                      className="name"
                                                      onChange={(e) =>
                                                        setEditTransactionType(
                                                          e.target.value
                                                        )
                                                      }
                                                    >
                                                      <option value="">
                                                        --Please Select--
                                                      </option>
                                                      <option value="CASH">
                                                        CASH
                                                      </option>
                                                      <option value="CHEQUE">
                                                        CHEQUE
                                                      </option>
                                                      <option value="NEFT/RTGS/IMPS">
                                                        NEFT/RTGS/IMPS
                                                      </option>
                                                      <option value="CARDPAYMENT">
                                                        CARD PAYMENT
                                                      </option>
                                                      <option value="PAYMENTGATEWAY">
                                                        PAYMENT GATEWAY
                                                      </option>
                                                      <option value="OTHERS">
                                                        OTHERS
                                                      </option>
                                                    </select>
                                                  </div>
                                                  <div className="form-group">
                                                    <label for="uname">
                                                      Payment Detail
                                                    </label>
                                                    <input
                                                      autoComplete="off"
                                                      className="name"
                                                      type="text"
                                                      placeholder="Enter Payment Detail"
                                                      value={EditDetails}
                                                      onChange={(e) =>
                                                        setEditDetails(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <hr />
                                                <div className="form_flex caseMobile">
                                                  <div className="form-group">
                                                    <label htmlFor="amount">
                                                      Amount
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="name"
                                                      placeholder="Enter Amount"
                                                      value={EditPaidAmount}
                                                      onChange={
                                                        editHandleAmount
                                                      }
                                                    />
                                                  </div>
                                                  <div className="form-group">
                                                    <label htmlFor="tds">
                                                      TDS Amount
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="name"
                                                      placeholder="Enter TDS"
                                                      value={EditTDSAmount}
                                                      onChange={
                                                        editHandleTdsAmount
                                                      }
                                                    />
                                                  </div>
                                                  <div className="form-group">
                                                    <label htmlFor="amount">
                                                      Total Amount
                                                    </label>
                                                    <input
                                                      type="text"
                                                      readOnly
                                                      className="name"
                                                      placeholder="Enter Amount"
                                                      value={editTotalAmount}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="btn_main caseMobile">
                                                  <button
                                                    type="submit"
                                                    className="btn_save"
                                                  >
                                                    Save
                                                  </button>
                                                  <button
                                                    type="button"
                                                    data-dismiss="modal"
                                                    className="btn_save btn_cancle"
                                                  >
                                                    Cancel
                                                  </button>
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    {/*-------- DETAILS ---------*/}

                                    <div
                                      className="modal fade"
                                      id="exampleModalLongRCPDetail"
                                      tabIndex={-1}
                                      role="dialog"
                                      aria-labelledby="exampleModalLongTitle"
                                      aria-hidden="true"
                                    >
                                      <div
                                        className="modal-dialog modal-lg"
                                        role="document"
                                        // style={{ maxWidth: "50%" }}
                                      >
                                        <div className="modal-content">
                                          <div className="add_wrap">
                                            <div className="main_heading">
                                              <h2>Receipt Details</h2>
                                            </div>
                                          </div>
                                          <div className="popup_modal ">
                                            <button
                                              type="button"
                                              className="close ab btn_popup"
                                              data-dismiss="modal"
                                              aria-label="Close"
                                            >
                                              <span aria-hidden="true">×</span>
                                            </button>
                                            <div className="dashed_popup">
                                              <div className="form_flex caseMobile">
                                                <div className="form-group">
                                                  <h4>
                                                    Case Name &nbsp;: &nbsp;
                                                    {dCaseID}
                                                  </h4>
                                                </div>
                                                <div className="form-group">
                                                  <h4>
                                                    Case Year &nbsp;: &nbsp;
                                                    {dCaseYear}
                                                  </h4>
                                                </div>
                                              </div>

                                              <div className="form_flex caseMobile">
                                                <div className="form-group">
                                                  <h4>
                                                    Total Amount &nbsp;: &nbsp;
                                                    {dTotalAmount}
                                                  </h4>
                                                </div>
                                                <div className="form-group">
                                                  <h4>
                                                    Date &nbsp;: &nbsp;
                                                    {formatDate1(dDate)}
                                                  </h4>
                                                </div>
                                              </div>

                                              <div className="form_flex caseMobile">
                                                <div className="form-group">
                                                  <h4>
                                                    TDS &nbsp;: &nbsp;₹
                                                    {dTDSAmount}
                                                  </h4>
                                                </div>
                                                <div className="form-group">
                                                  <h4>
                                                    Paid Amount &nbsp;: &nbsp;₹
                                                    {dPaidAmount}
                                                  </h4>
                                                </div>
                                              </div>

                                              <div className="form_flex caseMobile">
                                                <div className="form-group">
                                                  <h4>
                                                    Receipt No. &nbsp;: &nbsp;
                                                    {dReceiptNo === null
                                                      ? "-"
                                                      : dReceiptNo}
                                                  </h4>
                                                </div>
                                                <div className="form-group">
                                                  <h4>
                                                    Invoice No. &nbsp;: &nbsp;
                                                    {dInvoiceNo === null
                                                      ? "-"
                                                      : dInvoiceNo}
                                                  </h4>
                                                </div>
                                              </div>

                                              <div className="form_flex caseMobile">
                                                <div className="form-group">
                                                  <h4>
                                                    Transaction Type &nbsp;:
                                                    &nbsp; {dTransactionType}
                                                  </h4>
                                                </div>
                                                <div className="form-group">
                                                  <h4>
                                                    Added By &nbsp;: &nbsp;
                                                    {dAddedBy}
                                                  </h4>
                                                </div>
                                              </div>

                                              <div className="form_flex caseMobile">
                                                <div className="form-group">
                                                  <h4>
                                                    Detail &nbsp;: &nbsp;
                                                    {dDetails === ""
                                                      ? "-"
                                                      : dDetails}
                                                  </h4>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <p className="case_detail_table">
                                    Data not found
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                      <br />
                      {/*-------- ADD FORM ---------*/}
                      <div
                        className="modal fade"
                        id="exampleModalLongRCPAdd"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="exampleModalLongTitle"
                        aria-hidden="true"
                        show={show}
                      >
                        <div className="modal-dialog modal-xl" role="document">
                          <div className="modal-content">
                            <div className="add_wrap">
                              <div className="main_heading">
                                <h2>Add Receipt </h2>
                              </div>
                            </div>
                            <div className="popup_modal ">
                              <button
                                type="button"
                                className="close btn_popup Rcp_add"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">×</span>
                              </button>
                              <div className="dashed_popup">
                                <form role="form" onSubmit={submitHandler}>
                                  <div className="form_flex caseMobile">
                                    <div className="form-group">
                                      <label htmlFor="username">
                                        File No.
                                        <text className="m_star">*</text>
                                      </label>
                                      <select disabled id="city" required>
                                        {optionsCase &&
                                          optionsCase.map((val, index) => (
                                            <option
                                              key={index}
                                              value={val.value}
                                              selected={val.value === id}
                                            >
                                              {val.label}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="invoice">
                                        Invoice
                                        <text className="m_star">*</text>
                                      </label>

                                      <select
                                        className="name"
                                        id="cars"
                                        onChange={(e) =>
                                          setInvoiceID(e.target.value)
                                        }
                                        required
                                      >
                                        <option value="">
                                          --Please Select--
                                        </option>
                                        {Invoice1 && Invoice1.length ? (
                                          Invoice1.map((val, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={val.Id}
                                              >
                                                {`InvoiceNo - ${
                                                  val.InvoiceNo
                                                } / Amount - ${
                                                  val.InvoiceAmount -
                                                  val.PaidAmount
                                                } / Date - ${formatDate1(
                                                  val.Date
                                                )}`}
                                              </option>
                                            );
                                          })
                                        ) : (
                                          <option value="">Loading...</option>
                                        )}
                                      </select>
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="uname">
                                        Date
                                        <text className="m_star">*</text>
                                      </label>
                                      <input
                                        required
                                        value={Dates}
                                        type="Date"
                                        max={currentDate}
                                        className="name"
                                        onChange={(e) =>
                                          setDates(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="form_flex caseMobile">
                                    <div className="form-group">
                                      <label htmlFor="uname">
                                        Transaction Type
                                        <text className="m_star">*</text>
                                      </label>
                                      <select
                                        value={TransactionType}
                                        required
                                        className="name"
                                        onChange={(e) =>
                                          setTransactionType(e.target.value)
                                        }
                                      >
                                        <option value="">
                                          --Please Select--
                                        </option>
                                        <option value="CASH">CASH</option>
                                        <option value="CHEQUE">CHEQUE</option>
                                        <option value="NEFT/RTGS/IMPS">
                                          NEFT/RTGS/IMPS
                                        </option>
                                        <option value="CARD PAYMENT">
                                          CARD PAYMENT
                                        </option>
                                        <option value="PAYMENT GATEWAY">
                                          PAYMENT GATEWAY
                                        </option>
                                        <option value="OTHERS">OTHERS</option>
                                      </select>
                                    </div>
                                    <div className="form-group">
                                      <label for="uname">Payment Detail</label>
                                      <input
                                        value={Details}
                                        autoComplete="off"
                                        className="name"
                                        type="text"
                                        placeholder="Enter Payment Detail"
                                        onChange={(e) =>
                                          setDetails(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>

                                  <hr />
                                  <div className="form_flex caseMobile">
                                    <div className="form-group">
                                      <label htmlFor="amount">Amount</label>
                                      <input
                                        value={PaidAmount}
                                        type="text"
                                        className="name"
                                        placeholder="Enter Amount"
                                        onChange={handleAmount}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="tds">TDS Amount</label>
                                      <input
                                        value={TDSAmount}
                                        type="text"
                                        className="name"
                                        placeholder="Enter TDS"
                                        onChange={handleTdsAmount}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="amount">
                                        Total Amount
                                      </label>
                                      <input
                                        type="text"
                                        readOnly
                                        className="name"
                                        placeholder="Enter Amount"
                                        value={totalAmount}
                                      />
                                    </div>
                                  </div>
                                  {saveButton ? (
                                    <div className="btn_main caseMobile">
                                      <button
                                        type="submit"
                                        className="btn_save"
                                      >
                                        Save
                                      </button>
                                      <button
                                        type="button"
                                        data-dismiss="modal"
                                        className="btn_save btn_cancle"
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Receipt Ends */}
                      {/* Settle Amount Start */}
                      <>
                        <div className="report_crmgraph">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="myCard">
                                <div className="cardHeader">
                                  <h4>Settle Amount</h4>
                                  <span>
                                    <a
                                      style={{
                                        color: "white",
                                      }}
                                      type="button"
                                      data-toggle="modal"
                                      data-target="#exampleModalLongSAAdd"
                                    >
                                      Add Settle Amount
                                      <img src="assets/img/icons/add.svg" />
                                    </a>
                                  </span>
                                  <a
                                    href="/#/view-settleAmount"
                                    className="rytContent"
                                  >
                                    <img
                                      src="assets/img/icons/dots.png"
                                      alt=""
                                      title="All Settle Amount"
                                    />
                                  </a>
                                </div>
                                {optionsCD.SettleAmount &&
                                optionsCD.SettleAmount[0].message ===
                                  "Success" ? (
                                  <div className="cardBody">
                                    <table className="myTable">
                                      <thead>
                                        <th>Action</th>
                                        <th>Date</th>
                                        <th>Invoice No.</th>
                                        <th>Receipt No.</th>
                                        <th>Counsel</th>
                                        <th>Counsel Amount </th>
                                        <th>Payment Mode </th>
                                        <th>Total Amount </th>
                                      </thead>

                                      {optionsCD.SettleAmount &&
                                      optionsCD.SettleAmount.length > 0 ? (
                                        optionsCD.SettleAmount.map(
                                          (val, index) => (
                                            <tr
                                              key={index}
                                              className={
                                                index % 2 === 0
                                                  ? "row-even"
                                                  : "row-odd"
                                              }
                                            >
                                              <td>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    gap: "15px",
                                                  }}
                                                >
                                                  <span>
                                                    <a
                                                      type="button"
                                                      data-toggle="modal"
                                                      data-target="#exampleModalLongSADetail"
                                                      onClick={() =>
                                                        detailsSA(val.Id)
                                                      }
                                                    >
                                                      <img
                                                        src="assets/img/icons/eye.svg"
                                                        title="Details"
                                                      />
                                                    </a>
                                                  </span>
                                                  <span>
                                                    <a
                                                      type="button"
                                                      onClick={() =>
                                                        deActive(
                                                          val,
                                                          "SettleAmount/SettleAmountStatus"
                                                        )
                                                      }
                                                    >
                                                      {val.Status === "1" ? (
                                                        <img
                                                          className="thumb_icon"
                                                          src="assets/img/icons/Up.svg"
                                                          title="Status"
                                                        />
                                                      ) : (
                                                        <img
                                                          className="thumb_icon"
                                                          src="assets/img/icons/Down.svg"
                                                          title="Status"
                                                        />
                                                      )}
                                                    </a>
                                                  </span>
                                                  <span
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#exampleModalSADelete"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      setDelIdSA(val.Id)
                                                    }
                                                  >
                                                    <img
                                                      src="assets/img/icons/delete.svg"
                                                      alt="Delete"
                                                      title="Delete"
                                                    />
                                                  </span>
                                                  {/* )} */}
                                                </div>
                                              </td>
                                              <td>{formatDate1(val.Date)}</td>
                                              <td>{val.InvoiceNo}</td>
                                              <td>{val.ReceiptNo}</td>
                                              <td>{val.Counsel}</td>
                                              <td>{val.CounselAmount}</td>
                                              <td>
                                                {val.PaymentMode === ""
                                                  ? "-"
                                                  : val.PaymentMode}
                                              </td>
                                              <td>{val.TotalAmount} </td>
                                            </tr>
                                          )
                                        )
                                      ) : (
                                        <div></div>
                                      )}
                                    </table>
                                  </div>
                                ) : (
                                  <p className="case_detail_table">
                                    Data not found
                                  </p>
                                )}
                                {/*-------- DELETE FORM -------*/}

                                <div
                                  className="modal fade sc_modal"
                                  id="exampleModalSADelete"
                                  tabIndex={-1}
                                  aria-labelledby="exampleModalLabel"
                                  aria-hidden="true"
                                >
                                  <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <button
                                          type="button"
                                          className="btn-close dSA"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        />
                                      </div>
                                      <div className="modal-body">
                                        <h4>Delete Row</h4>
                                        <p>
                                          Are you sure you want to delete this
                                          SettleAmount ?
                                        </p>
                                        <ul>
                                          <li>
                                            <a
                                              className="btn btn-secondary"
                                              data-bs-dismiss="modal"
                                            >
                                              Cancel
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="btn btn-danger"
                                              onClick={() => deleteSA(delIdSA)}
                                            >
                                              Delete
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/*-------- ADD FORM ---------*/}

                                <div
                                  className="modal fade"
                                  id="exampleModalLongSAAdd"
                                  tabIndex={-1}
                                  role="dialog"
                                  aria-labelledby="exampleModalLongTitle"
                                  aria-hidden="true"
                                  show={show}
                                >
                                  <div
                                    className="modal-dialog modal-xl"
                                    role="document"
                                    // style={{ maxWidth: "70%" }}
                                  >
                                    <div className="modal-content">
                                      <div className="add_wrap">
                                        <div className="main_heading">
                                          <h2>Add Settle Amount </h2>
                                        </div>
                                      </div>
                                      <div className="popup_modal ">
                                        <button
                                          type="button"
                                          className="close btn_popup SA"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                        >
                                          <span aria-hidden="true">×</span>
                                        </button>
                                        <div className="dashed_popup">
                                          <form
                                            role="form"
                                            onSubmit={submitHandlerSA}
                                          >
                                            <div className="form_flex caseMobile">
                                              <div className="form-group">
                                                <label htmlFor="date">
                                                  Date
                                                  <text className="m_star">
                                                    *
                                                  </text>
                                                </label>
                                                <input
                                                  className="name"
                                                  type="date"
                                                  id="date"
                                                  value={DatesSA}
                                                  onChange={(e) =>
                                                    setDatesSA(e.target.value)
                                                  }
                                                  max={currentDate}
                                                />
                                              </div>
                                              <div className="form-group">
                                                <label htmlFor="username">
                                                  File No.
                                                  <text className="m_star">
                                                    *
                                                  </text>
                                                </label>
                                                <select
                                                  disabled
                                                  id="city"
                                                  required
                                                >
                                                  {optionsCase &&
                                                    optionsCase.map(
                                                      (val, index) => (
                                                        <option
                                                          key={index}
                                                          value={val.value}
                                                          selected={
                                                            val.value === id
                                                          }
                                                        >
                                                          {val.label}
                                                        </option>
                                                      )
                                                    )}
                                                </select>
                                              </div>
                                              <div className="form-group">
                                                <label htmlFor="invoice">
                                                  Invoice
                                                  <text className="m_star">
                                                    *
                                                  </text>
                                                </label>

                                                <select
                                                  value={invoiceIDSettle}
                                                  className="name"
                                                  id="cars"
                                                  onChange={(e) =>
                                                    setInvoiceIDSettle(
                                                      e.target.value
                                                    )
                                                  }
                                                  required
                                                >
                                                  <option value="">
                                                    --Please Select--
                                                  </option>
                                                  {InvoiceSA &&
                                                  InvoiceSA.length ? (
                                                    InvoiceSA.map(
                                                      (val, index) => {
                                                        return (
                                                          <option
                                                            key={index}
                                                            value={
                                                              val.InvoiceID
                                                            }
                                                          >
                                                            {` Invoice No. - ${val.InvoiceNo} / Amount - ${val.InvoiceAmount}`}
                                                          </option>
                                                        );
                                                      }
                                                    )
                                                  ) : (
                                                    <option value="">
                                                      Loading...
                                                    </option>
                                                  )}
                                                </select>
                                              </div>
                                            </div>
                                            <div className="form_flex caseMobile">
                                              <div className="form-group">
                                                <label htmlFor="Receipt">
                                                  Receipt
                                                  <text className="m_star">
                                                    *
                                                  </text>
                                                </label>

                                                <select
                                                  value={receiptID}
                                                  className="name"
                                                  id="cars"
                                                  onChange={(e) =>
                                                    setReceiptID(e.target.value)
                                                  }
                                                  required
                                                >
                                                  <option value="">
                                                    --Please Select--
                                                  </option>
                                                  {receiptData &&
                                                  receiptData.length ? (
                                                    receiptData.map(
                                                      (val, index) => {
                                                        return (
                                                          <option
                                                            key={index}
                                                            value={val.Id}
                                                          >
                                                            {` Receipt No - ${val.ReceiptNo} / Paid - ${val.PaidAmount} / Total Amount - ${val.TotalAmount}`}
                                                          </option>
                                                        );
                                                      }
                                                    )
                                                  ) : (
                                                    <option value="">
                                                      Loading...
                                                    </option>
                                                  )}
                                                </select>
                                              </div>
                                              <div className="form-group">
                                                <label htmlFor="uname">
                                                  Received Amount
                                                </label>
                                                <input
                                                  readOnly
                                                  type="text"
                                                  className="name"
                                                  value={ReceivedAmount}
                                                />
                                              </div>
                                              <div className="form-group">
                                                <label htmlFor="uname">
                                                  Payment Type
                                                </label>
                                                <select
                                                  className="name"
                                                  value={PaymentMode}
                                                  onChange={(e) =>
                                                    setPaymentMode(
                                                      e.target.value
                                                    )
                                                  }
                                                >
                                                  <option value="">
                                                    ----- Please select -----
                                                  </option>
                                                  <option value="CASH">
                                                    CASH
                                                  </option>
                                                  <option value="ONLINE">
                                                    ONLINE
                                                  </option>
                                                  <option value="CHEQUE">
                                                    CHEQUE
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                            <hr />
                                            {updateData &&
                                              updateData.map((val, index) => {
                                                return (
                                                  <div className="form_flex caseMobile">
                                                    <div className="form-group">
                                                      <label htmlFor="uname">
                                                        {val.CounselType === "1"
                                                          ? "Counsel Name"
                                                          : "Co Counsel Name"}
                                                      </label>
                                                      <input
                                                        className="name"
                                                        readOnly
                                                        type="text"
                                                        placeholder=""
                                                        value={val.counsel}
                                                      />
                                                    </div>
                                                    <div className="form-group">
                                                      <label htmlFor="uname">
                                                        {val.CounselType === "1"
                                                          ? "Counsel ( % )"
                                                          : "Co Counsel ( % )"}
                                                      </label>
                                                      <input
                                                        className="name"
                                                        type="text"
                                                        placeholder="%"
                                                        value={`${val.CounselPer} %`}
                                                        readOnly
                                                      />
                                                    </div>
                                                    <div className="form-group">
                                                      <label htmlFor="uname">
                                                        {val.CounselType === "1"
                                                          ? "Counsel Amount"
                                                          : "Co Counsel Amount"}
                                                      </label>
                                                      <input
                                                        className="name"
                                                        type="text"
                                                        placeholder="0"
                                                        value={val.amount}
                                                        onChange={(e) =>
                                                          handleCounselAmount(
                                                            index,
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                );
                                              })}

                                            <div className="form_flex caseMobile">
                                              <div className="form-group">
                                                <label htmlFor="uname">
                                                  Remark
                                                </label>
                                                <textarea
                                                  type="text"
                                                  className="name"
                                                  placeholder="Enter Remark"
                                                  value={remarkSA}
                                                  onChange={(e) =>
                                                    setRemarkSA(e.target.value)
                                                  }
                                                />
                                              </div>
                                            </div>

                                            <div className="btn_main caseMobile">
                                              <button
                                                type="submit"
                                                className="btn_save"
                                              >
                                                Save
                                              </button>

                                              <button
                                                type="button"
                                                data-dismiss="modal"
                                                className="btn_save btn_cancle"
                                                // onClick={filterCancel}
                                              >
                                                Cancel
                                              </button>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/*-------- DETAILS ---------*/}

                                <div
                                  className="modal fade"
                                  id="exampleModalLongSADetail"
                                  tabIndex={-1}
                                  role="dialog"
                                  aria-labelledby="exampleModalLongTitle"
                                  aria-hidden="true"
                                >
                                  <div
                                    className="modal-dialog modal-lg"
                                    role="document"
                                    // style={{ maxWidth: "50%" }}
                                  >
                                    <div className="modal-content">
                                      <div className="add_wrap">
                                        <div className="main_heading">
                                          <h2>Settle Amount Details</h2>
                                        </div>
                                      </div>
                                      <div className="popup_modal ">
                                        <button
                                          type="button"
                                          className="close ab btn_popup"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                        >
                                          <span aria-hidden="true">×</span>
                                        </button>
                                        <div className="dashed_popup">
                                          <div className="form_flex caseMobile">
                                            <div className="form-group">
                                              <h4>
                                                Invoice No. &nbsp;: &nbsp;
                                                {dInvoiceNoSA}
                                              </h4>
                                            </div>
                                            <div className="form-group">
                                              <h4>
                                                Receipt No. &nbsp;: &nbsp;
                                                {dReceiptNoSA}
                                              </h4>
                                            </div>
                                          </div>

                                          <div className="form_flex caseMobile">
                                            <div className="form-group">
                                              <h4>
                                                Added By &nbsp;: &nbsp;
                                                {dAddedBySA}
                                              </h4>
                                            </div>
                                            <div className="form-group">
                                              <h4>
                                                Date &nbsp;: &nbsp;
                                                {dDateSA === "0000-00-00"
                                                  ? "-"
                                                  : formatDate1(dDateSA)}
                                              </h4>
                                            </div>
                                          </div>

                                          <div className="form_flex caseMobile">
                                            <div className="form-group">
                                              <h4>
                                                Total Amount &nbsp;: &nbsp;
                                                {dTotalAmountSA}
                                              </h4>
                                            </div>

                                            <div className="form-group">
                                              <h4>
                                                Payment Mode &nbsp;: &nbsp;
                                                {dPaymentMode === ""
                                                  ? "-"
                                                  : dPaymentMode}
                                              </h4>
                                            </div>
                                          </div>

                                          <div className="form_flex caseMobile">
                                            <div className="form-group">
                                              <h4>
                                                Case Year &nbsp;: &nbsp;
                                                {dCaseYearSA}
                                              </h4>
                                            </div>

                                            <div className="form-group">
                                              <h4>
                                                Case Name &nbsp;: &nbsp;
                                                {dCaseIDSA}
                                              </h4>
                                            </div>
                                          </div>

                                          <div className="form_flex caseMobile">
                                            <div className="form-group">
                                              <h4>
                                                Remark &nbsp;: &nbsp;
                                                {dRemarkSA === ""
                                                  ? "-"
                                                  : dRemarkSA}
                                              </h4>
                                            </div>
                                          </div>

                                          <div className="form_flex caseMobile">
                                            <div className="form-group">
                                              <table className="table detail_table">
                                                <tr>
                                                  <th>S. No</th>
                                                  <th>Counsel Name</th>
                                                  <th>Counsel Amount</th>
                                                </tr>

                                                {dCounselName &&
                                                  dCounselName.map(
                                                    (val, index) => {
                                                      return (
                                                        <tr key={index}>
                                                          <td>{index + 1}</td>
                                                          <td>{val.Counsel}</td>
                                                          <td>
                                                            {val.CounselAmount}
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                      <br />
                      {/* Settle Amount Ends */}
                      <div>
                        <button className="btn btn-client">
                          <a
                            type="button"
                            className="btn btn-primary btn_client"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`#/view-Appointmentdetails/${id}`}
                          >
                            Appointment
                          </a>
                        </button>{" "}
                        <button className="btn btn-client">
                          <a
                            type="button"
                            className="btn btn-primary btn_client"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`#/view-Letterdetails/${id}`}
                          >
                            Letter
                          </a>
                        </button>
                        <button className="btn btn-client">
                          <a
                            type="button"
                            className="btn btn-primary btn_client"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`#/view-Eventdetails/${id}`}
                          >
                            Event
                          </a>
                        </button>
                        <button className="btn  btn-client">
                          <a
                            type="button"
                            className="btn btn-primary btn_client"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`#/view-Notesdetails/${id}`}
                          >
                            Notes
                          </a>
                        </button>
                        <button className="btn  btn-client">
                          <a
                            type="button"
                            className="btn btn-primary btn_client"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`#/view-Expensedetails/${id}`}
                          >
                            Expenses
                          </a>
                        </button>
                        <button className="btn  btn-client">
                          <a
                            type="button"
                            className="btn btn-primary btn_client"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`#/view-Documentdetails/${id}`}
                          >
                            Document
                          </a>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <Footer />
            </div>

            {/*--------- Toster ---------*/}

            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        </DocumentTitle>
      )}

      {/*-------------------- Edit Client Form-------------------- */}
      <div
        className="modal fade"
        id="exampleModalLongClientEdit"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog popUp modal-xl"
          role="document"
          // style={{ maxWidth: "75%" }}
        >
          <div className="modal-content">
            <div className="add_wrap">
              <div className="main_heading">
                <h2>Edit Client</h2>
              </div>
            </div>
            <div className="popup_modal ">
              <button
                type="button"
                className="close btn_popup closeclientEdit"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>

              <div className="dashed_popup">
                <form role="form " onSubmit={HandleEditClientSubmit}>
                  <div className="main_heading">
                    <h2>Personal Details </h2>
                  </div>
                  <hr />
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label for="uname">
                        Full Name
                        <text className="m_star">*</text>
                      </label>
                      <input
                        autoComplete="off"
                        className="name"
                        value={EditFirstName}
                        type="text"
                        placeholder="Enter Full Name"
                        onChange={(e) => setEditFirstName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label for="uname">
                        Mobile No
                        <text className="m_star">*</text>
                      </label>
                      <input
                        value={EditMobileNo}
                        className="name"
                        onKeyPress={handleKeyPress}
                        maxLength={10}
                        autoComplete="off"
                        type="text"
                        placeholder="Enter Mobile No"
                        onChange={handleInputChange1}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label for="uname">
                        Last Name & Son/Wife/Daughter of
                      </label>
                      <input
                        onKeyPress={handleKeyPressalpha}
                        autoComplete="off"
                        className="name"
                        value={EditLastName}
                        type="text"
                        placeholder="Enter Name of Father/Husband"
                        onChange={(e) => setEditLastName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label for="uname">Designation </label>
                      <input
                        onKeyPress={handleKeyPressalpha}
                        value={EditDesignation}
                        autoComplete="off"
                        className="name"
                        type="text"
                        placeholder="Enter Designation "
                        onChange={(e) => setEditDesignation(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label for="uname">Company Name</label>
                      <input
                        autoComplete="off"
                        className="name"
                        value={EditCompanyName}
                        type="text"
                        placeholder="Enter Company Name"
                        onChange={(e) => setEditCompanyName(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <label for="uname">Email</label>
                      <input
                        value={EditEmail}
                        className="name"
                        autoComplete="off"
                        type="Email"
                        placeholder="Enter Email"
                        onChange={(e) => setEditEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="main_heading">
                    <h2> Address </h2>
                  </div>
                  <hr />
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label for="uname">Telephone No</label>
                      <input
                        value={EditPhone}
                        onKeyPress={handleKeyPress}
                        maxLength={10}
                        className="name"
                        autoComplete="off"
                        type="text"
                        onChange={handleInputChange3}
                        placeholder="Enter Telephone No"
                      />
                    </div>
                    <div className="form-group">
                      <label for="uname">House No</label>
                      <input
                        value={EditHouseNo}
                        className="name"
                        autoComplete="off"
                        type="text"
                        placeholder="Enter House No"
                        onChange={(e) => setEditHouseNo(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <label for="uname">Address</label>
                      <input
                        value={EditAddress}
                        autoComplete="off"
                        className="name"
                        type="text"
                        placeholder="Enter Address"
                        onChange={(e) => setEditAddress(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label for="uname">Pin Code </label>
                      <input
                        onKeyPress={handleKeyPress1}
                        value={EditPinCode}
                        autoComplete="off"
                        className="name"
                        type="text"
                        placeholder="Enter PinCode "
                        onChange={(e) => setEditPinCode(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="city">Country</label>

                      <select
                        value={CountryID}
                        onChange={(e) => setEditCountryID(e.target.value)}
                      >
                        <option value="">--Please Select--</option>
                        <option value="1">India</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <div>
                        <label htmlFor="city">
                          State<text className="m_star">*</text>
                        </label>

                        <select id="city" onChange={handleStateChange} required>
                          <option value="1">--Please Select--</option>
                          {State && State.length > 0 ? (
                            State.map((val) => (
                              <option
                                key={val.state_id}
                                value={val.state_id}
                                selected={val.state_id === StateID}
                              >
                                {val.state_title}
                              </option>
                            ))
                          ) : (
                            <option value="">Loading...</option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="city">
                        District<text className="m_star">*</text>
                      </label>
                      <select
                        required
                        id="citySelect"
                        onChange={handleDistrictChange}
                      >
                        <option value="1">--Please Select--</option>

                        {District && District.length > 0 ? (
                          District.map((val) => (
                            <option
                              key={val.district_id}
                              value={val.district_id}
                              selected={val.district_id === CityID}
                            >
                              {val.district_title}
                            </option>
                          ))
                        ) : (
                          <option value="">Loading...</option>
                        )}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="city">
                        Tehsil<text className="m_star">*</text>
                      </label>
                      <select
                        required
                        id="citySelect"
                        onChange={handleCityChange}
                      >
                        <option value="">--Please Select--</option>
                        {City && City.length > 0 ? (
                          City.map((val) => (
                            <option
                              key={val.city_id}
                              value={val.city_id}
                              selected={val.city_id === cityData}
                            >
                              {val.city_title}
                            </option>
                          ))
                        ) : (
                          <option value="">Loading...</option>
                        )}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="username">Village</label>
                      <select
                        name="cars"
                        id="cars"
                        onChange={(e) => setVillageID(e.target.value)}
                      >
                        <option value="1">--Select Village--</option>
                        {village && village.length ? (
                          village.map((val, index) => {
                            return (
                              <option key={index} value={val.village_id}>
                                {val.village_name}
                              </option>
                            );
                          })
                        ) : (
                          <option value="">Loading...</option>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="main_heading">
                    <h2> Reference </h2>
                  </div>
                  <hr />
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <div>
                        <label htmlFor="city">Is Lead</label>
                        <select
                          value={EditselectedOption}
                          onChange={handleOptionChange2}
                        >
                          <option value="">--Please Select--</option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <div>
                        <label htmlFor="city">Source</label>
                        <select
                          value={EditSource}
                          onChange={handleOptionChange3}
                        >
                          <option value="null">--Please Select--</option>
                          <option value="Walkin">WALKIN</option>
                          <option value="Reffered">REFFERED</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label for="uname">Referred By Client</label>
                      <select
                        className="name"
                        id="city"
                        onChange={(e) =>
                          handleEditReferredByClientID(e.target.value)
                        }
                      >
                        <option value="">--Please Select--</option>
                        {referredByClientData &&
                        referredByClientData.length > 0 ? (
                          referredByClientData.map((val) => (
                            <option
                              value={val.Id}
                              selected={val.Id === EditReferredByClientID}
                            >
                              {val.FirstName}
                            </option>
                          ))
                        ) : (
                          <option value=""></option>
                        )}
                      </select>
                    </div>
                    <div className="form-group">
                      <label for="uname">Referred By Counsel</label>
                      <select
                        className="name"
                        id="city"
                        onChange={(e) =>
                          handleEditReferredByCounselID(e.target.value)
                        }
                      >
                        <option value="">--Please Select--</option>
                        {referredByCounselData &&
                        referredByCounselData.length > 0 ? (
                          referredByCounselData.map((val) => (
                            <option
                              value={val.Id}
                              selected={val.Id === EditReferredByCounselID}
                            >
                              {val.ContactPersonName}
                            </option>
                          ))
                        ) : (
                          <option value=""></option>
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label for="uname">Comments</label>
                      <textarea
                        value={Edittext}
                        onChange={handleTextChange1}
                        rows={4}
                        cols={50}
                        placeholder="Enter your text here..."
                      />
                    </div>
                  </div>
                  <div className="btn_main caseMobile">
                    <button type="submit" className="btn_save">
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn_save btn_cancle"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*------------ EDIT CASE FORM -----------*/}

      <div
        className="modal fade"
        id="exampleModalLongEditCase"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-xl"
          role="document"
          // style={{ maxWidth: "80%" }}
        >
          <div className="modal-content">
            <div className="add_wrap">
              <div className="main_heading">
                <h2>Edit Case</h2>
              </div>
            </div>
            <div className="popup_modal ">
              <button
                type="button"
                className="close e_popup btn_popup closeCasEedit"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="main_heading">
                <h2>Case</h2>
              </div>
              <div className="dashed_popup">
                <form role="form" onSubmit={submitCaseEditHandler}>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="uname">
                        Case Name<text className="m_star">*</text>
                      </label>
                      <input
                        className="name"
                        type="text"
                        placeholder="Enter Case Name"
                        name="uname"
                        value={title1}
                        onChange={handleEditTitle1}
                        required
                      />
                    </div>
                    VS
                    <div className="form-group">
                      <label htmlFor="uname">&nbsp;</label>
                      <input
                        className="name"
                        type="text"
                        placeholder="Enter Case Name"
                        name="uname"
                        value={title2}
                        onChange={handleEditTitle2}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="uname">
                        Client <text className="m_star">*</text>
                      </label>
                      <select
                        className="name"
                        name="cars"
                        id="cars"
                        onChange={(e) => setCEditClient(e.target.value)}
                        required
                      >
                        <option value="">Please select</option>
                        {caseClientData &&
                          caseClientData.map((val, index) => {
                            return (
                              <option
                                key={index}
                                value={val.value}
                                selected={val.value === ceditclient}
                              >
                                {val.label}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="username">Court </label>

                      <select
                        className="name"
                        name="cars"
                        id="cars"
                        onChange={(e) => {
                          const selectedCourtId = e.target.value;
                          const selectedCourtName = courtData.find(
                            (val) => val.Id === selectedCourtId
                          )?.CourtName;
                          handleCourtChange(selectedCourtId, selectedCourtName);
                        }}
                      >
                        <option value="">--Please Select--</option>

                        {courtData &&
                          courtData.map((val, index) => {
                            return (
                              <option
                                key={index}
                                value={val.Id}
                                selected={val.Id === court}
                              >
                                {val.CourtName}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="username">Case Type </label>

                      <select
                        className="name"
                        name="cars"
                        id="cars"
                        onChange={(e) => setType(e.target.value)}
                      >
                        <option value="">Please select</option>
                        {caseType && caseType.length ? (
                          caseType.map((val, index) => {
                            return (
                              <option
                                key={index}
                                value={val.Id}
                                selected={val.Id === type}
                              >
                                {val.CaseType}
                              </option>
                            );
                          })
                        ) : (
                          <option value="">Loading...</option>
                        )}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="uname">Case No.</label>
                      <input
                        onKeyPress={handleKeyPress}
                        className="name"
                        type="text"
                        placeholder="Enter Case No."
                        name="uname"
                        value={editcaseNo}
                        onChange={(e) => setEditCaseNo(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="uname">
                        Case Year<text className="m_star">*</text>
                      </label>
                      <select
                        required
                        className="name"
                        name="cars"
                        id="cars"
                        onChange={(e) => setEditCaseYear(e.target.value)}
                      >
                        <option value="">---Please select---</option>
                        {optionsYear &&
                          optionsYear.map((val, index) => {
                            return (
                              <option
                                key={index}
                                value={val.value}
                                selected={val.value == editcaseYear}
                              >
                                {val.label}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="username">
                        Court State <text className="m_star">*</text>
                      </label>
                      <select
                        required
                        className="name"
                        name="cars"
                        id="cars"
                        onChange={handleStateChange}
                      >
                        <option value="">--Please Select--</option>
                        {State && State.length
                          ? State.map((val, index) => {
                              return (
                                <option
                                  key={index}
                                  value={val.state_id}
                                  selected={
                                    val.state_id === StateID ? true : false
                                  }
                                >
                                  {val.state_title}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="username">
                        Court District<text className="m_star">*</text>
                      </label>
                      <select
                        required
                        className="name"
                        name="cars"
                        id="cars"
                        onChange={handleDistrictChange}
                      >
                        <option value="">--Please Select--</option>
                        {District && District.length
                          ? District.map((val, index) => {
                              return (
                                <option
                                  key={index}
                                  value={val.district_id}
                                  selected={
                                    val.district_id === CityID ? true : false
                                  }
                                >
                                  {val.district_title}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </div>
                  </div>

                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="username">Court Tehsil </label>
                      <select
                        className="name"
                        name="cars"
                        id="cars"
                        onChange={(e) => setCityData(e.target.value)}
                      >
                        ( <option value="">please select...</option> )
                        {City && City.length ? (
                          City.map((val, index) => {
                            return (
                              <option
                                key={index}
                                value={val.city_id}
                                selected={val.city_id === cityData}
                              >
                                {val.city_title}
                              </option>
                            );
                          })
                        ) : (
                          <option value="">Loading...</option>
                        )}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="uname">
                        Case Booked At <text className="m_star">*</text>
                      </label>

                      <select
                        required
                        value={editBranchCity}
                        className="name"
                        name="cars"
                        id="cars"
                        onChange={(e) => setEditBranchCity(e.target.value)}
                      >
                        <option value="">---Please select---</option>
                        {branchCityData &&
                          branchCityData.map((val, index) => {
                            return (
                              <option
                                key={index}
                                value={val.Id}
                                selected={val.Id === editBranchCity}
                              >
                                {val.BranchCity}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="uname">Filing Date </label>
                      <input
                        className="name"
                        type="date"
                        name="uname"
                        value={editfilingDate}
                        onChange={(e) => setEditFilingdate(e.target.value)}
                      />
                    </div>
                  </div>
                  <hr />

                  {showInputField && (
                    <>
                      <div className="main_heading">
                        <h2>Diary</h2>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <label htmlFor="uname">Diary No.</label>
                          <input
                            className="name"
                            type="text"
                            placeholder="Enter Diart No."
                            name="uname"
                            value={editdiaryNo}
                            onChange={(e) => setEditDiaryNo(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="uname">Diary Year</label>
                          <select
                            className="name"
                            name="cars"
                            id="cars"
                            onChange={(e) => setEditDiaryYear(e.target.value)}
                          >
                            <option value="">--Please Select--</option>
                            {optionsYear &&
                              optionsYear.map((val, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={val.value}
                                    selected={
                                      val.value === editdiaryYear ? true : false
                                    }
                                  >
                                    {val.label}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="uname">Name OF AOR</label>
                          <input
                            className="name"
                            type="text"
                            placeholder="Enter Name OF AOR"
                            value={editaor}
                            onChange={(e) => setEditAor(e.target.value)}
                          />
                        </div>
                      </div>
                      <hr />
                    </>
                  )}

                  <div className="main_heading">
                    <h2>Case Summary</h2>
                  </div>

                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="uname">Case Summary</label>
                      <textarea
                        className="name"
                        type="text"
                        placeholder="Enter Case Summary"
                        name="uname"
                        value={ceditsummary}
                        onChange={(e) => setCEditSummary(e.target.value)}
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="main_heading">
                    <h2>Counsel</h2>
                  </div>

                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="uname">
                        Counsel (Incharge) <text className="m_star">*</text>{" "}
                      </label>
                      <select
                        className="name"
                        name="cars"
                        id="cars"
                        onChange={(e) => setEditCounsel(e.target.value)}
                        required
                      >
                        <option value="">Please select</option>
                        {viewUserData &&
                          viewUserData.map((val, index) => {
                            return (
                              <option
                                key={index}
                                value={val.Id}
                                selected={val.Id === editcounsel}
                              >
                                {val.Name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="uname">Counsel Share ( In % )</label>
                      <input
                        className="name"
                        type="text"
                        placeholder="%"
                        value={editcounselShare}
                        onChange={handleCounselShareChange1}
                      />
                    </div>
                  </div>
                  <hr />
                  {cocounselRow &&
                    cocounselRow.map((row, index) => (
                      <div key={index}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor={`coCounselName-${index}`}>
                              Co-Counsel _ {index + 1}
                            </label>
                            <select
                              className="name"
                              name="cars"
                              id="cars"
                              value={row.coCounselName}
                              onChange={(e) =>
                                editHandlecoCounsel(index, e.target.value)
                              }
                            >
                              <option value="">--Please Select--</option>
                              {viewUserCoCounselData &&
                                viewUserCoCounselData.map((val, index) => {
                                  return (
                                    <option
                                      value={val.Id}
                                      selected={val.Id === row.coCounselName}
                                    >
                                      {val.Name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>

                          <div className="form-group">
                            <label htmlFor={`coCounselShare-${index}`}>
                              Co Counsel Share ( In % )
                            </label>
                            <input
                              className="name"
                              type="text"
                              placeholder="%"
                              value={row.coCounselShare}
                              onChange={(e) =>
                                editHandleCoCounselShare(index, e.target.value)
                              }
                            />
                          </div>
                          {index === 0 && (
                            <a
                              onClick={editCaseAddRow}
                              style={{
                                color: "white",
                                cursor: "pointer",
                                margin: "10px",
                                padding: "6px 10px",
                                background: "green",
                              }}
                            >
                              +
                            </a>
                          )}

                          {index !== 0 && (
                            <a
                              onClick={() =>
                                editRemoveRow(index, row.coCounselId)
                              }
                              style={{
                                color: "white",
                                cursor: "pointer",
                                margin: "10px",
                                padding: "6px 6px",
                              }}
                            >
                              ❌
                            </a>
                          )}
                        </div>
                      </div>
                    ))}
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="uname">Retaineed For</label>
                      <select
                        className="name"
                        name="cars"
                        id="cars"
                        value={editretaineed}
                        onChange={(e) => setEditRetaineed(e.target.value)}
                      >
                        <option value="">--Please Select--</option>
                        <option value="Petitioners">
                          PETITIONERS / PLAINTIFFS / APPELLANTS
                        </option>
                        <option value="Respondants">
                          RESPONDANTS / DEFANDANTS
                        </option>
                        <option value="Intervenors">
                          INTERVENORS / COMPLAINANTS
                        </option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="uname">Intervenors/ Complainants</label>
                      <textarea
                        className="name"
                        placeholder="Enter Intervenors"
                        value={editintervenors}
                        onChange={(e) => setEditIntervenors(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="uname">Petitioners / Plaintiffs</label>
                      <textarea
                        className="name"
                        placeholder="Enter Petitioners"
                        value={editpatitioners}
                        onChange={(e) => setEditPatitioners(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="uname">Respondants/ Defandants</label>
                      <textarea
                        className="name"
                        placeholder="Enter Respondants"
                        value={editrespondants}
                        onChange={(e) => setEditRespondants(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className={`form_flex col-${inputWidth}`}>
                    <div className="form-group">
                      <label htmlFor="uname">Is Open</label>
                      <select
                        className="name"
                        name="cars"
                        id="cars"
                        value={isOpen}
                        onChange={(e) => setIsOpen(e.target.value)}
                      >
                        <option value="1">Open</option>
                        <option value="0">Close</option>
                      </select>
                    </div>
                    {showClosingDate && (
                      <div className="form-group">
                        <label htmlFor="uname">
                          Closing Date <text className="m_star">*</text>
                        </label>
                        <input
                          className="name"
                          required
                          type="Date"
                          value={closingDate}
                          onChange={(e) => setClosingDate(e.target.value)}
                        />
                      </div>
                    )}
                  </div>
                  <hr />
                  <div className="main_heading">
                    <h2>Payment Structure</h2>
                  </div>

                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="uname">Fixed Fees</label>
                      <input
                        className="name"
                        onKeyPress={handleKeyPress}
                        type="text"
                        placeholder="Enter Fixed Fees"
                        name="uname"
                        value={editfixedFees}
                        onChange={(e) => setEditFixedFees(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="uname">Expenses</label>
                      <select
                        className="name"
                        name="cars"
                        id="cars"
                        value={editexpenses}
                        onChange={(e) => setEditExpenses(e.target.value)}
                      >
                        <option value="">--- Please Select ---</option>
                        <option value="0">EXCLUSIVE</option>
                        <option value="1">INCLUSIVE</option>
                      </select>
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="uname">Effective Appearance Fees</label>
                      <input
                        className="name"
                        onKeyPress={handleKeyPress}
                        type="text"
                        placeholder="Per Effective Appearance Fees"
                        name="uname"
                        value={editappearanceFee}
                        onChange={(e) => setEditAppearanceFee(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="uname">Non Effective Appr. Fees</label>
                      <input
                        className="name"
                        onKeyPress={handleKeyPress}
                        type="text"
                        placeholder="per Non Effective Appr. Fees"
                        name="uname"
                        value={editnonAppearanceFee}
                        onChange={(e) =>
                          setEditNonAppearanceFee(e.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div className="btn_main caseMobile">
                    <button type="submit" className="btn_save">
                      Save
                    </button>
                    <button
                      type="button"
                      data-dismiss="modal"
                      className="btn_save btn_cancle"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseDetails;
